.tag {
  #font.size(@tagFontSize);
  .margin(r, @tagFontSize, @tagMargin);
  .padding(l, @tagFontSize, @tagPadding);
  .padding(r, @tagFontSize, @tagPadding);
  .height(@tagFontSize, @tagHeight);
  .border-radius(a, @tagFontSize, @tagBorderRadius);
  .border(a, @tagFontSize, @borderWidthBase, @tagBorderColor);
  display: inline-block;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  color: @tagFontColor;
  background-color: @tagBgColor;
  white-space: nowrap;
  cursor: default;

  .tag-title {
    .truncate();
  }

  .tag-remove,
  .autocomplete-tag-remove {
    #font.size(@tagRemoveFontSize);
    #font.line-height(@baselineEm, 0);
    .margin(l, @tagRemoveFontSize, @tagIconMarginLeft);
    opacity: @opacityMed;
    cursor: pointer;

    &:after {
      .iconCore();
      font-style: normal;
      content: '\e0ab'; // Close icon
    }

    &:hover {
      opacity: 1;
    }
  }
}