@contentSliderButton: transparent;
@contentSliderBgColor: @colorBlueMed;
@contentSliderPrevNextWidth: 45;
@contentSliderPrevNextLabelTextSize: @fontSize3;
@contentSliderPrevNextLabelPaddingTopBtm: 9.5;
@contentSliderPrevNextLabelPaddingLeftRight: 11;
@contentSliderPrevPosLeft: 0;
@contentSliderPrevNextPosLftRgtSmallScreen: 12;
@contentSliderNextPosRight: 24;
@contentSliderNextPosRightMediumScreen: 12;
@contentSliderMaskPaddingLeft: @spaceLarge;
@contentSliderMaskPaddingLeftMediumScreen: @spaceSmall;
@contentSliderMaskMarginRight: @spaceLarge;
@contentSliderMaskMarginRightMediumScreen: @spaceSmall;
@contentSliderSmallScreenMinWidth: 250;

.content-slider{
  display: flex;
  align-items: center;
  position: relative;

  .prev,
  .next {
    .btn-label {
      padding: 0;
      letter-spacing: 0;
      #font.size(@contentSliderPrevNextLabelTextSize);
    }
  }

  .content-slider-mask {
    flex: 1;
    overflow:hidden;
    .padding(trbl, @baseFontPx,0,0,3,@contentSliderMaskPaddingLeft); // Padding bottom for making room for the Box's shadow
  }

  .row {
    position: relative;
    width:2000%;
    left:0;

    .stopTransitionFlicker();

    transform: translate3d(0,0,0);
    transition: left 0.5s ease-out;

    &.view-change{
      transition: left 0s ease-out;
    }
  }

  .row .col {
    .cols4(240);
    .margin(b,@baseFontPx,0);
  }

  & + .content-slider {
    .margin(t, @baseFontPx, @spaceLarge);
  }
}


//No JS
.no-js .content-slider-mask{overflow:auto;}


// Small Screen
@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  .content-slider {
    .content-slider-mask {
      .padding(l, @baseFontPx, 0);
      .margin(r, @baseFontPx, 0);
    }

    .prev {
      .pos(l, @baseFontPx, @contentSliderPrevNextPosLftRgtSmallScreen * -1);
    }

    .next {
      .pos(r, @baseFontPx, @contentSliderPrevNextPosLftRgtSmallScreen * -1);
    }

    .row .col {
      .min-width(@baseFontPx, @contentSliderSmallScreenMinWidth);
    }
  }
}

// Medium Screen
@media only screen and (min-width: @breakpointScreenMediumMin) and (max-width: @breakpointScreenMediumMax) {
  .content-slider {
    .content-slider-mask {
      .padding(l, @baseFontPx, @contentSliderMaskPaddingLeftMediumScreen);
      .margin(r, @baseFontPx, @contentSliderMaskMarginRightMediumScreen);
    }

    .next {
      .pos(r, @baseFontPx, @contentSliderNextPosRightMediumScreen);
    }
  }
}