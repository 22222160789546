// ==========================================================================
//   Components
//   ==========================================================================

.star-rating {
  position: relative;
  display: inline-block;

  uitk-icon, .icon {
    position: relative;
    line-height: inherit;
    left: 0;
  }
}


//
// Urgency HTML:
//
// <div class="urgency">
//    <strong class="message"><span class="visuallyhidden">Flights </span> Left At</strong>
//</div>
//
.urgency {
  vertical-align: middle;
  #font.size(15);

  .message {
    display: inline-block
  }
  .message {
    .padding(l, 15, 6);
    #font.size(15);
  }
}

.urgency.secondary {
  #font.size(13);

  .badge, .message {
    vertical-align: middle;
    display: inline-block
  }
  .message {
    .padding(l, 13, 6);
    #font.size(13);
  }
}

//
// Read more link
//
.read-more {
  display: inline-block;
  .margin(t, @baseFontPx, @spaceLarge);
}

//
// See all link
//
.see-all {
  float: right;
}

//
// Bread crumb
// <nav class="bread-crumb cf">
//   <ul>
//     <li><a href="#">Expedia.com</a>&nbsp;&bull;&nbsp;</li>
//     <li><a href="#">United States</a>&nbsp;&bull;&nbsp;</li>
//     <li><strong>San Francisco</strong></li>
//   </ul>
// </nav>
//
.bread-crumb {
  .padding(b, @baseFontPx, @spaceLarge);
  li {
    float: left;
    padding: 0;
    #font.size(@copySmall);
    color: @siteDefaultFontColor;
  }
  strong {
    font-weight: normal;
    color: @colorBlueMed;
  }
  a, a:link, a:active, a:visited, a:hover {
    color: @siteDefaultFontColor;
  }
  a:focus,
  a.vtest-focus {
    text-decoration: underline;
  }
}


//Layover
.layover {
  width: 100%;
  position: relative;
  display: inline-block;
  #font.size();
  .border(t, @baseFontPx, @layoverBorderWidth, @colorGrayscale4, dashed);
  .margin(tr, @baseFontPx, @layoverMargin, 0);

  .layover-label {
    position: absolute;
    #font.weight(medium);
    .padding(r, @baseFontPx, @spaceSmall);
    transform: translateY(@layoverLabelTranslateY);
  }
}

body {
  .layover .layover-label{
    background-color: @siteDefaultBgColor;
  }
}

.box,
.box-alt {
  .layover .layover-label {
    background-color: @boxBgColor;
  }
}

.data-table {
  .layover .layover-label {
    background-color: @tablesBgColor;
  }
}


//Currency ????
.currency-symbol {
  font-size: 100%;
}

.currency-code {
  font-size: 80%;
}
