//==========================================================================
// Typography Core
// ==========================================================================
body {
  color: @siteDefaultFontColor
}

/* Accessible focus treatment: people.opera.com/patrickl/experiments/keyboard/test */
a:link, .link, a:visited {
  color: @colorBlueMed;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}

a:focus {
  outline: none;
}

del, .strike {
  color: @colorGrayscale4;
  text-decoration: line-through;
}

// ==========================================================================
// Headings
// ==========================================================================
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: @colorSlateDark;
  margin: 0;
  font-weight: @fontWeightMed;
}

h1, .h1 {
  .H(1);

  &.display {
    .H(d);
    #font.weight(regular);
  }
}

h2, .h2 {
  .H(2);
  &.display {
    .H(2);
    #font.weight(regular);
  }

}

h3, .h3 {
  .H(3);
}

h4, .h4 {
  .H(4);
}

h5, .h5 {
  .H(5);
}

h6, .h6 {
  .H(6);
}

// Headline Bars
.headline-bar {
  background-color: @colorSlateDark;
  .border-radius(tlr, @h3, 4, 4);
  color: #fff;
  #font.size(@h3);
  .margin(trb, @h3, 0, 0, 24);
  .padding(tr, @h3, 6, 12);
}

.headline-bar-alt {
  background-color: @colorSlateDark;
  color: @colorSlateDark;
}

// ==========================================================================
// Sub-Headings
// ==========================================================================

h5, .h5 {
  &.sub-header {
    #font.size(@smallFontPx);
    #font.weight(medium);
    letter-spacing: 0.7px;
  }
}

h6, .h6 {
  &.sub-header {
    #font.size(9);
    #font.weight(medium);
    letter-spacing: 0.9px;
  }
}

// ==========================================================================
// Body copy
// ==========================================================================
// Paragraphs
p {
  color: @siteDefaultFontColor;
  margin: 0;
  + p {
    .margin(t, @baseFontPx, 24);
  }
}

// Lists
.liStyle() {
  #font.size(@secondaryFontPx);
  color: @colorSlateLight;
}

ul.list-bullet, 
ul.list-check, 
ol.list-number {
  padding-left: 0.933rem;
  .margin(trb, @baseFontPx, 24, 0, 0);

  li {
    .liStyle();
  }

  ul, ol {
    .margin(a, @baseFontPx, 12);
    list-style: none;
  }

  &.nobullet {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    list-style: none;
  }
}

// This appears to be a LESS hack. It fixes the issue of needing an escaped hash inside the svg tag for color value. 
// without escaping the bullets are not displayed.
// First issue was that the replace %() funciton could not be placed in the url() propertey directly so required this extra var.
// Second issue was that escape() did not work with just passing a less var, it had to be wrapped in another kind of escape 
// syntax ~'@{var}' which also did not work on it's own.
// Second issue may be due to the version of less we're using since I did not encounter any issue with the codepen less compiler.
@list-bullet-style-image-str: %('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6"><circle cx="1.5" cy="1.5" r="1.5" fill="%s"/></svg>', escape(~'@{colorGrayscale4}'));
ul.list-bullet {
  list-style-image: url(@list-bullet-style-image-str);
}

// check list
// Same LESS needed as above
@list-check-style-image-str: %('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><g transform="translate(0, 3)"><path d="M6.5701,12.56146 C6.3301,12.56146 6.0891,12.47546 5.8981,12.30146 L2.3281,9.06046 C1.9191,8.68846 1.8881,8.05646 2.2591,7.64746 C2.6321,7.23846 3.2641,7.20946 3.6721,7.57946 L6.5391,10.18246 L12.4591,4.29146 C12.8501,3.90146 13.4831,3.90346 13.8731,4.29446 C14.2621,4.68646 14.2601,5.31946 13.8691,5.70946 L7.2751,12.27046 C7.0811,12.46346 6.8261,12.56146 6.5701,12.56146 Z" fill="%s" /></g></svg>', escape(~'@{colorGrayscale4}'));
ul.list-check {
  //same as egencia icon "success" but because we don't have SVG hosted assets, and not all browsers support string list-styles this needs to be inline SVG.
  //use the "g" to fine tune scale/offset
  list-style-image: url(@list-check-style-image-str);
}

// check large list
ul.list-large {
  li {
    #font.size(@baseFontPx);
    letter-spacing: 0.3px;
  }

  li:before {
    #font.size(@h4);
    .padding(r, @h4, 6);
  }
}

ol.list-number {
  list-style: none;
  padding-left: 0px;

  > li {
    counter-increment: step-counter;
    display: flex;
  }

  > li:before {
    margin-right: 10px;
    content: counter(step-counter);
    #font.size(9);
    #font.weight(medium);
    justify-content: flex-start;
    color: @colorGrayscale4;
  }
}

ul.secondary {
  .margin(trb, 13, 18, 0, 0);
  li {
    .padding(trb, 13, 0, 6, 6);
  }
}

.list-nobullet {
  list-style: none;
}

dl {
  dt {
    #font.size(@secondaryFontPx);
    color: @colorSlateDark;

    + dt {
      .margin(t, @baseFontPx, 6);
    }
  }
  dd {
    .liStyle();
    margin-left: 0;
  }
}

// Quotes
q {
  quotes: "\201C" "\201D" "\2018" "\2019";
  font-style: italic;

  // Add a bit of style to the quoates
  &:before, &:after {
    font-size: 120%;
    line-height: normal;
  }

  // sets quotation marks
  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
}

// Copy size shortcuts
.primary,
.medium {
  #font.size(@baseFontPx);
  line-height: 1.35;
}

.secondary {
  #font.size(@secondaryFontPx);
  line-height: 1.35;
}

small, .small {
  #font.sizeIncludeLineheight(@smallFontPx);
}

// Text decoration shortcuts
.underline {
  text-decoration: underline;
}

// Link Styles
a.disabled, a.disabled:active, a.disabled:hover, a.disabled:focus {
  color: @colorSlateDark;
  cursor: not-allowed;
  pointer-events: none;
}

.link {
  color: @colorBlueMed;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// ==========================================================================
// Font Weight Shortcuts
// ==========================================================================
.font-light {
  #font.weight(regular); // DEPRECATED font weight light
}

.font-regular {
  #font.weight(regular);
}

.font-medium {
  #font.weight(medium);
}

.font-bold, .bold, strong, b {
  #font.weight(medium);
}
