.flag(@name) {
    @sel: ~"uitk-country-flag[isocode=@{name}], utik-country-flag[isocode=@{name}], .country-flag.@{name}";
    @{sel} {
        background-image: url("../images/flags/@{name}.svg");
    }
}
uitk-country-flag,
.country-flag {
    display: inline-block;
    width: 16px;
    height: 12px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 16px;
}
/* Uncomment the countries we need to support (no official list right now) */
.flag('ad');
.flag('ae');
.flag('af');
.flag('ag');
.flag('ai');
.flag('al');
// .flag('am');
.flag('ao');
.flag('aq');
.flag('ar');
.flag('as');
.flag('at');
.flag('au');
.flag('aw');
// .flag('ax');
.flag('az');
.flag('ba');
.flag('bb');
.flag('bd');
.flag('be');
.flag('bf');
.flag('bg');
.flag('bh');
.flag('bi');
.flag('bj');
.flag('bl');
.flag('bm');
.flag('bn');
.flag('bo');
.flag('bq');
// .flag('bq-bo');
// .flag('bq-sa');
// .flag('bq-se');
.flag('br');
.flag('bs');
.flag('bt');
.flag('bw');
.flag('by');
.flag('bz');
.flag('ca');
// .flag('ca-bc');
.flag('cc');
.flag('cd');
.flag('cf');
// .flag('cg');
.flag('ch');
.flag('ci');
.flag('ck');
.flag('cl');
.flag('cm');
.flag('cn');
// .flag('cn-tibet');
// .flag('co');
.flag('cr');
.flag('cu');
.flag('cv');
.flag('cw');
.flag('cx');
.flag('cy');
.flag('cz');
.flag('de');
.flag('dj');
.flag('dk');
.flag('dm');
.flag('do');
.flag('dz');
// .flag('ea');
// .flag('easter-island'); // tbd
.flag('ec');
// .flag('ec-w');
.flag('ee');
.flag('eg');
.flag('eh');
.flag('er');
.flag('es');
// .flag('es-cn');
// .flag('es-ib');
// .flag('es-ml');
// .flag('es-pv');
.flag('et');
// .flag('eu');
.flag('fi');
.flag('fj');
.flag('fk');
.flag('fm');
.flag('fo');
.flag('fr');
// .flag('fr-cor');
.flag('ga');
.flag('gb');
// .flag('gb-eng');
// .flag('gb-ork');
// .flag('gb-sct');
// .flag('gb-wls');
.flag('gd');
.flag('ge');
// .flag('ge-ab');
.flag('gf');
// .flag('gg');
.flag('gh');
.flag('gi');
.flag('gl');
.flag('gm');
.flag('gn');
.flag('gp');
.flag('gq');
.flag('gr');
.flag('gt');
.flag('gu');
.flag('gw');
.flag('gy');
.flag('hk');
.flag('hn');
.flag('hr');
.flag('ht');
.flag('hu');
.flag('id');
.flag('ie');
.flag('il');
// .flag('im');
.flag('in');
// .flag('io');
.flag('iq');
.flag('ir');
.flag('is');
.flag('it');
// .flag('it-82');
// .flag('it-88');
// .flag('je');
.flag('jm');
.flag('jo');
.flag('jp');
.flag('ke');
.flag('kg');
.flag('kh');
.flag('ki');
.flag('km');
.flag('kn');
.flag('kp');
.flag('kr');
.flag('kw');
.flag('ky');
.flag('kz');
.flag('la');
.flag('lb');
.flag('lc');
.flag('li');
.flag('lk');
.flag('lr');
.flag('ls');
.flag('lt');
.flag('lu');
.flag('lv');
.flag('ly');
.flag('ma');
.flag('mc');
.flag('md');
// .flag('md-transnistria');
.flag('me');
.flag('mf');
.flag('mg');
.flag('mh');
.flag('mk');
.flag('ml');
.flag('mm');
.flag('mn');
.flag('mo');
.flag('mp');
.flag('mq');
.flag('mr');
.flag('ms');
.flag('mt');
.flag('mu');
.flag('mv');
.flag('mw');
.flag('mx');
.flag('my');
.flag('mz');
.flag('na');
// .flag('nato');
.flag('nc');
.flag('ne');
.flag('nf');
.flag('ng');
.flag('ni');
.flag('nl');
.flag('no');
// .flag('northern-cyprus'); // tbd kk?
.flag('np');
.flag('nr');
.flag('nu');
.flag('nz');
.flag('om');
.flag('pa');
.flag('pe');
.flag('pf');
.flag('pg');
.flag('ph');
.flag('pk');
.flag('pl');
.flag('pm');
// .flag('pn');
.flag('pr');
// .flag('ps');
.flag('pt');
// .flag('pt-20');
// .flag('pt-30');
.flag('pw');
.flag('py');
.flag('qa');
.flag('re');
.flag('ro');
.flag('rs');
.flag('ru');
.flag('rw');
.flag('sa');
.flag('sb');
.flag('sc');
.flag('sd');
.flag('se');
.flag('sg');
.flag('sh');
.flag('si');
.flag('sk');
.flag('sl');
.flag('sm');
.flag('sn');
.flag('so');
// .flag('so-somaliland');
.flag('sr');
// .flag('ss');
.flag('st');
.flag('sv');
.flag('sx');
.flag('sy');
.flag('sz');
.flag('tc');
.flag('td');
.flag('tg');
.flag('th');
.flag('tj');
.flag('tk');
.flag('tl');
.flag('tm');
.flag('tn');
.flag('to');
.flag('tr');
.flag('tt');
.flag('tv');
.flag('tw');
.flag('tz');
.flag('ua');
.flag('ug');
// .flag('un');
.flag('us');
// .flag('us-hi');
.flag('uy');
.flag('uz');
.flag('va');
.flag('vc');
.flag('ve');
.flag('vg');
.flag('vi');
.flag('vn');
.flag('vu');
.flag('wf');
.flag('ws');
// .flag('xi');
// .flag('xk');
.flag('ye');
.flag('yt');
.flag('za');
.flag('zm');
.flag('zw');