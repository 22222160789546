.menu-navigation {
  padding: 0;
  margin: 0;
  list-style: none;
  .cf();

  > li {
    display: inline-block;

    // For menus with multiple lists
    &.multi-category {
      position: static;
    }

    > a {
      #font.weight(medium);
      .margin(r, @menuFontPx, 3);
      text-decoration: none;
      cursor: pointer;

      &:after {
        .iconCore();
        content: "\e09a"; // caret180
        .pos(l, @menuFontPx, 12);
      }

      // Regular links
      &:not(.btn) {
        display: inline-block;
        .padding(trbl, @menuFontPx, 12, 32, 12, 32);
      }

      // Button links
      &.btn {
        &.btn-med {
          .btn-label {
            .padding(r, @secondaryFontPx, 42);
          }

          &:after {
            .pos(r, @secondaryFontPx, 18);
            position: absolute;
            top: 50%;
            left: auto;
            color: white;
            transform: translateY(-50%);
          }
        }

        &.btn-small {
          .btn-label {
            .padding(r, @smallFontPx, 42);
          }

          &:after {
            position: absolute;
            .pos(t, @smallFontPx, 19);
            left: auto;
            .pos(r, @smallFontPx, 18);
            color: white;
          }
        }
      }
    }

    // The actual menu pane with all the links/content
    > .menu {
      opacity: 0;
      visibility: hidden;
      height: 0;
      position: absolute;
      background-color: white;
      box-shadow: @menuShadow;
      .padding(tr, @menuFontPx, @spaceSmall, @spaceLarge);
      .border-radius(tlrbrl, @menuFontPx, 0, @menuRadius, @menuRadius, @menuRadius);
      #zIndex.menus();
      .menuStyleList();
    }
  }

  // State when the Menu is open
  .open {
    > a.btn,
    > a:not(.btn),
    > a:not(.btn):hover,
    > a:not(.btn):active {
      .border-radius(tlrbrl, @menuFontPx, @menuBtnRadius, @menuBtnRadius, 0, 0);
      background-color: @menuOpenBtnBgColor;
      color: white;
    }

    .menu {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
}

// In a mixin because the theme-utility-menu uses it
.menuStyleList() {
  ul,
  ol,
  dl {
    float: left;
    margin: 0;
    .margin(r, @menuFontPx, 6);
    padding: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  dl {
    dt {
      color: @menuCatTitleTextColor;
      #font.size(@menuCatTitleTextSize);
      #font.weight(medium);
    }

    dd {
      padding-bottom: 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  li {
    margin: 0;
    list-style-type: none;
  }

  a {
    text-decoration: none;
    .padding(tr, @menuFontPx, 6, 0);
    .min-width(@menuFontPx, 120);
    .max-width(@menuFontPx, 270);
    color: @menuNavTextColor;
    display: block;

    &:hover {
      color: @menuNavTextColorHover;
    }
  }
}