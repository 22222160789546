// ==========================================================================
// Icon Font Face
// ==========================================================================
@font-face {
  font-family: 'EgenciaIcons';
  src:  url(/media/fonts/EgenciaIcons.woff2) format("woff2"),
        url(/media/fonts/EgenciaIcons.woff) format('woff'),
        url(/media/fonts/EgenciaIcons.ttf) format('truetype'),
        url(https://assets.egencia.com/media/fonts/EgenciaIcons.woff2) format("woff2"),
        url(https://assets.egencia.com/media/fonts/EgenciaIcons.woff) format('woff'),
        url(https://assets.egencia.com/media/fonts/EgenciaIcons.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

// ==========================================================================
// Icon Core
// ==========================================================================
.iconCore() {
  font-family: 'EgenciaIcons';
  display: inline-block;
  position: relative;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  speak: none;
}

// Basic setup of icons
uitk-icon,
.icon {
  display: inline-block;
  position: relative;
  font-style: normal;

  // Hide alternative content
  .alt {
    display: inline-block;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    text-indent: -999em;
  }

  // Generated content for icons
  &:after, &:before {
    .iconCore();
  }
}

// ==========================================================================
// Icon Stack
// ==========================================================================
uitk-icon-stack,
.icon-stack {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  uitk-icon,
  .icon {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }
}

// Firefox animation flicker issue (preserve-3d	Specifies that child elements will preserve its 3D position)
.firefox uitk-icon-stack,
.firefox .icon-stack {
  transform-style: preserve-3d;
}

// ==========================================================================
// Icons
// ==========================================================================
// DIRECTIONAL
// Angled arrow
uitk-icon[name="angled-arrow-left"]:before,
.icon-angled-arrow-left:before {
  content: "\e0af";
}

uitk-icon[name="angled-arrow-right"]:before,
.icon-angled-arrow-right:before {
  content: "\e0ae";
}

// Arrows
uitk-icon[name="arrow0"]:before,
.icon-arrow0:before {
  content: "\e08a";
}

uitk-icon[name="arrow180"]:before,
.icon-arrow180:before {
  content: "\e08e";
}

uitk-icon[name="arrow270"]:before,
.icon-arrow270:before {
  content: "\e090";
}

uitk-icon[name="arrow90"]:before,
.icon-arrow90:before {
  content: "\e08c";
}

uitk-icon[name="arrow135"]:before,
.icon-arrow135:before {
  content: "\e08d";
}

uitk-icon[name="arrow315"]:before,
.icon-arrow315:before {
  content: "\e091";
}

uitk-icon[name="arrow45"]:before,
.icon-arrow45:before {
  content: "\e08b";
}

uitk-icon[name="arrow225"]:before,
.icon-arrow225:before {
  content: "\e08f";
}

// Toggles
uitk-icon[name="toggle90"]:before,
.icon-toggle90:before {
  content: "\e093";
}

uitk-icon[name="toggle270"]:before,
.icon-toggle270:before {
  content: "\e095";
}

uitk-icon[name="toggle180"]:before,
.icon-toggle180:before {
  content: "\e094";
}

uitk-icon[name="toggle0"]:before,
.icon-toggle0:before {
  content: "\e092";
}

// Carets
uitk-icon[name="caret270"]:before,
.icon-caret270:before {
  content: "\e09b";
}

uitk-icon[name="caret0"]:before,
.icon-caret0:before {
  content: "\e098";
}

uitk-icon[name="caret90"]:before,
.icon-caret90:before {
  content: "\e099"
}

uitk-icon[name="caret180"]:before,
.icon-caret180:before {
  content: "\e09a";
}

// Merge
uitk-icon[name="merge"]:before,
.icon-merge:before {
  content: "\f001";
}

// Split
uitk-icon[name="split"]:before,
.icon-split:before {
  content: "\f002";
}

// Mobile-toggle
uitk-icon[name="mobile-toggle"]:before,
.icon-mobile-toggle:before {
  content: "\f003";
}

// search-toggle
uitk-icon[name="search-toggle"]:before,
.icon-search-toggle:before {
  content: "\f004";
}

// BRAND & PRODUCT
// Egencia bird
uitk-icon[name="egencia-bird"]:before,
.icon-egencia-bird:before {
  content: "\e044";
}

// Flights
uitk-icon[name="flights"]:before,
uitk-icon[name="flights45"]:before, // DEPRECATE
.icon-flights:before,
.icon-flights45:before {
  content: "\e001";
}

// Hotels
uitk-icon[name="hotels"]:before,
.icon-hotels:before {
  content: "\e003";
}

// Train
uitk-icon[name="train"]:before,
.icon-train:before {
  content: "\e007";
}

// Tram
uitk-icon[name="tram"]:before {
  content: "\f023";
}

// Tunnel
uitk-icon[name="tunnel"]:before {
  content: "\f025";
}

// Cars
uitk-icon[name="cars"]:before,
.icon-cars:before {
  content: "\e005";
}

// Taxi
uitk-icon[name="taxi"]:before,
.icon-taxi:before {
  content: "\e009";
}

// Bus
uitk-icon[name="bus"]:before,
.icon-bus:before {
  content: "\e00b";
}

// Boat
uitk-icon[name="boat"]:before {
  content: "\f022";
}

// AMENITIES
// Accessible
uitk-icon[name="accessible"]:before,
.icon-accessible:before {
  content: "\e088";
}

// Activities
uitk-icon[name="activities"]:before,
.icon-activities:before {
  content: "\e0bf";
}

// Bed
uitk-icon[name="bed"]:before,
uitk-icon[name="bed-alt-filled"]:before, // DEPRECATE
.icon-bed:before,
.icon-bed-alt-filled:before {
  content: "\e605";
}

// Bicycle
uitk-icon[name="bicycle"]:before,
.icon-bicycle:before {
  content: "\e806";
}

// Briefcase
uitk-icon[name="briefcase"]:before,
.icon-briefcase:before {
  content: "\e80a";
}

// Cabin
uitk-icon[name="cabin"]:before,
.icon-cabin:before {
  content: "\e804";
}

// Car Door
uitk-icon[name="car-door"]:before,
.icon-car-door:before {
  content: "\f005";
}

// Cart
uitk-icon[name="cart"]:before,
.icon-cart:before {
  content: "\e038";
}

// Coffee
uitk-icon[name="coffee"]:before,
.icon-coffee:before {
  content: "\e07d";
}

// Croissant
uitk-icon[name="croissant"]:before,
.icon-croissant:before {
  content: "\e07f";
}

// Dining
uitk-icon[name="dining"]:before,
.icon-dining:before {
  content: "\e081";
}

// Eco
uitk-icon[name="eco"]:before {
  content: "\f031";
}

// Entertainment
uitk-icon[name="entertainment"]:before,
.icon-entertainment:before {
  content: "\e801";
}

// Front-desk
uitk-icon[name="front-desk"]:before,
.icon-front-desk:before {
  content: "\f006";
}

// Gym
uitk-icon[name="gym"]:before,
.icon-gym:before {
  content: "\e087";
}

// Lights
uitk-icon[name="lights"]:before,
.icon-lights:before {
  content: "\e0c5";
}

// Lounge
uitk-icon[name="lounge"]:before,
.icon-lounge:before {
  content: "\e808";
}

// Luggage
uitk-icon[name="luggage"]:before,
.icon-luggage:before {
  content: "\e075";
}

// Non-Refundable
uitk-icon[name="non-refundable"]:before,
.icon-non-refundable:before {
  content: "\e0b1";
}

// Overnight
uitk-icon[name="overnight"]:before,
.icon-overnight:before {
  content: "\e02b";
}

// Parking
uitk-icon[name="parking"]:before,
.icon-parking:before {
  content: "\e07b";
}

// Plug
uitk-icon[name="plug"]:before,
.icon-plug:before {
  content: "\e802";
}

// Pool
uitk-icon[name="pool"]:before,
.icon-pool:before {
  content: "\e085";
}

// Refundable
uitk-icon[name="refundable"]:before,
.icon-refundable:before {
  content: "\e0b0";
}

// Seat
uitk-icon[name="seat"]:before,
uitk-icon[name="seatalt"]:before, // DEPRECATE
.icon-seat:before,
.icon-seatalt:before {
  content: "\e071";
}

// airline-seat-recline--none-right
uitk-icon[name="airline-seat-recline-none-right"]:before,
.icon-airline-seat-recline-none-right:before {
  content: "\f032";
}

// Service dog
uitk-icon[name="service-dog"]:before,
.icon-service-dog:before {
  content: "\e602";
}

// Shield
uitk-icon[name="shield"]:before,
.icon-shield:before {
  content: "\e0c3";
}

// Snowflake
uitk-icon[name="snowflake"]:before,
.icon-snowflake:before {
  content: "\e079";
}

// Sun
uitk-icon[name="sun"]:before,
.icon-sun:before {
  content: "\e029";
}

// Ticket
uitk-icon[name="ticket"]:before,
.icon-ticket:before {
  content: "\e03d";
}

// Tickets
uitk-icon[name="tickets"]:before,
.icon-tickets:before {
  content: "\e03f";
}

// Transmission
uitk-icon[name="transmission"]:before,
.icon-transmission:before {
  content: "\e077";
}

// Transfer
uitk-icon[name="transfer"]:before {
  content: "\f024";
}

// Trolley
uitk-icon[name="trolley"]:before,
.icon-trolley:before {
  content: "\e604";
}

// Walk
uitk-icon[name="walk"]:before,
.icon-walk:before {
  content: "\e0b7";
}

// Wifi
uitk-icon[name="wifi"]:before,
.icon-wifi:before {
  content: "\e072";
}

// Wine
uitk-icon[name="wine"]:before,
.icon-wine:before {
  content: "\e083";
}

// CONTENT Navigation
// Settings
uitk-icon[name="settings"]:before,
.icon-settings:before {
  content: "\e01f";
}

// Offcanvas
uitk-icon[name="offcanvas"]:before,
.icon-offcanvas:before {
  content: "\e0b2";
}

// List
uitk-icon[name="list"]:before,
.icon-list:before {
  content: "\e0b3";
}

// Filter
uitk-icon[name="filter"]:before,
.icon-filter:before {
  content: "\e037";
}

// Ellipsis
uitk-icon[name="ellipsis"]:before,
.icon-ellipsis:before {
  content: "\e0b4";
}

// Search
uitk-icon[name="search"]:before,
.icon-search:before {
  content: "\e01d";
}

// tools
uitk-icon[name="tools"]:before {
  content: "\f021";
}

// Home
uitk-icon[name="home"]:before,
.icon-home:before {
  content: "\e01b";
}

// CONTENT Action
// Add
uitk-icon[name="add"]:before,
.icon-add:before {
  content: "\e0ac";
}

// Subtract
uitk-icon[name="subtract"]:before,
.icon-subtract:before {
  content: "\e0ad";
}

// Success
// .icon-successalt is created for default alert icon settings
uitk-icon[name="success"]:before,
uitk-icon[name="successalt"]:before, // DEPRECATE
.icon-success:before,
.icon-successalt:before {
  content: "\e0aa";
}

// Close
uitk-icon[name="close"]:before,
uitk-icon[name="close-thin"]:before, // DEPRECATE
.icon-close:before,
.icon-close-thin:before {
  content: "\e0ab";
}

// checkbox-empty
uitk-icon[name="checkbox-empty"]:before {
  content: "\f029";
}

// checkbox-checked
uitk-icon[name="checkbox-checked"]:before {
  content: "\f030";
}

// Refresh
uitk-icon[name="refresh"]:before,
.icon-refresh:before {
  content: "\e807";
}

// In progress
uitk-icon[name="in-progress"]:before,
.icon-in-progress:before {
  content: "\e906";
}

// Copy
uitk-icon[name="copy"]:before,
.icon-copy:before {
  content: "\e062";
}

// Edit
uitk-icon[name="edit"]:before,
.icon-edit:before {
  content: "\e803";
}

// Print
uitk-icon[name="print"]:before,
.icon-print:before {
  content: "\e066";
}

//Trash
uitk-icon[name="trash"]:before,
.icon-trash:before {
  content: "\e90a";
}

// Lock
uitk-icon[name="lock"]:before,
.icon-lock:before {
  content: "\e0bb";
}

// Unlock
uitk-icon[name="unlock"]:before,
.icon-unlock:before {
  content: "\f007";
}

// Calendar
uitk-icon[name="calendar"]:before,
.icon-calendar:before {
  content: "\e800";
}

uitk-icon[name="block"]:before {
  content: "\e904";
}

// CONTENT View
// Enlarge
uitk-icon[name="enlarge"]:before,
.icon-enlarge:before {
  content: "\e900";
}

// Shrink
uitk-icon[name="shrink"]:before,
.icon-shrink:before {
  content: "\e901";
}

// Gridview
uitk-icon[name="gridview"]:before,
.icon-gridview:before {
  content: "\e060";
}

// Viewed
uitk-icon[name="viewed"]:before,
.icon-viewed:before {
  content: "\e064";
}

// Hidden
uitk-icon[name="hidden"]:before,
.icon-hidden:before {
  content: "\e902";
}

// No image
uitk-icon[name="noimage"]:before,
.icon-noimage:before {
  content: "\e033";
}

// Image gallery
uitk-icon[name="image-gallery"]:before,
.icon-image-gallery:before {
  content: "\e035";
}

// CONTENT Share
// Attachment
uitk-icon[name="attachment"]:before,
.icon-attachment:before {
  content: "\f009";
}

// File
uitk-icon[name="file"]:before,
.icon-file:before {
  content: "\e05d";
}

// Files
uitk-icon[name="files"]:before,
.icon-files:before {
  content: "\e05e";
}

// Security document
uitk-icon[name="security-document"]:before,
.icon-security-document:before {
  content: "\f010";
}

// Receipt
uitk-icon[name="receipt"]:before,
.icon-receipt:before {
  content: "\f011";
}

// Folder
uitk-icon[name="folder"]:before,
.icon-folder:before {
  content: "\f012";
}

// Link
uitk-icon[name="link"]:before,
.icon-link:before {
  content: "\f013";
}

// Download
uitk-icon[name="download"]:before,
.icon-download:before {
  content: "\e909";
}

// Upload
uitk-icon[name="upload"]:before,
.icon-upload:before {
  content: "\e908";
}

// Popup
uitk-icon[name="popup"]:before,
.icon-popup:before {
  content: "\e06a";
}

// Share
uitk-icon[name="share"]:before,
.icon-share:before {
  content: "\e068";
}

// Android-share
uitk-icon[name="android-share"]:before {
  content: "\f028";
}

// CONTENT Notification
// Award
uitk-icon[name="award"]:before,
.icon-award:before {
  content: "\e0c1";
}

// Bell
uitk-icon[name="bell"]:before,
.icon-bell:before {
  content: "\e02d";
}

// Bookmark
uitk-icon[name="bookmark"]:before,
.icon-bookmark:before {
  content: "\e054";
}

// Flag
uitk-icon[name="flag"]:before,
.icon-flag:before {
  content: "\e042";
}

// Heart
uitk-icon[name="heart"]:before,
.icon-heart:before {
  content: "\e04c";
}

// Tag
uitk-icon[name="tag"]:before,
.icon-tag:before {
  content: "\e600";
}

// Thumbs up
uitk-icon[name="thumbsup"]:before,
.icon-thumbsup:before {
  content: "\e04a";
}

// Time
uitk-icon[name="time"]:before,
.icon-time:before {
  content: "\e027";
}

// CONTENT Alert
// Info
uitk-icon[name="info"]:before,
.icon-info:before {
  content: "\e05c";
}

uitk-icon[name="infoalt"]:before,
.icon-infoalt:before {
  content: "\e05b";
}

// Warning
// .icon-error and .icon-erroralt is created for default alert icon settings
uitk-icon[name="warn"]:before,
uitk-icon[name="error"]:before, // DEPRECATE
.icon-warn:before,
.icon-error:before {
  content: "\e058";
}

uitk-icon[name="warnalt"]:before,
uitk-icon[name="erroralt"]:before, // DEPRECATE
.icon-warnalt:before,
.icon-erroralt:before {
  content: "\e057";
}

// Help
uitk-icon[name="help"]:before,
.icon-help:before {
  content: "\e05a";
}

uitk-icon[name="helpalt"]:before,
.icon-helpalt:before {
  content: "\e059";
}

// CONTENT Rating
// Circle
uitk-icon[name="circle"]:before,
.icon-circle:before {
  content: "\e052";
}

uitk-icon[name="half-circle"]:before,
.icon-half-circle:before {
  content: "\e051";
}

uitk-icon[name="empty-circle"]:before,
.icon-empty-circle:before {
  content: "\e050";
}

// Circle filled (primarily used as background for stacking)
uitk-icon[name="circle-filled"]:before,
.icon-circle-filled:before {
  content: "\e0ca";
}

// Star
uitk-icon[name="star"]:before,
.icon-star:before {
  content: "\e04d";
}

uitk-icon[name="half-star"]:before,
.icon-half-star:before {
  content: "\e04e";
}

uitk-icon[name="empty-star"]:before,
.icon-empty-star:before {
  content: "\e04f";
}

// CONTENT Contact
// Phone
uitk-icon[name="phone"]:before,
.icon-phone:before {
  content: "\e021";
}

// Headset
uitk-icon[name="headset"]:before,
.icon-headset:before {
  content: "\e040";
}

// Comment
uitk-icon[name="comment"]:before,
.icon-comment:before {
  content: "\e031";
}

// Mail
uitk-icon[name="mail"]:before,
.icon-mail:before {
  content: "\e02f";
}

// Microphone
uitk-icon[name="microphone"]:before,
.icon-microphone:before {
  content: "\f014";
}

// Speaker
uitk-icon[name="speaker"]:before,
.icon-speaker:before {
  content: "\f027";
}

// CONTENT Device
// Wearable
uitk-icon[name="wearable"]:before,
.icon-wearable:before {
  content: "\f015";
}

// Tablet
uitk-icon[name="tablet"]:before,
.icon-tablet:before {
  content: "\f016";
}

// Mobile
uitk-icon[name="mobile"]:before,
.icon-mobile:before {
  content: "\e0c9";
}

// Computer
uitk-icon[name="computer"]:before,
.icon-computer:before {
  content: "\e0c7";
}

// Global
uitk-icon[name="global"]:before,
.icon-global:before {
  content: "\e048";
}

// CONTENT Map
// Pin
uitk-icon[name="pin"]:before,
.icon-pin:before {
  content: "\f017";
}

// Location
uitk-icon[name="location"]:before,
.icon-location:before {
  content: "\e023";
}

// Destination
uitk-icon[name="destination"]:before,
.icon-destination:before {
  content: "\e056";
}

// Map
uitk-icon[name="map"]:before,
.icon-map:before {
  content: "\e80b";
}

// Compass
uitk-icon[name="compass"]:before,
.icon-compass:before {
  content: "\f018";
}

// CONTENT Charts
// Pie chart
uitk-icon[name="pie-chart"]:before,
.icon-pie-chart:before {
  content: "\e06c";
}

// Trend
uitk-icon[name="trend"]:before,
.icon-trend:before {
  content: "\e06e";
}

// Line graph
uitk-icon[name="line-graph"]:before,
.icon-line-graph:before {
  content: "\e070";
}

// USER & PROFILE
// Auth
uitk-icon[name="auth"]:before,
.icon-auth:before {
  content: "\e0b9";
}

// Add traveler
uitk-icon[name="add-traveler"]:before,
.icon-add-traveler:before {
  content: "\e017";
}

// Group
uitk-icon[name="group"]:before,
.icon-group:before {
  content: "\e015";
}

// Handshake
uitk-icon[name="handshake"]:before,
.icon-handshake:before {
  content: "\e805";
}

// Traveler
uitk-icon[name="traveler"]:before,
.icon-traveler:before {
  content: "\e011";
}

// Travelers
uitk-icon[name="travelers"]:before,
.icon-travelers:before {
  content: "\e013";
}

// User
uitk-icon[name="user"]:before,
.icon-user:before {
  content: "\e903";
}

// Passport
uitk-icon[name="passport"]:before,
.icon-passport:before {
  content: "\f019";
}

// Id card
uitk-icon[name="id-card"]:before,
.icon-id-card:before {
  content: "\f020";
}

// Credit card
uitk-icon[name="credit-card"]:before,
.icon-credit-card:before {
  content: "\e03b";
}

uitk-icon[name="disinfectant"]:before{
  content: "\e905";
}

uitk-icon[name="cleanliness"]:before{
  content: "\e907";
}

uitk-icon[name="face_mask"]:before{
  content: "\e90b";
}

uitk-icon[name="gloves"]:before{
  content: "\e90c";
}

uitk-icon[name="hand_sanitizer"]:before{
  content: "\e90d";
}

uitk-icon[name="protect"]:before{
  content: "\e90e";
}

uitk-icon[name="social_distance"]:before{
  content: "\e90f";
}

uitk-icon[name="temperature"]:before{
  content: "\e910";
}

uitk-icon[name="bundled_savings_plus"]:before {
  content: "\e920";
}

uitk-icon[name="mod"]:before {
  content: "\e91d";
}

uitk-icon[name="notifications_none"]:before {
  content: "\e91a";
}

uitk-icon[name="notifications_off"]:before {
  content: "\e919";
}

uitk-icon[name="notifications"]:before {
  content: "\e918";
}

uitk-icon[name="notifications_active"]:before {
  content: "\e917";
}

uitk-icon[name="sanitizer"]:before {
  content: "\e916";
}

uitk-icon[name="negotiated"]:before {
  content: "\e915";
}

uitk-icon[name="file_copy"]:before {
  content: "\e914";
}

uitk-icon[name="speed"]:before {
  content: "\e913";
}

uitk-icon[name="coronavirus"]:before {
  content: "\e912";
}

uitk-icon[name="clean_hands"]:before {
  content: "\e911";
}

uitk-icon[name="ondemand_entertainment"]:before {
  content: "\e91b";
}

uitk-icon[name="loyalty"]:before {
  content: "\e91e";
}

uitk-icon[name="loyalty_no"]:before {
  content: "\e91f";
}

uitk-icon[name="check_circle"]:before {
  content: "\e91c";
}

uitk-icon[name="assignment"]:before {
  content: "\e921";
}

// OTHERS!!!! & DEPRECATE!!!
// DEPRECATE Arrows thin
uitk-icon[name="arrow0-thin"]:before,
.icon-arrow0-thin:before {
  content: "\e08a";
}
uitk-icon[name="arrow45-thin"]:before,
.icon-arrow45-thin:before {
  content: "\e08b";
}
uitk-icon[name="arrow90-thin"]:before,
.icon-arrow90-thin:before {
  content: "\e08c"
}
uitk-icon[name="arrow135-thin"]:before,
.icon-arrow135-thin:before {
  content: "\e08d";
}
uitk-icon[name="arrow180-thin"]:before,
.icon-arrow180-thin:before {
  content: "\e08e";
}
uitk-icon[name="arrow225-thin"]:before,
.icon-arrow225-thin:before {
  content: "\e08f";
}
uitk-icon[name="arrow270-thin"]:before,
.icon-arrow270-thin:before {
  content: "\e090";
}
uitk-icon[name="arrow315-thin"]:before,
.icon-arrow315-thin:before {
  content: "\e091";
}
// DEPRECATE Toggles thin
uitk-icon[name="toggle0-thin"]:before,
.icon-toggle0-thin:before {
  content: "\e092";
}
uitk-icon[name="toggle90-thin"]:before,
.icon-toggle90-thin:before {
  content: "\e093";
}
uitk-icon[name="toggle180-thin"]:before,
.icon-toggle180-thin:before {
  content: "\e094";
}
uitk-icon[name="toggle270-thin"]:before,
.icon-toggle270-thin:before {
  content: "\e095";
}
// DEPRECATE Egencia bird
uitk-icon[name="egencia-birdalt"]:before,
.icon-egencia-birdalt:before {
  content: "\e044";
}
// DEPRECATE Flights
uitk-icon[name="flights0"]:before,
.icon-flights0:before {
  content: "\e001";
}
uitk-icon[name="flights90"]:before,
.icon-flights90:before {
  content: "\e001";
}
uitk-icon[name="flightsalt"]:before,
uitk-icon[name="flightsalt45"]:before,
.icon-flightsalt:before,
.icon-flightsalt45:before {
  content: "\e001";
}
uitk-icon[name="flightsalt0"]:before,
.icon-flightsalt0:before {
  content: "\e001";
}
uitk-icon[name="flightsalt90"]:before,
.icon-flightsalt90:before {
  content: "\e001";
}
// DEPRECATE Hotels
uitk-icon[name="hotelsalt"]:before,
.icon-hotelsalt:before {
  content: "\e003";
}
// DEPRECATED Train
uitk-icon[name="trainalt"]:before,
.icon-trainalt:before {
  content: "\e007";
}
// DEPRECATE Cars
uitk-icon[name="carsalt"]:before,
.icon-carsalt:before {
  content: "\e005";
}
// DEPRECATED Taxi
uitk-icon[name="taxialt"]:before,
.icon-taxialt:before {
  content: "\e009";
}
// DEPRECATE Bus
uitk-icon[name="busalt"]:before,
.icon-busalt:before {
  content: "\e00b";
}
// DEPRECATE Accessible
uitk-icon[name="accessiblealt"]:before,
.icon-accessiblealt:before {
  content: "\e088";
}
// DEPRECATE Activities
uitk-icon[name="activitiesalt"]:before,
.icon-activitiesalt:before {
  content: "\e0bf";
}
// DEPRECATE Briefcase
uitk-icon[name="briefcase-filled"]:before,
uitk-icon[name="briefcasealt"]:before,
.icon-briefcase-filled:before,
.icon-briefcasealt:before {
  content: "\e80a";
}
// DEPRECATE Coffee
uitk-icon[name="coffeealt"]:before,
.icon-coffeealt:before {
  content: "\e07d";
}
// DEPRECATE Croissant
uitk-icon[name="croissantalt"]:before,
.icon-croissantalt:before {
  content: "\e07f";
}
// DEPRECATE Dining
uitk-icon[name="diningalt"]:before,
.icon-diningalt:before {
  content: "\e081";
}
// DEPRECATE Gym
uitk-icon[name="gymalt"]:before,
.icon-gymalt:before {
  content: "\e087";
}
// DEPRECATE Lights
uitk-icon[name="lightsalt"]:before,
.icon-lightsalt:before {
  content: "\e0c5";
}
// DEPRECATE Luggage
uitk-icon[name="luggagealt"]:before,
.icon-luggagealt:before {
  content: "\e075";
}
// DEPRECATE Overnight
uitk-icon[name="overnightalt"]:before,
.icon-overnightalt:before {
  content: "\e02b";
}
// DEPRECATE Parking
uitk-icon[name="parkingalt"]:before,
.icon-parkingalt:before {
  content: "\e07b";
}
// DEPRECATE Pool
uitk-icon[name="poolalt"]:before,
.icon-poolalt:before {
  content: "\e085";
}
// DEPRECATE Service dog
uitk-icon[name="service-dogalt"]:before,
.icon-service-dogalt:before {
  content: "\e602";
}
// DEPRECATE Shield
uitk-icon[name="shieldalt"]:before,
.icon-shieldalt:before {
  content: "\e0c3";
}
// DEPRECATE Snowflake
uitk-icon[name="snowflakealt"]:before,
.icon-snowflakealt:before {
  content: "\e079";
}
// DEPRECATE Sun
uitk-icon[name="sunalt"]:before,
.icon-sunalt:before {
  content: "\e029";
}
// DEPRECATE Ticket
uitk-icon[name="ticketalt"]:before,
.icon-ticketalt:before {
  content: "\e03d";
}
// DEPRECATE Tickets
uitk-icon[name="ticketsalt"]:before,
.icon-ticketsalt:before {
  content: "\e03f";
}
// DEPRECATE Transmission
uitk-icon[name="transmissionalt"]:before,
.icon-transmissionalt:before {
  content: "\e077";
}
// DEPRECATE Walk
uitk-icon[name="walkalt"]:before,
.icon-walkalt:before {
  content: "\e0b7";
}
// DEPRECATE Partial wifi
uitk-icon[name="partial-wifi"]:before,
.icon-partial-wifi:before {
  content: "\e072";
}
// DEPRECATE Wine alt
uitk-icon[name="winealt"]:before,
.icon-winealt:before {
  content: "\e083";
}
// DEPRECATE Settings alt
uitk-icon[name="settingsalt"]:before,
.icon-settingsalt:before {
  content: "\e01f";
}
// DEPRECATE Filter alt
uitk-icon[name="filteralt"]:before,
.icon-filteralt:before {
  content: "\e037";
}
// DEPRECATE Search alt
uitk-icon[name="searchalt"]:before,
.icon-searchalt:before {
  content: "\e01d";
}
// DEPRECATE Home alt
uitk-icon[name="homealt"]:before,
.icon-homealt:before {
  content: "\e01b";
}
// DEPRECATE Copy alt
uitk-icon[name="copyalt"]:before,
.icon-copyalt:before {
  content: "\e062";
}
// DEPRECATE print alt
uitk-icon[name="printalt"]:before,
.icon-printalt:before {
  content: "\e066";
}
// DEPRECATE Lock alt
uitk-icon[name="lockalt"]:before,
.icon-lockalt:before {
  content: "\e0bb";
}
// DEPRECATE Gridview alt
uitk-icon[name="gridviewalt"]:before,
.icon-gridviewalt:before {
  content: "\e060";
}
// DEPRECATE viewed alt
uitk-icon[name="viewedalt"]:before,
.icon-viewedalt:before {
  content: "\e064";
}
// DEPRECATE no image alt
uitk-icon[name="noimagealt"]:before,
.icon-noimagealt:before {
  content: "\e064";
}
// DEPRECATE image gallery alt
uitk-icon[name="image-galleryalt"]:before,
.icon-image-galleryalt:before {
  content: "\e035";
}
// DEPRECATE Popup alt
uitk-icon[name="popupalt"]:before,
.icon-popupalt:before {
  content: "\e06a";
}
// DEPRECATE Share alt
uitk-icon[name="sharealt"]:before,
.icon-sharealt:before {
  content: "\e068";
}
// DEPRECATE Award alt
uitk-icon[name="awardalt"]:before,
.icon-awardalt:before {
  content: "\e0c1";
}
// DEPRECATE Bell alt
uitk-icon[name="bellalt"]:before,
.icon-bellalt:before {
  content: "\e02d";
}
// DEPRECATE Bookmark alt
uitk-icon[name="bookmarkalt"]:before,
.icon-bookmarkalt:before {
  content: "\e054";
}
// DEPRECATE Flag alt
uitk-icon[name="flagalt"]:before,
.icon-flagalt:before {
  content: "\e042";
}
// DEPRECATE Heart alt
uitk-icon[name="heartalt"]:before,
.icon-heartalt:before {
  content: "\e04c";
}
// DEPRECATE Tag alt
uitk-icon[name="tagalt"]:before,
.icon-tagalt:before {
  content: "\e600";
}
// DEPRECATE Thumbs up alt
uitk-icon[name="thumbsupalt"]:before,
.icon-thumbsupalt:before {
  content: "\e04a";
}
// DEPRECATE Phone alt
uitk-icon[name="phonealt"]:before,
.icon-phonealt:before {
  content: "\e021";
}
// DEPRECATE Comment alt
uitk-icon[name="commentalt"]:before,
.icon-commentalt:before {
  content: "\e031";
}
// DEPRECATE Mail alt
uitk-icon[name="mailalt"]:before,
.icon-mailalt:before {
  content: "\e02f";
}
// DEPRECATE Mobile alt
uitk-icon[name="mobilealt"]:before,
.icon-mobilealt:before {
  content: "\e0c9";
}
// DEPRECATE Computer alt
uitk-icon[name="computeralt"]:before,
.icon-computeralt:before {
  content: "\e0c7";
}
// DEPRECATE Global alt
uitk-icon[name="globalalt"]:before,
.icon-globalalt:before {
  content: "\e048";
}
// DEPRECATE Location alt
uitk-icon[name="locationalt"]:before,
.icon-locationalt:before {
  content: "\e023";
}
// DEPRECATE Destination alt
uitk-icon[name="destinationalt"]:before,
.icon-destinationalt:before {
  content: "\e056";
}
// DEPRECATE Map alt
uitk-icon[name="mapalt"]:before,
.icon-mapalt:before {
  content: "\e80b";
}
// DEPRECATE Auth alt
uitk-icon[name="authalt"]:before,
.icon-authalt:before {
  content: "\e0b9";
}
// DEPRECATE Add Traveler alt
uitk-icon[name="add-traveleralt"]:before,
.icon-add-traveleralt:before {
  content: "\e017";
}
// DEPRECATE Group alt
uitk-icon[name="groupalt"]:before,
.icon-groupalt:before {
  content: "\e015";
}
// DEPRECATE Traveler alt
uitk-icon[name="traveleralt"]:before,
.icon-traveleralt:before {
  content: "\e011";
}
// DEPRECATE Travelers
uitk-icon[name="travelersalt"]:before,
.icon-travelersalt:before {
  content: "\e013";
}
// DEPRECATE User alt
uitk-icon[name="useralt"]:before,
.icon-useralt:before {
  content: "\e019";
}
// DEPRECATED Credit card alt
uitk-icon[name="credit-cardalt"]:before,
.icon-credit-cardalt:before {
  content: "\e03b";
}
// DEPRECATE Expand
uitk-icon[name="expand"]:before,
.icon-expand:before {
  content: "\e094";
  transition: all 0.5s;
}
// DEPRECATE Expand thin
uitk-icon[name="expand-thin"]:before,
.icon-expand-thin:before {
  content: "\e094";
  transition: all 0.5s;
}
// DEPRECATE Collapse
uitk-icon[name="collapse"]:before,
.icon-collapse:before {
  content: "\e092";
}
// DEPRECATE Collapse thin
uitk-icon[name="collapse-thin"]:before,
.icon-collapse-thin:before {
  content: "\e092";
}
// DEPRECATE Trend alt
uitk-icon[name="trendalt"]:before,
.icon-trendalt:before {
  content: "\e06e";
}
// DEPRECATE Line graph alt
uitk-icon[name="line-graphalt"]:before,
.icon-line-graphalt:before {
  content: "\e070";
}
// DEPRECATE Pie chart alt
uitk-icon[name="pie-chartalt"]:before,
.icon-pie-chartalt:before {
  content: "\e06c";
}