// ==========================================================================
//Common Layouts
// ==========================================================================

// Global Layout Styles
// =========================================================================

// Container for Grid
.site-content {
  max-width: @absMaxWidth;
  width: 100%;
  margin: 0 auto;
  padding: @layoutSiteContentPadding;
  .cf();
}

// Container that stretches the Grid edge-to-edge
.site-content-full-width {
  height: 100%;
  width: 100%;
  .cf();
}

// Box-Sizing to make our lives easier
// Remove tap highlight color
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: get-token(color__neutral__100);
}

// set min-width for browsers without @media support
.mediaqueries {
  body {
    min-width: ((240 / 16) + 0em);
  }
}

// Offcanvas properties on #page wrapper
#page {
  transition: transform @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
  &.show-offcanvas {
    transform: translateX(~"calc(-100% + 3.375rem)");
    @media (min-width: @breakpointScreenMediumMin) {
      transform: translateX(0);
    }
  }
}

// Medium or smaller screens have less padding around site-content
@media only screen and (max-width: @breakpointScreenMediumMax) {
  .site-content {
    padding: @layoutSiteContentPadding/2;
  }
}

@media only screen and (max-width: @breakpointScreenSmallMax) {

  .site-content {
    &.site-content-small-screen {
      padding: 0;
    }
  }
}

@media only screen and (max-width: @breakpointHeaderMenuMobile) {
  // Lock scrolling when mobile menu is open
  html.lock-scroll {
    overflow: hidden;
    height: 100%;

    body {
      padding-top: 3.375rem;
      overflow: hidden;
      height: 100%;
    }
  }
}

// Interstitial
// =========================================================================
.has-interstitial
{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}
