// BADGE
// ==========================================================================

uitk-badge{
  font-size: 0.6875rem; // 11/16
  font-weight: @fontWeightMed;
  padding: 0.3125rem 0.5625rem; // 5/16 9/16
  border-radius: 40000px;
  background: @colorBlueMed;
  color: @defaultLight;
  vertical-align: middle;
  display: inline-block;
  line-height: normal; // For aligning the height

  // If no value then don't display
  &:empty {
    display: none;
  }

  // Default margin for a list of Badges
  & + & {
    margin-left: 0.375rem; // 6/16
  }

  > uitk-icon,
  > .icon,
  > img,
  > span {
    &:before {
      vertical-align: middle;
    }
  }

  > .icon,
  > img,
  > uitk-icon {
    margin-right: 0.375rem; // 6/16
  }

  > img {
    max-height: 1rem;
  }

  &[count] {
    &:empty {
      display: inline-block;
    }

    &:before {
      content: attr(count);
    }

    &:not(:empty) {
      &:before {
        content: attr(count) " ";
      }
    }
  }

  // Background type
  &[type="info"] {
    background: @colorGoldMed;
    color: @colorSlateDark;
  }

  &[type="success"] {
    background: @colorGreenMed;
  }

  &[type="warn"] {
    background: @colorRedMed;
  }

  &[type="error"] {
    background: @colorRedMed;
  }

  // Pill styles
  &[type*="pill"] {
    border-radius: 40000px;
  }

  &[type="pill"] {
    padding: 0.25rem 0.5rem; // 4/16 8/16
    white-space: nowrap;
    background: @defaultLight;
    color: @colorSlateDark;
    border: 1px solid @colorGrayscale6;
  }

  &[type="pill-green"] {
    background: @colorGreenMed;
  }

  &[type="pill-egencia"] {
    background: @colorGoldMed;
    color: @colorSlateDark;
  }

  &[type="pill-agent"] {
    color: @defaultLight;
    background: @colorGreenMed;

    .icon,
    uitk-icon[name="headset"] {
      color: @defaultLight;
    }
  }

  &[type="pill-overnight"] {
    color: @colorSlateDark;
    background: transparent;
    border: 1px solid transparent;

    .icon,
    uitk-icon {
      color: @colorOrangeMed;
    }
  }

  &[type="overnight"] {
    background: transparent;
    color: @colorOrangeMed;
    padding-top: 0;
    vertical-align: text-top;
  }
}