//  Interaction Patterns
//
//  Uses CSS3 Animations in-place of JavaScript
//
//   ==========================================================================


// Remove Interaction
// ==========================================================================
.remove-animated {
  transition: height @interactRemoveTime, opacity @interactRemoveTime;
  height: auto;

  // Fade Animation
  &.animated-fade {
    opacity: 0 !important;
  }
  // Slide Animation
  &.animated-slide {
    opacity: 0 !important;
    overflow: hidden !important;
    height: 0 !important;
  }
}


// Loading Animation
// ==========================================================================
// Loader Animation
.loader-animation() {
  from {
    transform: rotate(@interactLoaderRotateFrom);
  }
  to {
    transform: rotate(@interactLoaderRotateTo);
  }
}
// Keyframes
@-webkit-keyframes loader {.loader-animation;}
@-moz-keyframes loader {.loader-animation;}
@-o-keyframes loader {.loader-animation;}
@keyframes loader {.loader-animation;}


// Bouncing Animation
// ==========================================================================
// Bounce Animation
.bounce-animation() {
  from, 8%, 19%, 28%, to {
    .animation(cubic-bezier(0.215, 0.610, 0.355, 1.000));
    transform: translate3d(0, 0, 0);
  }
  14%, 16% {
    .animation(cubic-bezier(0.755, 0.050, 0.855));
    transform: translate3d(0, -8px, 0);
  }
  22% {
    .animation(cubic-bezier(0.755, 0.050, 0.855));
    transform: translate3d(0, -5px, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
}
// Keyframes
@-webkit-keyframes bounce {.bounce-animation()}
@-moz-keyframes bounce {.bounce-animation()}
@-o-keyframes bounce {.bounce-animation()}
@keyframes bounce {.bounce-animation()}


// Fade In Up Animation
// ==========================================================================
// fadeInUp Animation
.fadeInUp-animation() {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
// Keyframes
@-webkit-keyframes fadeInUp {.fadeInUp-animation()}
@-moz-keyframes fadeInUp {.fadeInUp-animation()}
@-o-keyframes fadeInUp {.fadeInUp-animation()}
@keyframes fadeInUp {.fadeInUp-animation()}


// Fade In Down Animation
// ==========================================================================
// fadeInDown Animation
.fadeInDown-animation() {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
// Keyframes
@-webkit-keyframes fadeInDown {.fadeInDown-animation()}
@-moz-keyframes fadeInDown {.fadeInDown-animation()}
@-o-keyframes fadeInDown {.fadeInDown-animation()}
@keyframes fadeInDown {.fadeInDown-animation()}

// Fade Out Up Animation
// ==========================================================================
// fadeOutUp Animation
.fadeOutUp-animation() {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
// Keyframes
@-webkit-keyframes fadeOutUp {.fadeOutUp-animation()}
@-moz-keyframes fadeOutUp {.fadeOutUp-animation()}
@-o-keyframes fadeOutUp {.fadeOutUp-animation()}
@keyframes fadeOutUp {.fadeOutUp-animation()}


// Fade Out Down Animation
// ==========================================================================
// fadeOutDown Animation
.fadeOutDown-animation() {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
// Keyframes
@-webkit-keyframes fadeOutDown {.fadeOutDown-animation()}
@-moz-keyframes fadeOutDown {.fadeOutDown-animation()}
@-o-keyframes fadeOutDown {.fadeOutDown-animation()}
@keyframes fadeOutDown {.fadeOutDown-animation()}


// Slide In Up Animation
// ==========================================================================
// slideInUp Animation
.slideInUp-animation() {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
// Keyframes
@-webkit-keyframes slideInUp {.slideInUp-animation()}
@-moz-keyframes slideInUp {.slideInUp-animation()}
@-o-keyframes slideInUp {.slideInUp-animation()}
@keyframes slideInUp {.slideInUp-animation()}


// Slide In Down Animation
// ==========================================================================
// slideInDown Animation
.slideInDown-animation() {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
// Keyframes
@-webkit-keyframes slideInDown {.slideInDown-animation()}
@-moz-keyframes slideInDown {.slideInDown-animation()}
@-o-keyframes slideInDown {.slideInDown-animation()}
@keyframes slideInDown {.slideInDown-animation()}
