#scroll-to-top {
  position: fixed;
  bottom: 3rem;  // 48/16
  right: 3rem;  // 48/16
  #zIndex.fixedElements();
}

// Small screen (up to and including iPhone 6 Plus in portrait)
@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  #scroll-to-top {
    height: 1.3125rem;  // 21/16
    bottom: 0.75rem; // 12/16
    right: 0.75rem; // 12/16

    .btn-label {
      font-size: 0.6875rem;  // 11/16
      line-height: 1.2;
      padding: 0 0.75rem;  // 12/16
    }
  }
}