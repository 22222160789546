#modal-contextual-help .modal-body{
  padding: 0;
}
.contextual-help-content, .contextual-help-page{
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

body.scrollable {
  overflow: auto;
}
