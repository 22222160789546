@icon-content-gutter-size: 1.2rem;
@alertBorderWidthLeft: 3px;

uitk-alert,
.alert {
  margin-bottom: 0.4rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  border-left: none;
  border-radius: get-token('banner__corner_radius');
  display: flex;
  border-left-width: 0;
  background-color: @banner-background-color;
  position: relative;
  box-shadow: @shadowInner;
  
  font-size: 0.875rem;
  font-weight: 400;
  color: get-token(banner__text_color);

  &.gray {
    background-color: @alertBgColorGray;
  }

  &:before {
    border-radius: 2px 0 0 2px;
    width: 0.1875rem;
    content: "";
    position: absolute;
    top: -0.066rem;
    bottom: -0.066rem;
    left: 0;
  }

  > uitk-icon,
  > i.icon {
    margin: 1.538rem auto;
  }
  
  uitk-icon,
  i.icon {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    
    &:before {
      font-size: 1.5rem;
    }
  }

  .alert-message {
    width: 100%;
    display: inline-flex;
    position: relative;
    margin: 1.538rem auto;
    
    > uitk-icon,
    > .icon {
      vertical-align: middle;
    }
    
    p, uitk-icon, .icon {
      color: get-token(banner__text_color);
    }
    
    .alert-title {
      color: get-token(banner__heading__text_color);
      font-weight: get-token(banner__heading__font_weight);
      font-size: 0.875rem;
      margin-bottom: 0;
    }

    .alert-message-content {
      display: inline-block;
      white-space: normal;
      margin-left: @icon-content-gutter-size;
      margin-right: @icon-content-gutter-size;

      > ul {
        margin: 0;
        list-style-position: inside;
        padding: 0;

        > li {
          // Ensure wrapped text doesn't display under bullet point
          padding-left: 1.3rem;
          text-indent: -1.3rem;
        }
      }

      .alert-title + p, .alert-title + ul {
        margin-top: 0;
        margin-bottom: 0.769rem;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  uitk-icon + .alert-message,
  i.icon + .alert-message {
    margin-left: get-token(banner__icon__spacing_inner_right);
    display: inline-block;
  }
  
  a {
    color: get-token(banner__text_color);
    text-decoration: underline;
  }

  .btn-close {

    > uitk-icon,
    > .icon {
      color: get-token(banner__icon__fill_color);
    }

    //taking out the :before styles
    &:before {
      display: none;
    }
  }

  a {
    text-decoration: underline; // TODO links with underlines... make sure this isn't covered somewhere else
  }

  // Alert error coloring
  &[type="error"],
  &.alert-error {
    background: get-token(error_summary__background_color);
  }
}

.alert-text-error {	
  color: @alertErrorColor;	
}	

.alert-text-success {	
  color: @alertSuccessColor;	
}	

.alert-text-info {	
  color: @alertInfoColor;	
}	

.alert-text-warn {	
  color: @alertWarnColor;
}