// ==========================================================================
// Non-semantic helper classes
// ==========================================================================
/* For image replacement */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.ir br {
  display: none;
}

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden {
  display: none !important;
  visibility: hidden;
}

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.is-visually-hidden, //compatiblity with uitk-react for elements that escape container like live-announce
.visuallyhidden {
  border: none;
  clip: rect(0 0 0 0);
  height: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 0;
}

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* Hide visually and from screenreaders, but maintain layout */
.invisible {
  visibility: hidden;
}

/* Truncate text to one line and add ellipsis */
.truncate {
  white-space: nowrap;
  #truncate();
}

/* Contain floats: h5bp.com/q */
.cf {
  .cf();
}

/* Clear floats */
.clear {
  clear: both;
}

// Prevent user selecting text (usually for avoiding ugly unwanted highlighting when click things)
.notextselect {
  .notextselect();
}

.left {
  float: left;
}

.right {
  float: right;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align:right;
}

.align-left {
  text-align:left;
}

.align-justify {
  text-align:justify;
}

.dashed {
  border-style: dashed;
}

.middled {
  text-align: center;
  vertical-align: middle;
}

.break{
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}

.sticky {
  // !important use is by design. Sticky is often applied to elements with position: relative style and those are either cascading or have a higher specificity score
  position: sticky !important;
  top: 0;
  #zIndex.base(); // Tooltip is at z-index 1000, so Tooltips inside a Sticky need to stay on top
  .cf();
}

// Global Classes for removing border radius (important is add to override css rules with id specificity score)
.border-radius-none {
 border-radius: 0 !important;
}

.border-radius-top-none {
  .border-radius(tl, @baseFontPx, 0) !important;
  .border-radius(tr, @baseFontPx, 0) !important;
}

.border-radius-bottom-none {
  .border-radius(bl, @baseFontPx, 0) !important;
  .border-radius(br, @baseFontPx, 0) !important;

}

.border-radius-left-none {
  .border-radius(tl, @baseFontPx, 0) !important;
  .border-radius(bl, @baseFontPx, 0) !important;
}

.border-radius-right-none {
  .border-radius(tr, @baseFontPx, 0) !important;
  .border-radius(br, @baseFontPx, 0) !important;
}

// === GLOBAL MARGIN CLASS ===
.margin-all-extra-small { margin: @marginExtraSmall; }
.margin-all-small { margin: @marginSmall; }
.margin-all-med { margin: @marginDefault; }
.margin-all-large { margin: @marginLarge; }
.margin-all-extra-large { margin: @marginExtraLarge; }

.margin-top-extra-small { margin-top: @marginExtraSmall; }
.margin-top-small { margin-top: @marginSmall; }
.margin-top-med { margin-top: @marginDefault; }
.margin-top-large { margin-top: @marginLarge; }
.margin-top-extra-large { margin-top: @marginExtraLarge; }

.margin-right-extra-small { margin-right: @marginExtraSmall; }
.margin-right-small { margin-right: @marginSmall;  }
.margin-right-med { margin-right: @marginDefault; }
.margin-right-large { margin-right: @marginLarge; }
.margin-right-extra-large { margin-right: @marginExtraLarge; }

.margin-bottom-extra-small { margin-bottom: @marginExtraSmall; }
.margin-bottom-small { margin-bottom: @marginSmall; }
.margin-bottom-med { margin-bottom: @marginDefault;  }
.margin-bottom-large { margin-bottom: @marginLarge; }
.margin-bottom-extra-large { margin-bottom: @marginExtraLarge; }

.margin-left-extra-small { margin-left: @marginExtraSmall; }
.margin-left-small { margin-left: @marginSmall; }
.margin-left-med { margin-left: @marginDefault;  }
.margin-left-large { margin-left: @marginLarge; }
.margin-left-extra-large { margin-left: @marginExtraLarge; }

.margin-none { margin: 0 !important;}

// === GLOBAL PADDING CLASS ===
.padding-all-extra-small { padding: @paddingExtraSmall; }
.padding-all-small { padding: @paddingSmall; }
.padding-all-med { padding: @paddingDefault; }
.padding-all-large { padding: @paddingLarge; }
.padding-all-extra-large { padding: @paddingExtraLarge; }

.padding-top-extra-small { padding-top: @paddingExtraSmall; }
.padding-top-small { padding-top: @paddingSmall; }
.padding-top-med { padding-top: @paddingDefault; }
.padding-top-large { padding-top: @paddingLarge; }
.padding-top-extra-large { padding-top: @paddingExtraLarge; }

.padding-right-extra-small { padding-right: @paddingExtraSmall; }
.padding-right-small { padding-right: @paddingSmall; }
.padding-right-med { padding-right: @paddingDefault; }
.padding-right-large { padding-right: @paddingLarge; }
.padding-right-extra-large { padding-right: @paddingExtraLarge; }

.padding-bottom-extra-small { padding-bottom: @paddingExtraSmall; }
.padding-bottom-small { padding-bottom: @paddingSmall; }
.padding-bottom-med { padding-bottom: @paddingDefault;  }
.padding-bottom-large { padding-bottom: @paddingLarge; }
.padding-bottom-extra-large { padding-bottom: @paddingExtraLarge; }

.padding-left-extra-small { padding-left: @paddingExtraSmall; }
.padding-left-small { padding-left: @paddingSmall; }
.padding-left-med { padding-left: @paddingDefault; }
.padding-left-large { padding-left: @paddingLarge; }
.padding-left-extra-large { padding-left: @paddingExtraLarge; }

.padding-none { padding: 0 !important; }

// Flex direction
.direction-column {
  flex-direction: column;
}

// TODO: fix error output so it no longer pushes the body down
.debug {
  position: relative;

  /*[class*="inset-"] {background: whiteSmoke}
    [class*="outset-"] {background: pink} */

  /* Utility Methods to highlight issues with the site
   * Add class of "debug " */
  /* Images and anchors. */
  img:not([alt]), img[alt=""], img[alt^=" "], a[href=""], a[href^=" "] {
    outline: 2px solid red !important;
    outline-offset: 3px !important;
  }

  head:before {
    display: block;
    .border(a, @h3, @borderWidthBase, @colorBlueMed);
    .padding(a, @h3, 24);
    background: @alertInfoColor;
    #font.sizeIncludeLineheight(@h3);
    #font.weight(medium);
    color: @colorBlueMed;
    content: "Debug: Enabled. Errors in this document will be highlighted in red";
  }

  /* Title and description. */
  head, title:empty,
  meta[name="description"][content=""],
  meta[name="keywords"][content=""] {
    display: block;
  }

  // Basic :pseudo elemenmt rules
  title:empty:before, link:before,
  meta[name="description"][content=""]:before,
  meta[name="description"][content=""]:before {
    display: block;
    .margin(tr, @baseFontPx, 6, 12);
    .debugWarning();
  }

  // Show error messages
  title:empty:before {
    content: "You've left the <title> empty!";
  }
  link:before {
    content: "You don't have a <title>!"
  }
  title ~ link {
    display: none;
  }
  meta[name="description"][content=""]:before {
    content: "You've left <meta> description empty!";
  }
  meta[name="keywords"][content=""]:before {
    content: "You've left <meta> keywords empty (not required)!";
  }

  //setup basic debug-only warning styles
  .debugWarning() {
    position: absolute;
    .border(a, @baseFontPx, @borderWidthBase, @colorError);
    .padding(tr, @baseFontPx, 12, 24);
    background: @alertErrorColor;
    #font.weight(medium);
    color: white;
  }

}

/* Media Query Messages */
#debug {
  .mqMsg(
    @colorSlateDark
  ) {
    .debug:before {
      content: @colorSlateDark;
      text-align: center;
      position: absolute;
      color: purple;
      font-size: 150%;
      #font.weight(medium);
      background: rgba(255, 255, 255, .9);
      top: 0;
      right: 0;
      left: 0;
      line-height: 4;
    }
  }
}

// ==========================================================================
// Responsive Utilities
// ==========================================================================
// Small screen (up to and including iPhone 6 Plus in portrait)
@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  // Important added to override css rules with id specificity score
  .hide-small-screen {
    display: none !important;
  }

  .full-width-small-screen {
    width: 100% !important;
  }

  .align-left-small-screen {
    text-align: left !important;
  }

  .nofloat-small-screen {
    float: none !important;
  }

  .display-block-small-screen {
    display: block !important;
  }

  // === GLOBAL MARGIN CLASS Small screen ===
  .margin-all-extra-small-small-screen { margin: @marginExtraSmall; }
  .margin-all-small-small-screen { margin: @marginSmall; }
  .margin-all-med-small-screen { margin: @marginDefault; }
  .margin-all-large-small-screen { margin: @marginLarge; }
  .margin-all-extra-large-small-screen { margin: @marginExtraLarge; }

  .margin-top-extra-small-small-screen { margin-top: @marginExtraSmall; }
  .margin-top-small-small-screen { margin-top: @marginSmall; }
  .margin-top-med-small-screen { margin-top: @marginDefault; }
  .margin-top-large-small-screen { margin-top: @marginLarge; }
  .margin-top-extra-large-small-screen { margin-top: @marginExtraLarge; }

  .margin-right-extra-small-small-screen { margin-right: @marginExtraSmall; }
  .margin-right-small-small-screen { margin-right: @marginSmall;  }
  .margin-right-med-small-screen { margin-right: @marginDefault; }
  .margin-right-large-small-screen { margin-right: @marginLarge; }
  .margin-right-extra-larg-small-screene { margin-right: @marginExtraLarge; }

  .margin-bottom-extra-small-small-screen { margin-bottom: @marginExtraSmall; }
  .margin-bottom-small-small-screen { margin-bottom: @marginSmall; }
  .margin-bottom-med-small-screen { margin-bottom: @marginDefault;  }
  .margin-bottom-large-small-screen { margin-bottom: @marginLarge; }
  .margin-bottom-extra-large-small-screen { margin-bottom: @marginExtraLarge; }

  .margin-left-extra-small-small-screen { margin-left: @marginExtraSmall; }
  .margin-left-small-small-screen { margin-left: @marginSmall; }
  .margin-left-med-small-screen { margin-left: @marginDefault;  }
  .margin-left-large-small-screen { margin-left: @marginLarge; }
  .margin-left-extra-large-small-screen { margin-left: @marginExtraLarge; }

  .margin-none { margin: 0 !important;}

  // === GLOBAL PADDING CLASS Small screen ===
  .padding-all-extra-small-small-screen { padding: @paddingExtraSmall; }
  .padding-all-smal-small-screenl { padding: @paddingSmall; }
  .padding-all-med-small-screen { padding: @paddingDefault; }
  .padding-all-large-small-screen { padding: @paddingLarge; }
  .padding-all-extra-large-small-screen { padding: @paddingExtraLarge; }

  .padding-top-extra-smal-small-screen { padding-top: @paddingExtraSmall; }
  .padding-top-small-small-screen { padding-top: @paddingSmall; }
  .padding-top-med-small-screen { padding-top: @paddingDefault; }
  .padding-top-large-small-screen { padding-top: @paddingLarge; }
  .padding-top-extra-large-small-screen { padding-top: @paddingExtraLarge; }

  .padding-right-extra-small-small-screen { padding-right: @paddingExtraSmall; }
  .padding-right-small-small-screen { padding-right: @paddingSmall; }
  .padding-right-med-small-screen { padding-right: @paddingDefault; }
  .padding-right-large-small-screen { padding-right: @paddingLarge; }
  .padding-right-extra-large-small-screen { padding-right: @paddingExtraLarge; }

  .padding-bottom-extra-small-small-screen { padding-bottom: @paddingExtraSmall; }
  .padding-bottom-small-small-screen { padding-bottom: @paddingSmall; }
  .padding-bottom-med-small-screen { padding-bottom: @paddingDefault;  }
  .padding-bottom-large-small-screen { padding-bottom: @paddingLarge; }
  .padding-bottom-extra-large-small-screen { padding-bottom: @paddingExtraLarge; }

  .padding-left-extra-small-small-screen { padding-left: @paddingExtraSmall; }
  .padding-left-small-small-screen { padding-left: @paddingSmall; }
  .padding-left-med-small-screen { padding-left: @paddingDefault; }
  .padding-left-large-small-screen { padding-left: @paddingLarge; }
  .padding-left-extra-large-small-screen { padding-left: @paddingExtraLarge; }

  .padding-none-small-screen { padding: 0 !important; }

}

// Medium Screen (just bigger than iPhone 6 Plus and up to and including iPad in portrait)
@media only screen and (min-width: @breakpointScreenMediumMin) and (max-width: @breakpointScreenMediumMax) {
  // Important added to override css rules with id specificity score
  .hide-medium-screen {
    display: none !important;
  }

  .full-width-medium-screen {
    width: 100% !important;
  }

  .display-block-medium-screen {
    display: block !important;
  }

  // === GLOBAL MARGIN CLASS Medium screen===
  .margin-all-extra-small-medium-screen { margin: @marginExtraSmall; }
  .margin-all-small-medium-screen { margin: @marginSmall; }
  .margin-all-med-medium-screen { margin: @marginDefault; }
  .margin-all-large-medium-screen { margin: @marginLarge; }
  .margin-all-extra-large-medium-screen { margin: @marginExtraLarge; }

  .margin-top-extra-small-medium-screen { margin-top: @marginExtraSmall; }
  .margin-top-small-medium-screen { margin-top: @marginSmall; }
  .margin-top-med-medium-screen { margin-top: @marginDefault; }
  .margin-top-large-medium-screen { margin-top: @marginLarge; }
  .margin-top-extra-large-medium-screen { margin-top: @marginExtraLarge; }

  .margin-right-extra-small-medium-screen { margin-right: @marginExtraSmall; }
  .margin-right-small-medium-screen { margin-right: @marginSmall;  }
  .margin-right-med-medium-screen { margin-right: @marginDefault; }
  .margin-right-large-medium-screen { margin-right: @marginLarge; }
  .margin-right-extra-large-medium-screen { margin-right: @marginExtraLarge; }

  .margin-bottom-extra-small-medium-screen { margin-bottom: @marginExtraSmall; }
  .margin-bottom-small-medium-screen { margin-bottom: @marginSmall; }
  .margin-bottom-med-medium-screen { margin-bottom: @marginDefault;  }
  .margin-bottom-large-medium-screen { margin-bottom: @marginLarge; }
  .margin-bottom-extra-large-medium-screen { margin-bottom: @marginExtraLarge; }

  .margin-left-extra-small-medium-screen { margin-left: @marginExtraSmall; }
  .margin-left-small-medium-screen { margin-left: @marginSmall; }
  .margin-left-med-medium-screen { margin-left: @marginDefault;  }
  .margin-left-large-medium-screen { margin-left: @marginLarge; }
  .margin-left-extra-large-medium-screen { margin-left: @marginExtraLarge; }

  .margin-none { margin: 0 !important;}

  // === GLOBAL PADDING CLASS Medium screen ===
  .padding-all-extra-small-medium-screen { padding: @paddingExtraSmall; }
  .padding-all-small-medium-screen { padding: @paddingSmall; }
  .padding-all-med-medium-screen { padding: @paddingDefault; }
  .padding-all-large-medium-screen { padding: @paddingLarge; }
  .padding-all-extra-large-medium-screen { padding: @paddingExtraLarge; }

  .padding-top-extra-small-medium-screen { padding-top: @paddingExtraSmall; }
  .padding-top-small-medium-screen { padding-top: @paddingSmall; }
  .padding-top-med-medium-screen { padding-top: @paddingDefault; }
  .padding-top-large-medium-screen { padding-top: @paddingLarge; }
  .padding-top-extra-large-medium-screen { padding-top: @paddingExtraLarge; }

  .padding-right-extra-small-medium-screen { padding-right: @paddingExtraSmall; }
  .padding-right-small-medium-screen { padding-right: @paddingSmall; }
  .padding-right-med-medium-screen { padding-right: @paddingDefault; }
  .padding-right-large-medium-screen { padding-right: @paddingLarge; }
  .padding-right-extra-large-medium-screen { padding-right: @paddingExtraLarge; }

  .padding-bottom-extra-small-medium-screen { padding-bottom: @paddingExtraSmall; }
  .padding-bottom-small-medium-screen { padding-bottom: @paddingSmall; }
  .padding-bottom-med-medium-screen { padding-bottom: @paddingDefault;  }
  .padding-bottom-large-medium-screen { padding-bottom: @paddingLarge; }
  .padding-bottom-extra-large-medium-screen { padding-bottom: @paddingExtraLarge; }

  .padding-left-extra-small-medium-screen { padding-left: @paddingExtraSmall; }
  .padding-left-small-medium-screen { padding-left: @paddingSmall; }
  .padding-left-med-medium-screen { padding-left: @paddingDefault; }
  .padding-left-large-medium-screen { padding-left: @paddingLarge; }
  .padding-left-extra-large-medium-screen { padding-left: @paddingExtraLarge; }

  .padding-none-medium-screen { padding: 0 !important; }
}

// Large Screen (screen size that are greater than iPad in portrait )
@media only screen and (min-width: @breakpointScreenLargeMin) {
  // Important added to override css rules with id specificity score
  .hide-large-screen {
    display: none !important;
  }
}
