// -----------------------------------------------------------------------------
// Modal and sliding panel components
// -----------------------------------------------------------------------------
@modalBgActiveOpacity: @opacityDark;
@modalBgColor: @colorSlateDark;
@modalBodyMaxHeight: 32.8125rem;
@modalBorderRadius: 8px;
@modalTopMargin: @marginLarge*3;
@modalTransition: all 0.2s cubic-bezier(0.250, 0.450, 0.450, 0.950);

// Modal -- Background
.modal-background {
  position: fixed;
  z-index: @zIndexModal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: @modalBgColor;
  transition: @modalTransition;

  &.active {
    opacity: @modalBgActiveOpacity;
    transition: @modalTransition;
  }
}

// Modal -- Outer wrapper
.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: @modalTransition;
  pointer-events: none;
  padding: @paddingLarge;
  z-index: @zIndexModal+1;
  min-height: auto;
  overflow-y: scroll;

  // on small screen the modal is full width/height
  @media only screen and (max-width: @breakpointScreenMediumMin) {
    padding: 0;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;

    .modal-inner {
      transform: translateY(0);
    }
  }
}

// Modal -- Inner content
.modal-inner {
  position: relative;
  background: white;
  border-radius: @modalBorderRadius;
  box-shadow: @shadowLarge;
  margin: @modalTopMargin auto;
  max-width: 41.25rem;
  transform: translateY(-100%);
  transition: @modalTransition;

  // on small screen the modal is full width/height
  @media only screen and (max-width: @breakpointScreenMediumMin) {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  &.loading {
    .modal-header, .modal-body {
      display: none;
    }

    .modal-loader {
      display: block;
    }

    .modal-footer + .modal-close {
      visibility: visible;
    }
  }
}

// Modal -- Close button
.modal-close {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  height: auto;
  line-height: 100%;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.25rem;
  cursor: pointer;
  color: @colorGrayscale4;
}

.modal-with-title .modal-close {
  color: @colorBlueMed; // accent 2
}

// Modal -- Header
.modal-header {
  padding: 1rem;
  border-bottom: 1px solid @colorGrayscale2;

  .modal-title {
    font-weight: @fontWeightMed;
    margin: 0;
  }

  .modal-subtitle {
    font-weight: @fontWeightRegular;
    opacity: @opacityDark;
    margin-top: 0.5rem;
  }

  .modal-title-icon, .icon-stack {
    display: inline-block;
    font-size: 2rem;
    background-color: white;
    vertical-align: middle;
    height: 2.5rem;
    width: 2.25rem;

    + .modal-title {
      padding-left: 0.5rem;
      display: inline-block;
      width: calc(100% - 4rem);
      vertical-align: middle;
    }
  }
}

.modal-alert {
  margin: 0;
  padding: 1rem 1rem 0 1rem;

  .modal-alert-title {
    .H(1);
    font-weight: @fontWeightMed;
    padding-right: 1rem; // space for close btn
    margin: 0;
  }

  .modal-alert-icon, .icon-stack {
    display: inline-block;
    font-size: 2rem;
    background-color: white;
    vertical-align: middle;
    height: 2.5rem;
    width: 2.25rem;

    + .modal-alert-title {
      padding-left: 0.5rem;
      display: inline-block;
      width: calc(100% - 4rem);
      vertical-align: middle;
    }
  }

  // alert colors
  &.alert-text-info {
    > .icon-stack {
      background-color: lighten(@colorInfo, 32%);
    }
  }

  &.alert-text-success {
    > .icon-stack {
      background-color: lighten(@colorSuccess, 32%);
    }
  }

  &.alert-text-warn {
    > .icon-stack {
      background-color: lighten(@colorWarn, 32%);
    }
  }

  &.alert-text-error {
    > .icon-stack {
      background-color: lighten(@colorError, 32%);
    }
  }

  i, .modal-alert-icon {
    color: inherit;
  }

  ~ .modal-body {
    padding-top: @paddingLarge;
  }
}

// Modal -- Body
.modal-body {
  max-height: @modalBodyMaxHeight;
  overflow: auto;
  padding: 1rem;

  h3.modal-title {
    margin: 0 0 0.5rem;
  }

  &:empty {
    height: 0;
    overflow: hidden;
  }

  // on small screen push everything else down
  @media only screen and (max-width: @breakpointScreenMediumMin) {
    flex: 1;
    max-height: initial;
  }
}

// Modal -- Footer
.modal-footer {
  padding: 0rem 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;

  .modal-footer-btns {
    padding: 0.5rem 0;
    border-top: 1px solid @colorGrayscale2; // Use Box section border color (no neutral400 use 300 instead)
    display: flex;
    justify-content: flex-end;
    
    .btn {
      margin-left: 1rem;
    }

    @media only screen and (max-width: @breakpointScreenMediumMin) {
      display: flex;
      margin-left: initial;

      .btn {
        flex: 1;
      }
    }
  }

  .opt-out {
    margin-bottom: 0;
    padding: 0 1rem 1rem;

    .modal-opt-out-label {
      margin: @layoutSiteContentPadding 0 0 auto;
      padding: 0.2rem 2rem 0 0;

      &:before {
        left: initial;
        right: 0;
      }

      &:after {
        right: 0.35rem;
        left: initial !important;
      }
    }
  }
}

// Modal -- Loader
.modal-loader {
  display: none;
  text-align: center;
}

// active-modal class is applied to <html> when a modal is active
.active-modal {
  body {
    overflow: hidden;
  }

  .active-tooltip {
    z-index: @zIndexModal+1;
  }
}

// feedback widget specific style
#modal-feedback-widget, #modal-feedback-nps-widget{
  &+ .modal-background {
    background: transparent;
  }

  .modal-body {
    padding: 0;
  }

  .feedback-content {
    height: 99%;
    width: 100%;
    border: none;
  }
}
