// Calendar -- base
// This is for the Tooltip, form control is down below
.cal {
  * {
    box-sizing: border-box;
  }
  overflow: hidden;
  position: relative;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    float: left;
    padding-bottom: 0;
    text-align: center;
    width: @calendarDateWidth + 0%;

    &.date-offset-1 {
      margin-left: @calendarDateWidth + 0%;
    }

    &.date-offset-2 {
      margin-left: @calendarDateWidth*2 + 0%;
    }

    &.date-offset-3 {
      margin-left: @calendarDateWidth*3 + 0%;
    }

    &.date-offset-4 {
      margin-left: @calendarDateWidth*4 + 0%;
    }

    &.date-offset-5 {
      margin-left: @calendarDateWidth*5 + 0%;
    }

    &.date-offset-6 {
      margin-left: @calendarDateWidth*6 + 0%;
    }
  }

  h5 {
    .margin(trbl, 15, @spaceSmall, 0, @spaceLarge, 0);
    text-align: center;
    color: @colorSlateLight;
  }

  .next, .prev {
    border: none;
    color: @calendarArrowColor;
    position: absolute;
    margin: 0;
    height: auto;
    #zIndex.base(3);

    .pos(t, @calendarArrowFontSize, 17);
    .btn-label {
      margin: 0;
      padding: 0;
    }
  }

  .next {
    .pos(r, @calendarArrowFontSize, 18);
    &.last-month {
      display: none;
    }
  }

  .prev {
    .pos(l, @calendarArrowFontSize, 18);
    &.first-month {
      display: none;
    }
  }

  .vertical-divider {
    position: absolute;
    left: 0;
    top: 5%;
    bottom: 0;
    .border(l, @baseFontPx, @borderWidthMedium, @colorGrayscale2);
    height: 90%;
    .margin(trbl, @baseFontPx, 0, 0, 0, -1.5);
    #zIndex.base(1);
    display: none; //for now...
  }
}

// Calendar -- section wrapper
.cal-section-wrapper {
  .cf();
  position: relative;
}

// Calendar -- annual
.cal.annual {
  .cal-section-wrapper {
    float: left;
    width: 66.6%;
  }

  .cal-section {
    width: 50%;

    .year {
      display: none;
    }

    .prev-year,
    .next-year {
      #font.size(24);
      color: @calendarArrowColor;
      margin: 0;
    }

    .prev-year .first-year {
      visibility: hidden;
    }

    .next-year .last-year {
      visibility: hidden;
    }

    &.new-year .year {
      display: inline;
      .margin(l, 36, 5);
      color: #999999;
    }
  }

  .next {
    .pos(r, 14, 20);
  }

  .prev {
    .pos(l, 14, 20);
  }

  .vertical-divider.new-year {
    left: 50%;
    display: block;
  }
}

// Calendar -- year selector section (annual)
.cal-section-year {
  .notextselect();
  .padding(a, @baseFontPx, 12);
  .min-height(@baseFontPx, 305);
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3%;
  background-color: @defaultLight;
  #zIndex.base(2);

  .year-control {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .year {
      margin-bottom: 0;
      display: block;
      #font.size(46);
      #font.weight(regular);
    }
  }

  .btn {
    margin: 0;
  }
}

.cal-days li {
  color: @colorGrayscale5;
  #font.size(10);
  #font.weight(regular);
}

.cal-section,
.cal .timepicker-section {
  float: left;
  .padding(a, @baseFontPx, 12);
  width: 50%;
  .notextselect();
}

.cal-section {
  .min-height(@baseFontPx, 305);
  position: relative;
}

.cal-dates {

  a {
    display: block;
    #font.line-height(15, 1.65);
    #font.weight(medium);
    width: 100%;
    position: relative;
    text-decoration: none;
    .padding(b, @baseFontPx, 3);

    .cal-day {
      position: relative;
      .height(@baseFontPx, @calendarCalDayFontSize);
      color: @calendarNormalTextColor;
      .border(a, @baseFontPx, @borderWidthBase, transparent);
      #font.weight(regular);
    }

    &.highlight .cal-day {
      background: @calendarHighlightBgColor;
      color: @calendarHighlightTextColor;
    }

    &.start,
    &.end,
    &.highlight-start,
    &.highlight-end,
    &:hover,
    &:focus,
    &:active {
      .cal-day {
        text-decoration: none;
        .border-radius(a, @baseFontPx, @calendarHighlightBorderRadius);
        #zIndex.base(2);
        background: @calendarHighlightBgColor;
        color: @calendarHighlightTextColor;
      }
    }

    &.start,
    &.highlight-start {
      &.highlight {
        .cal-day {
          .border-radius(tlbl,@baseFontPx,@calendarHighlightBorderRadius,@calendarHighlightBorderRadius);
        }
      }
    }

    &.end,
    &.highlight-end {
      &.highlight {
        .cal-day {
          .border-radius(trbr,@baseFontPx,@calendarHighlightBorderRadius,@calendarHighlightBorderRadius);
        }
      }
    }

    &.start.highlight-end.highlight,
    &.end.highlight-start.highlight,
    &.start.end.highlight {
      .cal-day {
        .border-radius(a, @baseFontPx, @calendarHighlightBorderRadius);
      }
    }

    &.disabled .cal-day {
      color: @calendarDisabledTextColor;
      cursor: default;
    }
  }
}

// The single Date Range form control
.date-range {
  .margin(b, @formInputFontSize, @formControlMargin);
  display: flex;
  flex-wrap: wrap;

  // TODO any use case?? can we remove it??
  //.cal-dates-separator {
  //  .margin(t, @mediumFontPx, 36);
  //  .padding(tr, @mediumFontPx, 8, 12);
  //  .border(t, @baseFontPx, @borderWidthBase, @calendarBorderColor);
  //  .border(b, @baseFontPx, @borderWidthBase, @calendarBorderColor);
  //  float: left;
  //  #font.size(@mediumFontPx);
  //  line-height: normal;
  //  color: @inputTextColor;
  //  background: #ffffff;
  //}

  .input-group {
    margin-bottom: 0;
    width: 50%;

    input {
      position: relative;

      &:focus {
        #zIndex.forms(1);
      }
    }

    uitk-icon, .icon {
      #zIndex.forms(2);
    }

    &:first-of-type {
      input {
        .border-radius(tlbl, @baseFontPx, @formInputRadius, @formInputRadius);
      }
    }

    + .input-group {
      margin-left: -1px;

      input {
        .border-radius(trbr, @baseFontPx, @formInputRadius, @formInputRadius);
      }
    }
  }
}

// The css solution for clicking through element
.cal-text-input + .icon.icon-calendar {
  pointer-events: none;
}

.cal-timepicker {
  .cf();
  clear: both;

  .timepicker {
    float: left;
    width: 50%;
  }
}

// Breakpoint -- max-width medium/tablet
@media only screen and (max-width: @breakpointScreenMediumMax) {
  .cal.annual {
    .cal-section-wrapper {
      width: 100%;
    }
    .cal-section {
      width: 50%;
      .min-height(@baseFontPx,312);
    }

    .vertical-divider.new-year {
      left: 50%;
      display: block;
    }
  }

  .cal-section-year {
    .min-height(@baseFontPx, 0);
    float: none;
    width: 100%;
  }
}

// Breakpoint -- max-width small/mobile
@media only screen and (max-width: @breakpointScreenSmallMax) {
  .cal {
    .next {
      &.last-month {
        display: inline;
      }
    }

    &.show-second-month {
      .next.last-month {
        display: none;
      }
    }
  }

  .cal-section,
  .cal.annual .cal-section {
    width:100%;
    .min-height(@baseFontPx,312);

    ~ .cal-section {
      display: none;
    }
  }

  .cal-section-year .year-control .year {
    #font.size(24);
  }

  .cal.annual .vertical-divider.new-year {
    display: none;
  }
}

// To be backward compatible, Safari Hack to remove clear fix TODO remove after v8
html:not(.chrome):not(.firefox):not(.ie11) {
  .date-range {
    &:before,
    &:after {
      content: none;
    }
  }
}