/*DEPRECATED IN UTIK9 */
.box {
  background-color: @boxBgColor;
  .border(a, @baseFontPx, @boxBorderWidth, @boxBorderColor);

  .box-section {
    border-radius: 0;

    &:last-of-type {
      border: none;
    }
  }
}

.box-alt {
  .box-section {
    margin-top: -1px;
    background-color: @boxBgColor;
    .border(a, @baseFontPx, @boxBorderWidth, @boxBorderColor);
    border-radius: 0;

    &:first-of-type {
      margin-top: 0;
      .border-radius(tlr, @baseFontPx, @boxBorderRadius, @boxBorderRadius);
    }
    &:last-of-type {
      .border-radius(blr, @baseFontPx, @boxBorderRadius, @boxBorderRadius);
    }
  }
}

.well {
  background-color: @boxWellBgColor;
}

.box,
.box-section,
.well {
  position: relative;
  .border-radius(a, @baseFontPx, @boxBorderRadius);
  .cf();

  .box-secondary {
    background-color: @boxSecondaryBgColor;
  }

  .box-header,
  .box-footer,
    // V9 Remove
  .box-flush {
    position: relative;
    .cf();
  }

  .box-header,
  .box-section {
    .border(b, @baseFontPx, @boxBorderWidth, @boxBorderColor);
  }

  .box-footer {
    .border(t, @baseFontPx, @boxBorderWidth, @boxBorderColor);
  }

  &.box-padded,
  .box-padded {
    padding: @paddingDefault;
  }

  // Toggle show more and show less
  .box-bumper {
    .border(t, @baseFontPx, @boxBorderWidth, @boxBorderColor);
    width: auto;

    [data-control='toggle'].btn {
      .border-radius(tlrbrl, @baseFontPx, 0, 0, @boxBtnBorderRadius, @boxBtnBorderRadius);
      width: 100%;
      margin: 0;
      background-color: @boxBtnBgColor;
    }
  }
}

.box:not(.hidden) + .box,
.box:not(.hidden) + .box-alt,
.box:not(.hidden) + .well,
.box:not(.hidden) ~ .box.hidden + .box,
.box:not(.hidden) ~ .box-alt.hidden + .box,
.box:not(.hidden) ~ .well.hidden + .box,
.box:not(.hidden) ~ .box.hidden + .box-alt,
.box:not(.hidden) ~ .box-alt.hidden + .box-alt,
.box:not(.hidden) ~ .well.hidden + .box-alt,
.box:not(.hidden) ~ .box.hidden + .well,
.box:not(.hidden) ~ .box-alt.hidden + .well,
.box:not(.hidden) ~ .well.hidden + .well,
.box-alt:not(.hidden) ~ .box.hidden + .box,
.box-alt:not(.hidden) ~ .box-alt.hidden + .box,
.box-alt:not(.hidden) ~ .well.hidden + .box,
.box-alt:not(.hidden) ~ .box.hidden + .box-alt,
.box-alt:not(.hidden) ~ .box-alt.hidden + .box-alt,
.box-alt:not(.hidden) ~ .well.hidden + .box-alt,
.box-alt:not(.hidden) ~ .box.hidden + .well,
.box-alt:not(.hidden) ~ .box-alt.hidden + .well,
.box-alt:not(.hidden) ~ .well.hidden + .well,
.well:not(.hidden) ~ .box.hidden + .box,
.well:not(.hidden) ~ .box-alt.hidden + .box,
.well:not(.hidden) ~ .well.hidden + .box,
.well:not(.hidden) ~ .box.hidden + .box-alt,
.well:not(.hidden) ~ .box-alt.hidden + .box-alt,
.well:not(.hidden) ~ .well.hidden + .box-alt,
.well:not(.hidden) ~ .box.hidden + .well,
.well:not(.hidden) ~ .box-alt.hidden + .well,
.well:not(.hidden) ~ .well.hidden + .well {
  .margin(t, @baseFontPx, @boxMarginTop);

}

.box-small-grouping {
  .box:not(.hidden) + .box,
  .box:not(.hidden) + .box-alt,
  .box:not(.hidden) + .well,
  .box:not(.hidden) ~ .box.hidden + .box,
  .box:not(.hidden) ~ .box-alt.hidden + .box,
  .box:not(.hidden) ~ .well.hidden + .box,
  .box:not(.hidden) ~ .box.hidden + .box-alt,
  .box:not(.hidden) ~ .box-alt.hidden + .box-alt,
  .box:not(.hidden) ~ .well.hidden + .box-alt,
  .box:not(.hidden) ~ .box.hidden + .well,
  .box:not(.hidden) ~ .box-alt.hidden + .well,
  .box:not(.hidden) ~ .well.hidden + .well,
  .box-alt:not(.hidden) ~ .box.hidden + .box,
  .box-alt:not(.hidden) ~ .box-alt.hidden + .box,
  .box-alt:not(.hidden) ~ .well.hidden + .box,
  .box-alt:not(.hidden) ~ .box.hidden + .box-alt,
  .box-alt:not(.hidden) ~ .box-alt.hidden + .box-alt,
  .box-alt:not(.hidden) ~ .well.hidden + .box-alt,
  .box-alt:not(.hidden) ~ .box.hidden + .well,
  .box-alt:not(.hidden) ~ .box-alt.hidden + .well,
  .box-alt:not(.hidden) ~ .well.hidden + .well,
  .well:not(.hidden) ~ .box.hidden + .box,
  .well:not(.hidden) ~ .box-alt.hidden + .box,
  .well:not(.hidden) ~ .well.hidden + .box,
  .well:not(.hidden) ~ .box.hidden + .box-alt,
  .well:not(.hidden) ~ .box-alt.hidden + .box-alt,
  .well:not(.hidden) ~ .well.hidden + .box-alt,
  .well:not(.hidden) ~ .box.hidden + .well,
  .well:not(.hidden) ~ .box-alt.hidden + .well,
  .well:not(.hidden) ~ .well.hidden + .well  {
    .margin(t, @baseFontPx, @boxSmallGroupingMarginTop);
  }
}

// Box -- No borders
.box-no-border {
  border: none;
}

@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  .site-content.site-content-small-screen {
    .box,
    uitk-box {
      border-radius: 0;
      border-right: none;
      border-left: none;
    }
  }
}

@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenMediumMax) {
  .box {
    &.box-padded,
    .box-padded {
      padding: @paddingSmall;
    }
  }
}
/*END DEPRECATED IN UTIK9 */


uitk-box {
  display: block;
  background-color: @boxBgColor;
  .border(a, @baseFontPx, @boxBorderWidth, @boxBorderColor);

  &:not(.hidden) ~ uitk-box.hidden + uitk-box {
    .margin(t, @baseFontPx, @boxMarginTop);
  }

  &:not(.hidden) + uitk-box:not(.hidden){
    .margin(t, @baseFontPx, @boxMarginTop);
  }

  &.secondary {
    font-size: 1rem; //override secondary font style
    background-color: @boxSecondaryBgColor;
  }

  header,
  footer,
  section {
    display: block;
    border: 0px solid @boxBorderColor;
    padding: @paddingDefault;
    background: transparent;
  }

  header {
    border-bottom-width: 1px;
  }
  footer {
    border-top-width: 1px;
  }
  section {
    border-bottom-width: 1px;

    &:last-of-type {
      border-bottom-width: 0px;
    }
    &.secondary {
      background-color: @boxSecondaryBgColor;
      font-size: 1rem;
    }
  }

  .box-bumper {
    .border(t, @baseFontPx, @boxBorderWidth, @boxBorderColor);
    width: auto;
    background-color: @boxSecondaryBgColor;

    [data-control="toggle"].btn {
      .border-radius(tlrbrl, @baseFontPx, 0, 0, @boxBtnBorderRadius, @boxBtnBorderRadius);
      margin-top: 0px;
      width: 100%;
      background-color: @boxBtnBgColor;
    }
  }
}