.pill {
  #font.size(@pillFontSize);
  #font.weight(@pillFontWeight);
  .border-radius(a, @pillFontSize, @pillBorderWidth);
  .padding(trbl, @pillFontSize, 4, 12, 4, 12);
  display: inline-block;
  white-space: nowrap;

  &.pill-default {
    color: @pillDefaultTextColor;
    background-color: @pillDefaultBgColor;
    border: 1px solid @pillDefaultBorderColor;
    .border(a, @pillFontSize, 1, @pillDefaultBorderColor)
  }

  &.pill-green {
    color: @pillGreenTextColor;
    background-color: @pillGreenBgColor;
    .border(a, @pillFontSize, 1, @pillGreenBorderColor);
  }

  &.pill-egencia {
    color: @colorSlateDark;
    background-color: @colorGrayscale3;
    .border(a, @pillFontSize, 1, @colorGrayscale3);

    uitk-icon, .icon {
      color: @colorGoldMed;
    }
  }

  &.pill-agent {
    color: @colorSlateDark;
    background-color: @colorGreenLight;
    .border(a, @pillFontSize, 1, @colorGreenLight);
    uitk-icon, .icon {
      color: @colorGreenMed;
    }
  }

  > uitk-icon,
  > .icon,
  > img,
  > span {
    vertical-align: middle;
  }

  > uitk-icon,
  > .icon,
  > img {
    .margin(r, @pillFontSize, @pillMargin);
  }

  > img {
    .max-height(@pillFontSize, @pillImgMaxHeight);
  }
}