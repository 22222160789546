@colorNeutralWhite: get-token('color__neutral__white');
@breakpointHeaderMenuMinHeight: 450px;
@breakpointHeaderMenuTablet: 1024px;
@breakpointHeaderMenuMobile: 700px;
@headerMenuToggleTransDuration: 0.25s;
@headerMenuToggleTransTimeFunc: cubic-bezier(0.250, 0.450, 0.450, 0.950);

// Global header
#global-header {
  width: 100%;
  background: @colorNeutralWhite;
  position: relative;
  #zIndex.fixedElements();
  border-bottom: 1px @colorGrayscale2 solid;

  .site-content {
    padding: 0;

    @media (min-width: @breakpointHeaderMenuMobile) {
      padding: 0 @layoutSiteContentPadding/2;
    }
    @media (min-width: @breakpointScreenMediumMax) {
      padding: 0 @layoutSiteContentPadding;
    }
  }

  .lock-scroll & {
    position: fixed;
    top: 0;
  }
}

// Global header: site navigation -- layout
#global-header .site-navigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 3.625rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @colorNeutralWhite;

    @media (min-width: @breakpointHeaderMenuMobile) {
      display: none;
    }
  }

  // Hide/show nav elements based on viewport width
  #primary-nav-desktop,
  #account-nav-desktop,
  #account-nav-desktop-toggle {
    display: none;
  }
  @media (min-width: @breakpointHeaderMenuMobile) {
    justify-content: center;

    #site-logo {
      flex: none;
    }

    #primary-nav-desktop {
      display: flex;
      flex: 1;
    }

    #account-nav-desktop {
      display: flex;
    }

    #account-nav-desktop-toggle {
      display: flex;
    }

    #primary-nav-mobile,
    #primary-nav-toggle,
    #account-nav-mobile,
    #account-nav-mobile-open {
      display: none;
    }
  }
}

.site-navigation:before {
  #zIndex.openLayer(1, 6);
}

#site-logo,
#primary-nav-toggle,
#account-nav-mobile-open,
#global-search {
  #zIndex.openLayer(2, 6);
}

#account-nav-mobile,
#account-nav-mobile-close {
  #zIndex.openLayer(3, 6);
}

// Global header -- flex order
#global-header .site-navigation {
  #site-logo           { order: 2; }
  #primary-nav-desktop { order: 1; }
  #site-utilities      { order: 3; }
  @media (min-width: @breakpointHeaderMenuMobile) {
    #site-logo           { order: 1; }
    #primary-nav-desktop { order: 2; }
    #site-utilities      { order: 3; }
  }
}

// Site logo
#site-logo {
  flex: 1;

  a {
    padding: 1.384rem 0;
    display: block;
    margin: auto;
    width: 6.125rem;
    background-image: url('/media/amexgbt-egencia-logo-header.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6.125rem 2rem;
  }
}

// Global header site utilities container
#site-utilities {
  display: flex;
  align-items: center;
}

// Global search
#global-search {
  margin-right: 0.4rem;
  color: @colorSlateLight;

  @media (min-width: @breakpointScreenMediumMax) {
    margin-right: 0.8rem;
  }
}

#skip-link {
  clip: rect(0,0,0,0);
  clip-path: inset(50%);
  height: 1em;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1em;
}

#skip-link:focus, #skip-link:active {
  clip: auto;
  clip-path: none;
  overflow: auto;
  position: absolute;
  width: 12em;
  height: auto;
  margin-left: auto;
  text-decoration: none;
  left: 50%;
}