@media only screen and (max-width: @breakpointScreenSmallMax) {
  .localization-menu {
    border-top: 1px @colorSlateLight solid;
    border-bottom: 1px @colorSlateLight solid;
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}

.language-menu-control {

  .language-menu-identifier {
    display: flex;
  }

  uitk-icon[name="caret180"] {
    margin: auto 0.4rem;

    &:before {
      transition: all 0.2s;
    }
  }

  &.tooltip-open, &.open {
    &[data-control="toggle"] .language-menu-flag {
      display: none;
      visibility: hidden;
    }

    uitk-icon[name="caret180"]:before {
      transform: rotate(180deg);
    }
  }

  @media only screen and (max-width: @breakpointScreenSmallMax) {
    color: @colorSlateLight;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0;
  }
}

// all flags should have similar margins
.language-menu-flag {
  margin: auto 0.4rem auto 0;
}

// all language lists are flex display
//  small screens display in a column, med/large in a row
.language-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media only screen and (max-width: @breakpointScreenSmallMax) {
    font-size: 1.3rem;
    font-weight: 400;

    flex-direction: column;

    margin: 0;
  }

  .language-option {
    margin: 0.3rem 0.2rem;
    border: 1px @colorNeutralWhite solid;

    .language-label {
      display: flex;
      width: 3.8rem;
      padding-left: 0.5rem;
    }

    &.selected {
      border: 1px @colorBlueLight solid;

      // set the border radius to something reasonably larger than the font size
      //  to allow for more circular radii around the link
      border-radius: 2rem;
      background-color: fade(@colorBlueMed, 10%);
    }

    @media only screen and (max-width: @breakpointScreenSmallMax) {
      width: 100%;
      margin: 0.6rem 0;
      border: none;

      a {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .language-label {
          color: @colorSlateLight;
          padding: 0;
          width: auto;
        }
      }
    }
  }
}

.btn.login-button {
  @media only screen and (max-width: @breakpointScreenSmallMax) {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    color: @colorNeutralWhite;

    .btn-label {
      #font.size(@baseFontPx);
    }
  }

  .btn-label {
    padding: 0.6rem 1.2rem;
  }
}
