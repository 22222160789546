// ==========================================================================
// Row
// ==========================================================================
.row {
  .margin(l, @baseFontPx, @gridColsPadding * -1);
  position: relative;
  display: flex;
  flex-flow: row wrap;

  + .row,
  + .row-flush {
    .padding(t, @baseFontPx, @gridRowPaddingTop);
  }
}

// Used in combination with .col-flush
.row-flush {
  margin: 0;
  position: relative;
  display: flex;
  flex-flow: row wrap;

  + .row,
  + .row-flush {
    .padding(t, @baseFontPx, @gridRowPaddingTop);
  }
}

// ==========================================================================
// Column
// ==========================================================================
.col {
  .col(@baseFontPx);
}

.col-flush {
  .col-flush(@baseFontPx);
}

.nested-grid {
  .row {
    .margin(l, @baseFontPx, @gridColsPaddingNested * -1);

    + .row,
    + .row-flush {
      .padding(t, @baseFontPx, @gridRowPaddingNestedTop);
    }
  }

  .col {
    .padding(l, @baseFontPx, @gridColsPaddingNested);
  }

  .row-flush {
    margin: 0;

    + .row,
    + .row-flush {
      .padding(t, @baseFontPx, @gridRowPaddingNestedTop);
    }
  }
}

.col-1 { .cols1(); }
.col-2 { .cols2(); }
.col-3 { .cols3(); }
.col-4 { .cols4(); }
.col-5 { .cols5(); }
.col-6 { .cols6(); }
.col-7 { .cols7(); }
.col-8 { .cols8(); }
.col-9 { .cols9(); }
.col-10 { .cols10(); }
.col-11 { .cols11(); }
.col-12 { .cols12(); }

.col-push-1 { .in1(); }
.col-push-2 { .in2(); }
.col-push-3 { .in3(); }
.col-push-4 { .in4(); }
.col-push-5 { .in5(); }
.col-push-6 { .in6(); }
.col-push-7 { .in7(); }
.col-push-8 { .in8(); }
.col-push-9 { .in9(); }
.col-push-10 { .in10(); }
.col-push-11 { .in11(); }

// ==========================================================================
// Row Alignment
// ==========================================================================
.row-align-top {
  align-items: flex-start;
}
.row-align-middle {
  align-items: center;
}
.row-align-bottom {
  align-items: flex-end;
}

.row-align-start {
  justify-content: flex-start;
}
.row-align-center {
  justify-content: center;
}
.row-align-end {
  justify-content: flex-end;
}

// ==========================================================================
// Column Alignment
// ==========================================================================
.col-align-top {
  align-self: flex-start;
}
.col-align-middle {
  align-self: center;
}
.col-align-bottom {
  align-self: flex-end;
}

// ==========================================================================
// Responsive breakpoints
// ==========================================================================

// Medium or smaller screens have smaller row spacing and column gutters
@media only screen and (max-width: @breakpointScreenMediumMax) {
  .row {
    .margin(l, @baseFontPx, @gridColsPaddingNested * -1);

    + .row,
    + .row-flush {
      .padding(t, @baseFontPx, @gridRowPaddingNestedTop);
    }
  }

  .col {
    .padding(l, @baseFontPx, @gridColsPaddingNested);
  }

  .row-flush {
    margin: 0;

    + .row,
    + .row-flush {
      .padding(t, @baseFontPx, @gridRowPaddingNestedTop);
    }
  }
}

// Small screen (up to and including iPhone 6 Plus in portrait)
@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  // Used for column ordering
  .row-ordered-cols {
    display: flex;
    flex-direction: column; // Change flex direction to vertical so column width can be 100%;

    &.row-ordered-cols-horizontal {
      flex-direction: row; // Override flex direction to horizontal to offset the default vertical.
    }
  }

  // Columns are 12 wide on small screens by default
  .col, .col-flush {
    width: 100%;
  }

  // Clear push offset at small screens
  .col-push-1, .col-push-2, .col-push-3, .col-push-4, .col-push-5, .col-push-6,
  .col-push-7, .col-push-8, .col-push-9, .col-push-10, .col-push-11 { #grid.push(0); }

  .col-small-screen-1 { .cols1(); }
  .col-small-screen-2 { .cols2(); }
  .col-small-screen-3 { .cols3(); }
  .col-small-screen-4 { .cols4(); }
  .col-small-screen-5 { .cols5(); }
  .col-small-screen-6 { .cols6(); }
  .col-small-screen-7 { .cols7(); }
  .col-small-screen-8 { .cols8(); }
  .col-small-screen-9 { .cols9(); }
  .col-small-screen-10 { .cols10(); }
  .col-small-screen-11 { .cols11(); }
  .col-small-screen-12 { .cols12(); }

  .col-push-small-screen-1 { .in1(); }
  .col-push-small-screen-2 { .in2(); }
  .col-push-small-screen-3 { .in3(); }
  .col-push-small-screen-4 { .in4(); }
  .col-push-small-screen-5 { .in5(); }
  .col-push-small-screen-6 { .in6(); }
  .col-push-small-screen-7 { .in7(); }
  .col-push-small-screen-8 { .in8(); }
  .col-push-small-screen-9 { .in9(); }
  .col-push-small-screen-10 { .in10(); }
  .col-push-small-screen-11 { .in11(); }

  // This is for column ordering.
  // The default order is 0 and will align base on HTML structure.
  // The higher the negative value is will place the column before any others.
  .col-order-1, .col-order-small-screen-1 { order: -12; }
  .col-order-2, .col-order-small-screen-2 { order: -11; }
  .col-order-3, .col-order-small-screen-3 { order: -10; }
  .col-order-4, .col-order-small-screen-4 { order: -9; }
  .col-order-5, .col-order-small-screen-5 { order: -8; }
  .col-order-6, .col-order-small-screen-6 { order: -7; }
  .col-order-7, .col-order-small-screen-7 { order: -6; }
  .col-order-8, .col-order-small-screen-8 { order: -5; }
  .col-order-9, .col-order-small-screen-9 { order: -4; }
  .col-order-10, .col-order-small-screen-10 { order: -3; }
  .col-order-11, .col-order-small-screen-11 { order: -2; }
  .col-order-12, .col-order-small-screen-12 { order: -1; }
}

// Medium Screen (just bigger than iPhone 6 Plus and up to and including iPad in portrait)
@media only screen and (min-width: @breakpointScreenMediumMin) and (max-width: @breakpointScreenMediumMax) {

  .col-medium-screen-1 { .cols1(); }
  .col-medium-screen-2 { .cols2(); }
  .col-medium-screen-3 { .cols3(); }
  .col-medium-screen-4 { .cols4(); }
  .col-medium-screen-5 { .cols5(); }
  .col-medium-screen-6 { .cols6(); }
  .col-medium-screen-7 { .cols7(); }
  .col-medium-screen-8 { .cols8(); }
  .col-medium-screen-9 { .cols9(); }
  .col-medium-screen-10 { .cols10(); }
  .col-medium-screen-11 { .cols11(); }
  .col-medium-screen-12 { .cols12(); }

  .col-push-medium-screen-1 { .in1(); }
  .col-push-medium-screen-2 { .in2(); }
  .col-push-medium-screen-3 { .in3(); }
  .col-push-medium-screen-4 { .in4(); }
  .col-push-medium-screen-5 { .in5(); }
  .col-push-medium-screen-6 { .in6(); }
  .col-push-medium-screen-7 { .in7(); }
  .col-push-medium-screen-8 { .in8(); }
  .col-push-medium-screen-9 { .in9(); }
  .col-push-medium-screen-10 { .in10(); }
  .col-push-medium-screen-11 { .in11(); }

  .col-order-medium-screen-1 { order: -12; }
  .col-order-medium-screen-2 { order: -11; }
  .col-order-medium-screen-3 { order: -10; }
  .col-order-medium-screen-4 { order: -9; }
  .col-order-medium-screen-5 { order: -8; }
  .col-order-medium-screen-6 { order: -7; }
  .col-order-medium-screen-7 { order: -6; }
  .col-order-medium-screen-8 { order: -5; }
  .col-order-medium-screen-9 { order: -4; }
  .col-order-medium-screen-10 { order: -3; }
  .col-order-medium-screen-11 { order: -2; }
  .col-order-medium-screen-12 { order: -1; }
}