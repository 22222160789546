// behavioral styles
.tab-pane {
  display: none;
}

.tab-pane.on {
  display: block;
}

// Default tabs styles
// ======================================================
.inline-tabs {
  .tabs {
    list-style-type: none;

    li.tab {
      display: inline-block;
    }

    a {
      text-decoration: none;
      display: block;
    }

    &.on {
      text-decoration: none;
    }
  }
}

// Inline tabs
// ======================================================
.inline-tabs {
  &.tabs-stretch {
    .tabs li.tab {
      flex-grow: 1;
      max-width: none;
    }
  }

  .tabs {
    margin: 0;
    padding: 0;
    display: flex;
    li {
      text-align: center;
      padding: 0;
    }
  }

  .tabs-container {
    border-right: @tabContainerBorder*1px solid @tabContainerBorderColor;
    border-bottom: @tabContainerBorder*1px solid @tabContainerBorderColor;
    border-left: @tabContainerBorder*1px solid @tabContainerBorderColor;

    .tab-pane {
      padding: 1.2rem;
      background: white;
    }
  }
}

// Simple tabs theme (Default)
// ======================================================
.inline-tabs.simple-theme {
  .tabs {
    border-top: @tabSimTabsBorder*1px solid @tabSimTabsBorderColor;
    border-right: @tabSimTabsBorder*1px solid @tabSimTabsBorderColor;
    border-bottom: @tabSimTabsBorder*1px solid @tabSimTabsBorderColor;
    border-left: @tabSimTabsBorder*1px solid @tabSimTabsBorderColor;
    background: @tabSimBgColor;

    li.tab {
      position: relative;

      &:first-child .tab-indicator {
        position: absolute;
        bottom: @tabSimTabsBorder*-1px;
        height: @borderWidthMedium*1px;
        background-color: @tabSimTabIndicatorColor;
        content: '';
        transition: all 0.2s ease-in-out;
        #zIndex.base();
        pointer-events: none;
      }

      a {
        padding: 0.92rem 1.85rem;
        #font.size(@tabSimFontSize);
        background-color: @tabSimBgColor;
        color: @tabSimTextColor;

        > uitk-icon,
        > .icon {
          padding-right: 1.85rem;
          #font.size(@tabSimFontSize);
          color: @tabSimTextColor;
        }
      }
    }
  }

  &.tabs-large {
    .tabs li.tab {
      a {
        padding: 1.3rem 1.6rem; 
        font-size: 1rem;

        uitk-icon,
        .icon {
          padding-right: 0.6rem;
          font-size: 1rem;
        }
      }
    }
  }

  #simple-theme-active-styles
}

.no-touch .inline-tabs.simple-theme {
  #simple-theme-active-styles
}

#simple-theme-active-styles() {

  .tabs li.tab {
    a {
      &:hover, &:focus {
        color: @tabSimTextColorHoverFocus;
      }
      &:active {
        color: @tabSimTextColorActive;
      }
      &.on {
        color: @tabSimTextColorActive;
      }
    }
  }

  &.tabs-large {
    .tabs li.tab {
      a {
        &:hover, &:focus {
          color: @tabLgTextColorHoverFocus;
        }
        &:active {
          color: @tabLgTextColorActive;
        }
        &.on {
          color: @tabLgTextColorActive;
        }
      }
    }
  }
}

// FireFox - to remove gap with display:inline-block (https://css-tricks.com/fighting-the-space-between-inline-block-elements/)
.firefox .inline-tabs .tabs {
  font-size: 0;
}

// IE10+ remove gap with display:inline-block
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ remove gap with display:inline-block
  .inline-tabs .tabs {
    font-size: 0;
  }

  // IE10+ em convert only to the 10th digit which fall short of 1px.
  .inline-tabs.simple-theme {
    .tabs li.tab:first-child .tab-indicator {
      bottom: -1px;
    }
  }
}

// Medium or smaller screens have smaller row spacing and column gutters
@media only screen and (max-width: @breakpointScreenMediumMax) {
  .inline-tabs {
    .tabs {
      li.tab {
        display: table-cell;
        max-width: none;
      }
    }
  }
}

// When Tabs are in a Box
.box,
.box-alt {
  .box-flush {
    .inline-tabs.simple-theme .tabs {
      border-top: none;
      border-left: none;
      border-right: none;
    }

    .inline-tabs .tabs-container {
      border: none;
    }
  }
}