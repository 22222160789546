// Primary navigation
.primary-nav {
  position: fixed;
  top: 100%;
  left: 0;
  margin: 0;
  width: ~"calc(100% + 3.375rem)";
  height: 100%;
  background-color: @colorNeutralWhite;
  opacity: 0;
  transition:
          opacity @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
          top 0s @headerMenuToggleTransDuration,
          transform @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;

  @media (min-width: @breakpointHeaderMenuMobile) {
    overflow: visible;
    position: relative;
    top: auto;
    left: auto;
    margin-left: 3%;
    padding: 0;
    width: 60%;
    height: auto;
    min-height: 0;
    background-color: transparent;
    opacity: 1;
  }

  &.is-visible {
    top: 0;
    opacity: 1;
    transform: translateY(0);
    transition:
            background-color @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
            opacity @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
            top 0s,
            transform @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
  }

  &.show-panel { //only used for mobile
    transform: translateX(-100%) translateX(3.375rem);
    background-color: @colorNeutralWhite;
  }

  > ul {
    @media (min-width:@breakpointHeaderMenuMobile) {
      display: flex;
      flex-grow: 2;

      &.ul-right {
        flex-grow: 1;
      }
    }

    > .menu-item {
      display: block;
      @media (min-width: @breakpointHeaderMenuMobile) {
        position: relative;
        display: inline-flex;
        margin-right: 3%;
      }

      > a {
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
          color: @colorBlueMed;
        }
      }

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }

  a {
    padding: 1.38rem 0;
    font-size: 1rem;
    display: block;
    text-decoration: none;
    word-break: normal;
    hyphens: manual;
    color: @colorSlateLight;
    cursor: default;

    @media (min-width: @breakpointHeaderMenuMobile) {
      font-size: 0.875rem;
      padding: 1.201rem 0.8rem;
    }

    &[href] {
      cursor: pointer;
    }
  }

  .category-title {
    font-size: 0.875rem;
    margin: 0 0 0 0.45rem;
    padding: 0 0 0.67rem 0;
    position: relative;
    font-weight: @fontWeightMed;
    letter-spacing: 1.1px;
    color: @colorSlateDark;

    @media (min-width: @breakpointHeaderMenuMobile) {
      margin: 2.21rem 0 0.796rem 0;
      padding: 0;
    }

    @media (min-width: @breakpointScreenMediumMax) {
      white-space: nowrap;
    }

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0.3125rem;
      right: ~"calc(100% + 0.375rem)";
      width: 0.125rem;;
      height: 0.625rem;
      background-color: @colorGoldMed;
    }
  }
}

// Primary navigation -- show submenu on hover
.primary-nav .menu-item {
  uitk-badge {
    margin: 0 0 0 0.26rem;
    line-height: 1.2;
  }

  .submenu.header-menu-active {
    @media (min-width: @breakpointHeaderMenuMobile) {
      top: 100%;
      bottom: auto;
      opacity: 1;
    }
  }

  .multi-list.header-menu-active {
    @media (min-width: @breakpointHeaderMenuMobile) {
      display: flex;
      position: fixed;
      top: 3.125rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 90%;
    }

    @media (min-width: @breakpointScreenMediumMax) {
      position: absolute;
      top: 100%;
      left: 50%;
      right: auto;
      width: auto;
      transform: translateX(-47%);
    }

    @media (min-width: @breakpointScreenLargeMax) {
      transform: translateX(-5rem);
    }
  }

  &.is-focused > .list,
  .list.header-menu-active {
    @media (min-width: @breakpointScreenMediumMin) {
      display: flex;
      flex-direction: column;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }
}

// Primary navigation -- submenu
.primary-nav .submenu {
  margin: 0 0 3.07rem 0;
  box-shadow: @shadowMedium;
  filter: drop-shadow(0px 1px 3px rgba(0,0,0,0.2));

  @media (min-width: @breakpointHeaderMenuMobile) {
    padding: 2.31rem 3.08rem 2.31rem 0;
    position: absolute;
    bottom: 100%;
    opacity: 0;
    width: auto;
    background-color: @colorNeutralWhite;
    transition: opacity 0.1s ease-out;
  }

  li {
    padding: 0.4rem 3.07rem;
    font-size: 1rem;
    list-style-type: none;
    text-align: left;

    &:hover {
      background-color: @colorBlueUltraLight;
    }

    @media (min-width: @breakpointHeaderMenuMobile) {
      white-space: nowrap;
      display: block;
    }

    &:first-child {
      border: none;
    }

    &:last-child {
      a {
        padding-bottom: 0;
      }
    }

    uitk-icon, .icon {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

      &:before {
        font-size: 0.8rem;
        color: @colorSlateLight;
      }

      &.open:before {
        color: @colorNeutralWhite;
      }
    }
  }

  a {
    padding: 0.46rem 0;

    @media (min-width: @breakpointHeaderMenuMobile) {
      padding: 0;
      color: @colorSlateLight;
    }

    &.has-submenu {
      &:before {
        color: @colorSlateLight;
      }
    }
  }

  &.submenu-primary {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    box-shadow: none;
    filter: none;

    @media (min-width: @breakpointHeaderMenuMobile) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.submenu-secondary {
    box-shadow: none;
    filter: none;
  }

  &.list {
    @media (min-width: @breakpointHeaderMenuMobile) {
      padding: 2.3rem 0;
    }
  }

  &.multi-list {
    @media (max-width: @breakpointScreenMediumMax) and (max-height: @breakpointHeaderMenuMinHeight) {
      height: ~"calc(100% - 3.375rem)";
      overflow-x: visible;
      overflow-y: scroll;
    }

    .submenu-group {
      background-color: @colorNeutralWhite;

      h4, ul {
        @media (min-width: @breakpointHeaderMenuMobile) {
          margin-left: 3.37rem;
        }
      }
    }
  }

  .submenu-group {
    ul {
      padding-bottom: 0.292rem;

      + h4 {
        margin-top: 1.63rem;
        margin-bottom: 1.09rem;;
      }

      @media (min-width: @breakpointHeaderMenuMobile) {
        font-size: 0.73rem;

        li {
          font-size: 0.73rem;
          padding: 0 0 0.292rem;
        }

        a {
          padding-top: 0.113rem;
          padding-bottom: 0.113rem;
        }
      }
    }
  }
}

// Primary navigation --fix ie11 submenu flex container bug
.ie11 .primary-nav .multi-list.header-menu-active {
  display: table;

  .submenu-group {
    display: table-cell;
  }
}

// Nav wrapper and panel -- shared styles
.primary-nav {
  .nav-wrapper,
  .nav-panel {
    padding: 4.124rem 0.875rem 0.875rem;
    position: relative;
    overflow-x: visible;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }
}

// Primary navigation - mobile -- nav wrapper
.primary-nav {
  .nav-wrapper {
    width: ~"calc(100% - 3.375rem)";
    opacity: 0;
    transition: opacity @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
    transform 0s @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;

    > * {
      transform: translateY(0.75rem);
      transition: opacity @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
      transform 0s @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
      opacity: 0;
    }
  }

  &.is-visible .nav-wrapper {
    transition: opacity @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
    opacity: 1;

    > * {
      transform: translateY(0);
      transition: opacity @headerMenuToggleTransDuration @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
      transform @headerMenuToggleTransDuration @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
      opacity: 1;
    }
  }

  &.show-panel .nav-wrapper {
    transition: opacity @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
    opacity: 0;
  }
}

// Primary navigation - mobile -- nav panel
.primary-nav {
  .nav-panel {
    position: absolute;
    top: 0;
    left: 100%;
    width: ~"calc(100vw - 3.375rem)";
    background-color: @colorNeutralWhite;

    .panel-menu-wrapper {
      display: none;

      &.is-visible {
        display: block;
      }
    }

    .toggle:before {
      transition: transform 0.2s ease-out;
    }

    .toggle.open:before {
      transform: rotate(180deg);
    }

    .toggle-pane {
      li {
        margin-left: 0.92rem;

        &:first-child {
          padding-top: 0.92rem;
        }

        &:last-child {
          padding-bottom: 0.92rem;
        }
      }

      a {
        color: @colorSlateLight;
      }
    }
  }
}

// Primary navigation - mobile -- close bar
.primary-nav {
  .nav-panel-close {
    #zIndex.fixedElements();
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3.375rem;
    bottom: 0;
    left: ~"calc(100% - 3.375rem)";
    margin: auto;
    width: 3.375rem;
    color: @colorSlateLight;

    uitk-icon, .icon {
      transition: opacity @headerMenuToggleTransDuration @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
      opacity: 0;
    }
  }

  &.show-panel .nav-panel-close {
    uitk-icon, .icon {
      opacity: 1;
    }
  }
}

// Primary navigation - mobile -- toggle button
#primary-nav-toggle {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 3.375rem;
  height: 3.375rem;

  @media (min-width: @breakpointHeaderMenuMobile) {
    display: none;
  }

  span {
    @pseudoStartPosition: ~"calc(100% + 0.1875rem)";
    display: flex;
    align-self: center;
    position: relative;
    width: 1.125rem;
    height: 0.125rem;
    font-size: 0;
    background-color: @colorSlateLight;
    transition:
            background-color @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
            transform @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background-color: @colorSlateLight;
      transition: transform @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;
    }

    &:before {
      bottom: @pseudoStartPosition;
    }

    &:after {
      top: @pseudoStartPosition;
    }
  }

  &.is-visible {
    span {
      background-color: transparent;
      transform: rotate(180deg);
      &:before {
        transform: rotate(45deg);
        bottom: auto;
      }
      &:after {
        transform: rotate(-45deg);
        top: auto;
      }
    }
  }
}

.primary-nav .ul-right{
  @media (min-width: @breakpointHeaderMenuMobile) {
    display: inline;
    text-align: right;
  }
}