/* Roboto with various weights */
/*=========================================================================*/
/* Roboto */
/* 300 - Light DEPRECATED */
/* 400 - Regular */
/* 500 - Medium */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300; // DEPRECATED
    src: local('Roboto Light'),
        local('Roboto-Light'),
        url(../fonts/roboto-light.woff2) format('woff2'),
        url(../fonts/roboto-light.woff) format('woff'),
        url(../fonts/roboto-light.ttf) format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'),
        local('Roboto-Regular'),
        url(../fonts/roboto-regular.woff2) format('woff2'),
        url(../fonts/roboto-regular.woff) format('woff'),
        url(../fonts/roboto-regular.ttf) format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'),
        local('Roboto-Medium'),
        url(../fonts/roboto-medium.woff2) format('woff2'),
        url(../fonts/roboto-medium.woff) format('woff'),
        url(../fonts/roboto-medium.ttf) format('ttf');
    font-display: swap;
}