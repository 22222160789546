.search-bar-icon {
  font-size: 1.5rem;
  top: .55rem;
  padding-right: .75rem;
  color: @colorBlueMed;
}

#search-bar-responsive-wrap-outer {
  overflow: hidden;
}

#search-bar-content-primary {
  margin: 0;
  .padding(r, @fontSize2, @searchbarContentPadding);
  color: @colorBlueMed;
}

#search-bar-responsive-wrap-inner {
  .padding(t, @baseFontPx, @searchbarResponsiveWrapInnerPadding);
  float: left;
  font-size: @fontSize6;

  > h6 {
    color: @colorBlueMed;
  }
}

#search-bar-content-secondary,
#search-bar-content-tertiary {
  display: inline;
  white-space: nowrap;
}

#search-bar-content-secondary {
  .padding(r, @baseFontPx, @searchbarContentPadding);
}

#search-bar-change-search {
  cursor: pointer;
  .btn-label {
    .padding(t, @baseFontPx, @searchbarChangeSearchBtnPadding);
    .padding(b, @baseFontPx, @searchbarChangeSearchBtnPadding);
  }

  uitk-icon[name="angled-arrow-left"] {
    display: none;
  }
}

@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  #search-bar-change-search {
    uitk-icon[name="search"] {
      display: none;
    }

    uitk-icon[name="angled-arrow-left"] {
      display: block;
    }
  }
}