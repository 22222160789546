// Range Slider
// ==========================================================================
/*! jQuery UI - v1.11.4 - 2015-05-13
* http://jqueryui.com
* Includes: core.css, slider.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Trebuchet%20MS%2CTahoma%2CVerdana%2CArial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=4px&bgColorHeader=f6a828&bgTextureHeader=gloss_wave&bgImgOpacityHeader=35&borderColorHeader=e78f08&fcHeader=ffffff&iconColorHeader=ffffff&bgColorContent=eeeeee&bgTextureContent=highlight_soft&bgImgOpacityContent=100&borderColorContent=dddddd&fcContent=333333&iconColorContent=222222&bgColorDefault=f6f6f6&bgTextureDefault=glass&bgImgOpacityDefault=100&borderColorDefault=cccccc&fcDefault=1c94c4&iconColorDefault=ef8c08&bgColorHover=fdf5ce&bgTextureHover=glass&bgImgOpacityHover=100&borderColorHover=fbcb09&fcHover=c77405&iconColorHover=ef8c08&bgColorActive=ffffff&bgTextureActive=glass&bgImgOpacityActive=65&borderColorActive=fbd850&fcActive=eb8f00&iconColorActive=ef8c08&bgColorHighlight=ffe45c&bgTextureHighlight=highlight_soft&bgImgOpacityHighlight=75&borderColorHighlight=fed22f&fcHighlight=363636&iconColorHighlight=228ef1&bgColorError=b81900&bgTextureError=diagonals_thick&bgImgOpacityError=18&borderColorError=cd0a0a&fcError=ffffff&iconColorError=ffd27a&bgColorOverlay=666666&bgTextureOverlay=diagonals_thick&bgImgOpacityOverlay=20&opacityOverlay=50&bgColorShadow=000000&bgTextureShadow=flat&bgImgOpacityShadow=10&opacityShadow=20&thicknessShadow=5px&offsetTopShadow=-5px&offsetLeftShadow=-5px&cornerRadiusShadow=5px
* Copyright 2015 jQuery Foundation and other contributors; Licensed MIT */

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}

/* Misc visuals
----------------------------------*/
.ui-slider {
  position: relative;
  text-align: left;
  flex-grow: 1;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  #zIndex.base(2);
  .width(@baseFontPx, @sliderHandleSize);
  .height(@baseFontPx, @sliderHandleSize);
  cursor: grab;
  -ms-touch-action: none;
  touch-action: none;
  .border-radius(a, @baseFontPx, @sliderHandleSize / 2);
  box-shadow: @shadowSmall;
}

.ui-slider .ui-slider-range {
  position: absolute;
  #zIndex.base(1);
  display: block;
  border: 0;
  background-position: 0 0;
}

.ui-slider .slider-value-label-start, .ui-slider .slider-value-label-end {
  position: absolute;
  .pos(t, 9, @sliderValueLabelPositionTop);
  text-align: center;
  white-space: nowrap;
  color: @sliderValueLabelTextColor;
}

.ui-slider .slider-value-label-end {
  transform: translate(15%, 0);
  right: 0;
}

.ui-slider-horizontal {
  .height(@baseFontPx, @sliderBarHeight);
}

.ui-slider-horizontal .ui-slider-handle {
  .calcMiddleAlign(@sliderHandleSize, @sliderBarHeight);
  .pos(t, @baseFontPx, @middleAlign); // Centers a circle (or box) over a bar regardless if either size changes
  margin-left: -.6em;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.slider-value-label-start, .slider-value-label-end {
  &.slider-value-label-hover {
    color: @sliderValueLabelHoverActiveTextColor;
  }

  &.slider-value-label-focus {
    color: @sliderValueLabelHoverActiveTextColor;
  }

  &.slider-value-label-active {
    color: @sliderValueLabelHoverActiveTextColor;
  }
}

/* Component containers
----------------------------------*/
.ui-widget-content {
  background: @colorGrayscale3;
  color: @colorSlateDark;
  .border-radius(a);
  .margin(b, @baseFontPx, 30);
  .margin(t, @baseFontPx, 12);
}

.ui-widget-content a {
  color: @colorSlateDark;
}

.ui-widget-header {
  .border-radius(a, @baseFontPx, @sliderHandleSize / 2);
  background: @formFocusInputTextColor;
  color: white;
}

.ui-widget-header a {
  color: @inputBorderColor;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  .border(a, @baseFontPx, @sliderHandleBorderWidth, @sliderHandleBorderColor);
  background: white;
  outline: none;
  transition: transform 0.08s;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: @sliderHandleHoverActiveColor;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover {
  border-color: @sliderHandleHoverActiveColor;
  background: white;
}

.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border-color: @sliderHandleHoverActiveColor;
  background: white;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
  color: @colorGrayscale4;
  text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  transform: scale(1.125);
  box-shadow: @shadowLarge;
  border-color: @sliderHandleHoverActiveColor;
  background: white;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: @colorGrayscale4;
  text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border-color: @sliderHandleHoverActiveColor;
  background: @colorGrayscale4;
  color: @colorSlateDark;

  a {
    color: @colorSlateDark;
  }
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  .border(a, @baseFontPx, @borderWidthBase, #cd0a0a);
  background: @colorError;
  color: white;

  a {
    color: white;
  }
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: white;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  background-image: none;
}
