// Form Mixins
// ==========================================================================
.inputBorderStyles() {
  border: @borderWidthBase + 0px solid @formInputBorderColor;
  border-radius: @formInputRadius + 0px;
}

.inputStyles() {

  background-color: @formInputBg;
  #font.size(@formInputFontSize);
  .padding(tr, @formInputFontSize, @formInputPaddingTopBottom, @formInputPaddingLeftRight);
  outline: none;
  color: @formInputTextColor;
}

.form-label() {
  #font.size(@formLabelFontSize);
  #font.weight(medium);
  .padding(trbl, @formLabelFontSize, 6, @formInputPaddingLeftRight, 2, @formInputPaddingLeftRight);
  position: absolute;
  cursor: default;
  letter-spacing: 0.7px;
  color: @formLabelTextColor;
}

.input {
  display: inline-block;
  .inputHeight();
  .inputBorderStyles();
  .inputStyles();

  &.focus {
    outline: none;
    border-color: @formFocusInputBorderColor;

    & ~ uitk-icon,
    & ~ .icon {
      color: @formFocusInputTextColor;
    }
  }
}

// Remove iOS appearance styles
input:not([type="checkbox"]):not([type="radio"]) {
  appearance: none;
}

// Main wrapper for all inputs and custom input controls, like Stepper and Slider
// ==========================================================================
.input-group {
  font-size: 0.8125rem; //13/16 px
  margin-bottom: 2 * @baseScaleValue + 0px;  //this may need to convert to rems in the future
  position: relative;
  display: flex;
  flex-flow: row wrap;

  &,
  > input,
  > .input,
  > select,
  > textarea {
    width: 100%;
  }

  > input,
  > select,
  > textarea {
    .inputBorderStyles();
    .inputHeight();
    .inputStyles();
  }

  // Label
  label:not(.unlabel) {
    border-top-left-radius: @radiusDefault + 0px;
    .form-label();

    &.form-label-background {
      top: 1.1px; // .1 for android browsers blurring border
      left: 1.1px; // .1 for android browsers blurring border
      right: 1.25rem; // bit extra for scrollbar
      background: linear-gradient(to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 70%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    ~ input,
    ~ .input,
    ~ select {
      .padding(t, @formInputFontSize, 12);
      padding-bottom: 0;
    }

    ~ input[type="password"] {
      .padding(t, @formInputPasswordFontSize, 12);
      padding-bottom: 0;
    }

    ~ textarea {
      // top: label space, right: scrollbar space, left: match label
      padding: 1.31rem 1.25rem 0 0.69rem;
    }

    // Prevents empty label broken layouts
    &:empty:before {
      content: ".";
      visibility: hidden;
    }
  }

  &.textarea label {
    background-color: white;
  }

  // Prevents switch empty label broken layouts
  &.switch,
  &.switch-small {
    label {
      padding: 0;
    }
  }

  // Focus State
  &:focus {
    outline: none;
  }

  input:focus,
  .input.focus,
  select:focus,
  textarea:focus {
    outline: none;
    border-color: @formFocusInputBorderColor;
  }

  input:focus,
  .input.focus,
  textarea:focus {
    & ~ uitk-icon,
    & ~ .icon {
      color: @formFocusInputTextColor;
    }
  }

  // Invalid State
  // General UX practice says that checkboxes, radios, and selects shouldn't have invalid choices available for the user, so should we support that or not?
  &.invalid {
    input,
    .input,
    input[type="checkbox"] + label:before,
    select,
    textarea {
      border-color: @formInvalidInputBorderColor;
      &:focus,
      &.focus {
        & ~ uitk-icon,
        ~ .icon {
          color: @formInvalidInputTextColor;
        }
      }
    }

    label,
    uitk-icon:not([class*="alert-text"]),
    .icon:not([class*="alert-text"]) {
      color: @formInvalidInputTextColor;
    }

    .input-message {
      color: @formInvalidInputTextColor;
    }
  }

  .input.invalid {
    border-color: @formInvalidInputBorderColor;

    ~ uitk-icon:not([class*="alert-text"]),
    ~ .icon:not([class*="alert-text"]) {
      color: @formInvalidInputTextColor;
    }

    ~ .input-message {
      color: @formInvalidInputTextColor;
    }
  }

  // Disabled
  &.disabled {
    label,
    uitk-icon:not([class*="alert-text"]),
    .icon:not([class*="alert-text"]) {
      color: @formDisabledInputIconColor;
    }
  }

  &.disabled .input,
  &.disabled .input.disabled,
  input[disabled],
  select[disabled],
  textarea[disabled] {
      color: @formDisabledInputTextColor;
      background-color: @formDisabledBgColor;
      border-color: @formDisabledInputBorderColor;
      cursor: not-allowed;
  }

  // read-only input class
  &.read-only {
    > input,
    > .input,
    > select,
    > textarea {
      border: none;
      background-color: transparent;
      cursor: default;

      &:disabled {
        color: @formInputTextColor;
        opacity: 1;
      }
    }
  }

  // Select
  &.select {
    // Padding for down arrow icon
    select {
      .padding(r, @formInputFontSize, 30);
      -webkit-appearance: none;
      appearance: none;
    }

    &:after {
      .iconCore();
      content: "\e09a";
      #font.size(@baseFontPx);
      position: absolute;
      .pos(t, @baseFontPx, 16);
      .pos(r, @baseFontPx, 12);
      pointer-events: none;
      color: @formSelectCaretColor;
      #zIndex.forms(4);
    }
  }

  // Custom Password input design
  input[type="password"] {
    #font.size(@formInputPasswordFontSize);
    .padding(tr, @formInputPasswordFontSize, @formInputPaddingTopBottom, @formInputPaddingLeftRight);
    .height(@formInputPasswordFontSize, @formInputHeight);
    letter-spacing: @formInputPasswordLetterSpacing;

    &[placeholder] {
      #font.size(@formInputFontSize);
      .height(@formInputFontSize, @formInputHeight);
      letter-spacing: normal;
    }
  }

  // Common style - Checkbox and Radio
  input[type="checkbox"],
  input[type="radio"]:not(.unradio) {
    padding: 0;
    // Custom checkbox and radio
    .visuallyhidden;

    // To override input[disabled] styling
    &[disabled] {
      background-color: transparent;

      + label {
        opacity:0.36;
      }
    }

    + label {
      display: inline-flex;
      align-items: center;
      position: relative;
      min-height: 1.5rem;
      padding: 0 0 0 2.0625rem;
      margin: 0 0.5675rem 0.5675rem 0;
      font-size: 0.8125rem;
      font-weight: @fontWeightRegular;
      letter-spacing: normal;
      text-transform: none;
      color: @inputTextColor;
      background-color: transparent;
      .notextselect();

      &:before {
        box-sizing: border-box;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 1.25rem;
        width: 1.25rem;
        content: '';
        background-color: white;
        border: 2px solid @formInputBorderColor;
        speak: none;
      }

      &:after {
        margin: auto;
        font-family: 'EgenciaIcons';
      }

      // Disable state
      &.disabled {
        color: @formDisabledInputTextColor;
        cursor: not-allowed;

        &:before {
          background-color: @formDisabledBgColor;
          border-color: @formDisabledInputBorderColor;
          cursor: not-allowed;
        }
      }

      // Override standard empty label rules set from 123
      &:empty {
        &:before {
          content: "";
          visibility: visible;
          margin-right: 0;
        }
      }
    }

    // Checked
    &:checked {
      + label:before {
        background-color: @formFocusColor;
        border-color: @formFocusColor;
        box-shadow: none;
      }

      // Disabled Checked state
      &[disabled] {
        + label:before {
          background-color: @formFocusColor;
          border-color: @formFocusColor;
        }
      }
    }
  }

  input[type="checkbox"] {
    + label {
      &:before {
        border-radius: @formCheckboxInputRadius;
      }
    }
  }

  // Checkbox
  input[type="checkbox"] {
    &:checked {
      + label:after {
        content: "\e0aa"; // Success icon
        height: 0.8125rem;
        width: 0.8125rem;
        color: white;
        position: absolute;
        top: 0;
        bottom: 0.1875rem;
        left: 0.2rem;
      }
    }
    &:focus {
      + label {
        outline: 2px solid @colorBlueLight;
        outline: 5px auto -webkit-focus-ring-color;
      }
      .ege-no-outline & + label {
        outline: none;
      }
    }
  }

  // Radio
  input[type="radio"]:not(.unradio) {
    + label {
      &:before {
        border-radius: @radiusFull;
      }

      &:after {
        border-radius: @radiusFull;
      }
    }

    &:checked {
      + label:after {
        content: "";
        box-shadow: inset 0 0 0 2px #fff;
        background-color: @formFocusColor;
        height: 1rem;
        width: 1rem;
        position: absolute;
        top:0;
        bottom:0;
        left: 2px;
      }
    }

    &:focus {
      + label {
        outline: 2px solid @colorBlueLight;
        outline: 5px auto -webkit-focus-ring-color;
      }
      .ege-no-outline & + label {
        outline: none;
      }
    }
  }

  // Input Group - Merged
  &.merged {
    .input-group {
      position:absolute;
      input[type=checkbox] + label {
        padding:18px;
        position:absolute;
        margin:0;
        #zIndex.base(3);
      }
    }
    select {
      position: absolute !important;
      border-color: transparent !important;
      margin: 1px !important;
      height: 60px !important;
      #zIndex.base(3) !important;
      width: 72px !important;
      background-color: transparent;
      &:focus ~ input {
        border-color: @colorBlueMed !important;
        #zIndex.base(1);
      }
    }
    &.select:after {
      left: 48px!important;
      right: initial!important;
    }
    input:not([type=checkbox]):not([type=radio]), label {
      padding-left: 72px;
    }
    label {
      padding-top:6px;
    }
  }
}

// Input group merge
.input-group-merge input {
  border-left-color: white !important;
  &:focus {
    border-left-color: @colorBlueMed!important;
  }
}

// Input group with unnested label
.input-group.label-outside {
  label:not(.unlabel) {
    .padding(trbl, @baseFontPx, 0, 0, 6, 0);
    position: relative;

    ~ input,
    ~ select {
      .padding(t, @baseFontPx, @formInputPaddingTopBottom);
      .padding(b, @baseFontPx, @formInputPaddingTopBottom);
    }

    ~ textarea {
      .padding(t, @baseFontPx, @formInputPaddingTopBottom * 2);
    }
  }

  &.select:after,
  uitk-icon, .icon {
    .pos(t, @baseFontPx, 34);
  }
}

// Textarea
.input-group textarea {
  resize: vertical;

  &[rows] {
    height: auto;
  }
}

// Input Messages
// =========================================================================
// Validation Messages
.validation-msg {
  #font.size(@formMessageFontSize);
  position: relative;

  &:before {
    .padding(r, @formMessageFontSize, 8)
  }

  &.error {
    color: @formInvalidColor;
  }
}

.input-message:not(:empty) {
  #font.size(@formMessageFontSize);
  .margin(t, @formMessageFontSize, 3);
  color: @formMessageTextColor;
}

// Field Icons
// ==========================================================================
.input-icon-left, .input-icon-right {
  uitk-icon,
  .icon {
    #font.size(@baseFontPx);
    position: absolute;
    display: block;
    box-shadow: none !important; //here only to not repeat myself, not because styles too specific!
    text-align: center;
    cursor: pointer;
    .pos(t, @baseFontPx, @formIconPositionTop); // input high 48 / 2 - icon high 15 / 2 = 16.5 round down to 16 - border 1 = 15
    color: @formInputPlaceholderColor;
  }

  uitk-country-flag,
  .country-flag {
    position: absolute;
    .pos(t, @formInputFontSize, @formFlagPositionTop); // input high 48 / 2 - flag high 12 / 2 = 18
  }
}

// Deprecate icon-before-pos and icon-after-pos
.icon-before-pos,
.icon-after-pos {
  color: @formInputPlaceholderColor;
}

.input-icon-left {
  input, input[type="password"][placeholder], textarea, .input, select {
    .padding(l, @formInputFontSize, @formIconLeftPaddingLeft);
  }

  input[type="password"] {
    .padding(l, @formInputPasswordFontSize, @formIconLeftPaddingLeft);
  }

  uitk-icon,
  uitk-country-flag,
  .icon,
  .country-flag {
    .pos(l, @baseFontPx, @formIconPositionLeftRight);
  }
}

.input-icon-right {
  input, input[type="password"][placeholder], textarea, .input {
    .padding(r, @formInputFontSize, @formIconRightPaddingRight)
  }

  input[type="password"] {
    .padding(r, @formInputPasswordFontSize, @formIconRightPaddingRight);
  }
  uitk-icon,
  uitk-country-flag,
  .icon,
  .country-flag {
    .pos(r, @baseFontPx, @formIconPositionLeftRight);
  }
}

// Form Layouts
// ==========================================================================
// Common style - all forms
.form-inline,
.form-group,
.form-grid {

  .input-group {
    &.select {
      select {
        box-shadow: none;
      }
    }

    label {
      .input-message {
        #font.weight(regular);
        color: inherit;
        font-size: inherit
      }
    }

    .btn {
      margin: 0;
    }

    .input-group {
      width: 100%;
      float: left;
    }
  }
}

// Inline
.form-inline {
  display: flex;
  .cf();

  .input-group {
    width: auto;
    .margin(r, @formInputFontSize, @formControlMargin);

    &:last-child {
      margin-right: 0;
    }
  }

  // Fix for Calendar's date range inputs
  .date-range {
    .input-group:first-child {
      margin-right: 0;
    }
  }
}

// Common Style - .form-group and .form-grid
.form-group,
.form-grid {

  .input-group {
    display: inline-block;

    label:not([type="radio"]):not([type="checkbox"]),
    uitk-icon,
    .icon {
      #zIndex.forms(2);
    }

    input:not([type="radio"]):not([type="checkbox"]),
    .input,
    select,
    textarea,
    .btn {
      position: relative;

      &:focus,
      &.focus {
        #zIndex.forms(1);
      }
    }

    &.invalid {
      input:not([type="radio"]):not([type="checkbox"]),
      .input,
      select,
      textarea {
        #zIndex.forms(1);
      }
    }

    &.input-merge {
      input:not([type="radio"]):not([type="checkbox"]),
      .input,
      select,
      textarea {
        border-left: none;
      }
    }
  }
}

// Group
.form-group {
  display: flex;

  .box & {
    display: block;
  }

  .input-group {
    &:first-of-type {
      margin-left: 0;
    }

    margin-left: -1px;
    width: auto;
  }

  .date-range {
    &:first-child {
      .input-group:first-of-type {
        margin-left: 0;
      }
    }

    .input-group:first-of-type {
      margin-left: -1px;
    }
  }
}

// Grid
.form-grid {
  .nested-grid & .row + .row,
  .row + .row {
    .padding(t, @formInputFontSize, @formControlMargin);
  }

  .input-group, .date-range {
    margin-bottom: 0;
  }

  .date-range .input-group {
    flex-grow: 1;
  }

  .col-flush + .col-flush {
    margin-left: -1px;
  }
}

// Input -- border radius alterations
.form-flush-borders() {
  select, input, .input, .btn {
    @media (min-width: @breakpointScreenMediumMin) {
      .border-radius(a, @baseFontPx, 0);
    }
  }
  &:first-child {
    select, input, .input, .btn {
      @media (min-width: @breakpointScreenMediumMin) {
        .border-radius(tlrbrl, @baseFontPx, @radiusDefault, 0, 0, @radiusDefault);
      }
    }
  }
  &:last-child {
    select, input, .input, .btn {
      @media (min-width: @breakpointScreenMediumMin) {
        .border-radius(tlrbrl, @baseFontPx, 0, @radiusDefault, @radiusDefault, 0);
      }
    }
  }
  &:only-child {
    select, input, .input, .btn {
      @media (min-width: @breakpointScreenMediumMin) {
        .border-radius(a, @baseFontPx, @radiusDefault);
      }
    }
  }
}

.form-group .input-group {
  .form-flush-borders;
}

.form-grid .col-flush {
  .form-flush-borders;
}

// Form group date range
.form-group {
  .date-range {
    .input-group:first-of-type {
      input {
        border-radius: 0;
        @media (max-width: @breakpointScreenSmallMax) {
          .border-radius(tlbl, @baseFontPx, @formInputRadius, @formInputRadius);
        }
      }

      + .input-group {
        input {
          border-radius: 0;
          @media (max-width: @breakpointScreenSmallMax) {
            .border-radius(trbr, @baseFontPx, @formInputRadius, @formInputRadius);
          }
        }
      }
    }

    &:first-of-type {
      .input-group:first-of-type {
        input {
          .border-radius(tlbl, @baseFontPx, @formInputRadius, @formInputRadius);
        }
      }
    }
    &:last-of-type {
      .input-group:first-of-type + .input-group {
        input {
          .border-radius(trbr, @baseFontPx, @formInputRadius, @formInputRadius);
        }
      }
    }
    &:only-of-type {
      .input-group:first-of-type {
        input {
          .border-radius(tlbl, @baseFontPx, @formInputRadius, @formInputRadius);
        }

        + .input-group {
          input {
            .border-radius(trbr, @baseFontPx, @formInputRadius, @formInputRadius);
          }
        }
      }
    }
  }
}

// Placeholder Text Styles
// ==========================================================================
.input-group {
  input,
  .input,
  select,
  textarea {
    &::placeholder {
      color: @formInputPlaceholderColor;
    }
    &:-ms-input-placeholder {
      color: @formInputPlaceholderColor;
    }
  }
}

// overwrite for the HTML5 Boilerplate way to hide labels
label.visuallyhidden-with-placeholder {
  height: auto !important;
  overflow: visible !important;
  position: absolute !important;
  left: -999em;
}

// hides input elements and replaces them with label
// stop user from jumping to top of page when clicking on input.masked
// using !important so that this can be used on any components
input.masked {
  position: absolute; // Someone needs to stop removing this!!!
  height: 1px !important; // min 1px required to that input can be tabbed to
  width: 1px !important;
  margin-left: 3px; // nudges the 1px radio button away from the edge. Pixels measurement is ok here.
  #zIndex.layer(-1); // drops input 'behind' the overlaying span. Mainly to fix safari and firefox
  opacity: 0;
}

@media only screen and (max-width: @breakpointScreenMediumMax) {

  .form-group .input-group,
  .form-grid .input-group {
    .input-group > input,
    .input-group > select,
    .input-group > textarea {
      font-size: 16px;
    }
  }

  .input-group > input,
  .input-group > select,
  .input-group > textarea,
  .input-group > .btn {
    height: 48px;
  }

  .input-group {
    > textarea {
      // Textarea indent slightly off in iOS safari
      // See issue: http://stackoverflow.com/questions/6890149/remove-3-pixels-in-ios-webkit-textarea
      .appleios & {
        text-indent: -3px;
      }
    }

    label:not(.unlabel) + textarea {
      .padding(t, @formInputFontSize, 17);
    }
  }
}

@media only screen and (max-width: @breakpointScreenSmallMax) {
  .form-inline,
  .form-group,
  .form-grid {

    .input-group {
      width: 100%;

      &:not([class*=col-sm]) .btn {
        width: 100%;
      }
    }
  }

  .form-group {
    display: block;

    .input-group {
      margin-left: 0;
    }

    .date-range {
      .margin(b, @formInputFontSize, @formControlPadding);

      .input-group {
        flex-grow: 1;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .form-inline {
    display: block;

    .input-group {
      margin-right: 0;
    }

    .date-range {
      .margin(b, @formInputFontSize, @formControlPadding);

      .input-group {
        flex-grow: 1;
        margin-right: 0;
      }
    }
  }

  .form-grid {
    .input-group, .date-range {
      .margin(b, @formInputFontSize, @formControlMargin);
    }
  }

  .input-group {
    input[type="checkbox"] + label,
    input[type="radio"]:not(.unradio) + label {
      width: 100%;
    }

  }
}

// ==========================================================================
// Resets For Random Browser Styles
// ==========================================================================
// Remove appearance of native Calendar UI
input[type="date"] {
  appearance: textfield;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}

// Remove validation styles
::-webkit-validation-bubble-message {
  display: none;
}

:-moz-validation-bubble-message {
  display: none;
}

// Webkit only - styles apply only to webkit since it supports -webkit-appearance property (properly) on <select> elems
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .input-group,
  .select {
    &uitk-icon:before, &.icon:before,
    .icon-toggle180:before {
      display: block;
    }
  }
}

.ie11 {
  //IE puts a 'x' button in text inputs, so we get rid it.
  .input-group input::-ms-clear {
    display: none;
  }

  // Restyle select default blue background color
  .input-group select::-ms-value,
  .select select::-ms-value {
    background: none;
    color: @formInputTextColor;
  }

  // Remove the default dropdown arrow
  select::-ms-expand {
    display: none;
  }
}
