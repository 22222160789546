//Future developer: the HTML5 "search" input type will be worth looking into.
//It is supposed to support a clear button and events that will potentially replace some Typeahead events

// Autocomplete Tooltip
.typeahead {
  .padding(a, @autocompleteResultsFontSize, 6);
  * {
    box-sizing: border-box;
  }

  .results {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .category-results {
    .padding(b, @autocompleteCategoryFontSize, 12);

    &:last-child {
      .padding(b, @autocompleteCategoryFontSize, 0);
    }
  }

  .category-name {
    #font.size(@autocompleteCategoryFontSize);
    #font.weight(medium);
    color: @autocompleteCategoryTextColor;
    margin: 0;
    .margin(t, @autocompleteCategoryFontSize, 12);
    .padding(l, @autocompleteCategoryFontSize, 12);
    .padding(r, @autocompleteCategoryFontSize, 12);
    position: relative;
    word-wrap: break-word;
  }

  dl {
    margin: 0;
    dd {
      padding: 0;
    }
  }

  .details {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: @autocompleteResultsMatchingTextColor;

    uitk-icon, .icon {
      .padding(r, @autocompleteResultsFontSize, 10);
    }

    &.child-suggestion {
      .padding(l, @autocompleteResultsFontSize, 10);
    }
  }

  .optional-details {
    #font.size(@autocompleteOptionalResultsFontSize);
    .margin(t, @autocompleteOptionalResultsFontSize, 3);
  }

  a, a:hover, a.details {
    display: block;
    .padding(trbl, @autocompleteResultsFontSize, 6, 12, 6, 12);
    color: @autocompleteResultsTextColor;
    text-decoration: none;
    width: 100%;
    #font.weight(regular);
    #font.size(@autocompleteResultsFontSize);
    .border-radius(a, @autocompleteResultsFontSize, @radiusDefault);

    &.highlight {
      background: @autocompleteHighlightBgColor;
    }

    &.close {
      color: @siteDefaultFontColor;
      float: right;
      width: auto;
      #font.size(@autocompleteResultsFontSize);
      padding-right: 0;

      &:hover, &.highlight {
        background: none;
        color: @colorSlateDark;
      }

      uitk-icon, .icon {
        .padding(l, @autocompleteResultsFontSize, 6);
      }
    }
  }

  .autocomplete-no-results {
    .margin(a, @baseFontPx, @spaceSmall);
    color: @colorSlateMed;
  }

  .footer {
    background: #f5f5f5;
    .border(t, @autocompleteResultsFontSize, @borderWidthBase, @colorSlateDark);
    .margin(t, @autocompleteResultsFontSize, 12);
    .padding(tr, @autocompleteResultsFontSize, 0, 6);
    overflow: hidden;
  }

  .powered-by-google {
    background-image: url(../images/powered_by_google_on_white.png);
    background-position: center right;
    background-repeat: no-repeat;
    height: 18px;
  }
}


// Autocomplete Input
.autocomplete {
  position: relative;

  label {
    #zIndex.forms(1);
  }

  .autocomplete-tags-wrap {
    .min-height(@formInputFontSize, @inputHeight);
    .padding(tb, @formInputFontSize, 11, 5);
    height: auto;
    display: flex;
    flex-wrap: wrap;

    .autocomplete-input {
      .height(@secondaryFontPx, @autocompleteTagsInputHeight);
      .min-width(@secondaryFontPx, @autocompleteTagsInputWidth);
      border: none;
      padding: 0;
      box-shadow: none;
      cursor: text;
      flex-grow: 1;

      &:focus {
        border-color: transparent;
      }
    }

    .tag {
      .margin(b, @formInputFontSize, 6);
    }

  }
}

