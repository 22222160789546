// Images
img {
  position: relative;
}
figure {
  position: relative;
  .margin(b, @baseFontPx, @spaceLarge);

  &.hero {
    .min-height(@baseFontPx, 240);
  }

  img {
    width: 100%;
  }

  figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    .padding(trb, 13, @spaceLarge, 9, @spaceExtraSmall);
    background-color: @mediaFigCaptionBg;
    background-position: 50% 100%;
    background-repeat: repeat-x;
    background-size: contain;
    #font.size(13);
    text-align: left;
    color: @mediaFigCaption;
  }
}

// Caption Background styles
.cssgradients .media-caption, .cssgradients .image-caption {.linear-gradient(tb, rgba(0,0,0,0), fadeout(@mediaCaptionFade,34%));}
.no-cssgradients.backgroundsize .image-caption,
.no-cssgradients.backgroundsize .media-caption {background-image: url('../images/caption-gradient.png');}

//
// Image Aspect Ratios HTML:
//
// <figure class="media aspect-ratio-1-1">
//   <img src="/path/to/image.jpg"  alt="image alt text" />
// </figure>
//
.image, .media {
  overflow: hidden;
  position: relative;
  width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  img, iframe {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom:0;
  }
  iframe{
    width:100%;
    height:100%;
  }
  &.aspect-ratio1-1 {
    padding-bottom: 100%;
  }
  &.aspect-ratio3-2 {
    padding-bottom: ((2 / 3) * 100) + 0%;
  }
  &.aspect-ratio4-3 {
    padding-bottom: ((3 / 4) * 100) + 0%;
  }
  &.aspect-ratio16-9 {
    padding-bottom: ((9 / 16) * 100) + 0%;
  }
  &.aspect-ratio5-1 {
    padding-bottom: ((1 / 5) * 100) + 0%;
  }

  // Loading/Loaded States
  // ==============================================================

  // Default View
  img,
  .image-caption,
  iframe {
    opacity: 1;
    transition: opacity 1s;
    .stopTransitionFlicker();
  }

  // Loading State
  &.loading {
    iframe, img, .image-caption { opacity: 0; }
    // Keep elements within <noscript> visible for browsers with no JS
    noscript {
      iframe, img{ opacity: 1;}
    }
  }
  // end
}

// moving css transitions out into a modernizr class
.csstransitions{
  img,
  .image-caption,
  iframe{
    transition: opacity 1s;
    .stopTransitionFlicker();
  }

  // fading the caption in to match the media fading in so it feels less jerky
  .media-caption{
    transition: opacity .5s;
    .stopTransitionFlicker();
  }
}

// Background Images
// ==============================================================
.image.background {
  background-size: cover;
}

// Show "no-image" background image if an image is not available
.image.no-image{
  background-color: @mediaImageBg;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 25%;
  img {
    display: none;
  }
}
.svg .no-image {background-image: url('../images/no-image.svg');}
// Overrides for background image
.svg .background.no-image { background-size: 25%; background-position: 50% 50%;}

.no-svg .no-image {background-image: url('../images/no-image.png'); background-size: auto;}

.image.dont-display {
  padding-bottom: 0;
  margin-bottom: 0;
  img {
    display: none;
  }
}

// Background Colours for different types
.image {background-color: @mediaImageBg;}
.media {background-color: @mediaBg;}

// Media Loader Wrapper
.media-loader {
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  .margin(t,@baseFontPx,-12);
  .margin(l,@baseFontPx,-24);
  .width(@baseFontPx, @spaceExtraLarge);
  .height(@baseFontPx,@spaceLarge);
}
.media-loader.animated {
  .width(@baseFontPx,36);
  .height(@baseFontPx,36);
  .margin(trbl,@baseFontPx,-18,0,0,-18);
}

.loading .media-loader {visibility: visible;}
.dont-display .media-loader {visibility: hidden;}