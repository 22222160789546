@import "./node_modules/@egencia/design-tokens-7/dist/main/tokens-map/web-tokens.json"; //evaluates by cwd
//==========================================================================
// Import all required stylesheets
@import "fonts";
@import "../../node_modules/@egencia/uitk-global-styles-7/mixins/mixin.less";

@banner-background-color: get-token(banner__background_color);

@import "brand-values";
@autocompleteHighlightBgColor: get-token('color__accent__1__200');
@boxBorderRadius: get-token('card__corner_radius');
@boxBorderColor: get-token('card__bordered__border_color');
@tabContainerBorderColor: get-token('card__bordered__border_color'); //uitk-react tabs do not have borders
@tabSimTabsBorderColor: get-token('card__bordered__border_color');
@calendarDisabledTextColor: fade(get-token('date_picker__calendar__day_number__text_color'), 36);
@calendarNormalTextColor: get-token('date_picker__calendar__day_number__text_color');
:root {
    color: @calendarDisabledTextColor;
}

@import "interactions";
@import "normalize";
@import "type";
@import "icons";
@import "animation";
@import "media";
@import "buttons";
@import "themes";
@import "header";
@import "header-nav-primary";
@import "header-nav-account";
@import "not-logged-in-header";
@import "footer";
@import "agent";
@import "loaders";
@import "forms";
@import "stepper";
@import "star-rating";
@import "range-slider";
@import "tooltip";
@import "tabs";
.tabs {
    border-top-left-radius: get-token('card__corner_radius');
    border-top-right-radius: get-token('card__corner_radius');
    overflow: hidden;
}
.tabs-container {
    border-radius: 0 0 get-token('card__corner_radius') get-token('card__corner_radius');

    .tab-pane {
        border-radius: 0 0 get-token('card__corner_radius') get-token('card__corner_radius');
    }
}

@import "layouts";
body {
    background-size: 100%;
    background-color: get-token(page__background_color);
    @body-image-url: get-token('page__background_image');
    background-image: url("@{body-image-url}"); //Can't seem to get this to work without intermediate variable.
    background-repeat: no-repeat;
}

@import "grid";
@import "alerts";
@import "boxes";
@import "components";
@import "tag";
@import "badge";
@import "menu";
@import "titles";
@import "calendar";
@import "autocomplete";
@import "modal";
@import "toggle";
@import "pill";
@import "map";
@import "tables";
@import "switch";
.toggle-switch input[type="checkbox"] + .toggle-switch-slider {
    background-color: get-token('color__neutral__1__500');

    &::before {
        border: 2px solid get-token('color__neutral__1__500');
    }
}

@import "interstitial";
@import "country-flags";
@import "action-bar";
@import "checkout-bar";
@import "search-bar";
@import "content-slider";
@import "filters";
@import "character-count";
@import "scroll-to-top";
@import "utils";
@import "panel";
@import "contextual-help-panel";
@import "react-container";
