.action-bar {
  background-color: @actionBarBgColor;
  #font.weight(medium);
  .padding(a, @baseFontPx, @actionBarPadding);
  .cf();

  * {
    color: @actionBarTextColor;
  }

  .action-bar-icon {
    .border(a, @actionBarIconTextSize, @borderWidthMedium, @actionBarTextColor);
    border-radius: 100%;
    .margin(tr, @actionBarIconTextSize, 0, @actionBarIconMarginLeftRight);
    .padding(a, @actionBarIconTextSize, @actionBarIconPadding);
    background-color: lighten(@actionBarBgColor, 10%);
    line-height: 0;
  }
}