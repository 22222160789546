// Interstitial
.interstitial-results {
  position: relative;

  .interstitial {
    .stopTransitionFlicker();
    transition: opacity .4s ease-in-out;
    opacity: 0;
    background-color: @interstitialBgColor;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    // Workaround for when browsers don't fire 'transitionend', this allows users to still interact with content under Interstitial
    pointer-events: none;
    #zIndex.interstitial();

    &.fade-in {
      opacity: @opacityExtraDark;
      // Workaround, see prev comment
      pointer-events: all;
    }

    .interstitial-message {
      .padding(t, @baseFontPx, @interstitialMsgPaddingTop);
      text-align: center;

      .interstitial-message-content {
        .margin(b, @baseFontPx, @interstitialMsgMarginBtm);
        color: @interstitialMsgTextColor;

        p + p {
          margin-top: 0;
        }
      }

      .interstitial-ad-space {
        // Ad space size fixed and should not scale.
        height: @interstitialAdSpaceHeight;
        width: @interstitialAdSpaceWidth;
        margin: 0 auto;
      }
    }
  }
}


// Set up opacity values for results
  .onpage-interstitial-results {

    &.fade {
      // yep, .36 because design
      opacity: .36;
    }
  }

// Fade results
.onpage-interstitial-results {
  .stopTransitionFlicker();
  transition: opacity .5s .2s;
}

.theme-onpage-interstitial {

  // allows users to click "through" the overlaid <div> in case filters are located in the <aside>
  //pointer-events: none; //Don't want them clicking through, and filters etc. shouldn't be covered up

  &.modal-background {
    position: absolute;
  }
  .modal-body {
    //background-color: @colorBlueMed;
    color: @colorSlateLight;
    .border-radius(a, @baseFontPx, 3);
    .padding(a, @baseFontPx, 12);
    display: inline-block;
    .min-width(@baseFontPx, 72);
  }

  .modal-loader {
    position: static;
    top: 0;
    //background-color: @colorBlueMed;
    color: @colorSlateLight;
    .border-radius(a, @baseFontPx, 3);
    .padding(a, @baseFontPx, 12);
    .min-width(@baseFontPx, 72);

    .loader {
      position: static;
      width: 100%;
      #font.size(24);
    }
  }

  .modal-inner {
    min-height: 0;
    text-align: center;
    background: none;
    transition: none;  // remove default transition

    // hide .modal-body if the .modal-loader is displayed
    &.loading .modal-body {
      display: none;
    }
  }

  &.modal-wrap,
  &.active.modal-wrap {
    transition: opacity 0.05s;
    .pos(t, @baseFontPx, 90);
  }

  &.loading {
    .modal-inner {
      .max-width(@baseFontPx, 72);
    }

    .modal-body {
      opacity: 1;
      width: 100%;
      .min-height(@baseFontPx, 72);
    }
    .modal-loader {
      text-indent: -999em;
      left: 0;
      padding-right: 0;
      width: 100%;
      .pos(t, @baseFontPx, 32);
    }
  }

  // hiding close button
  // todo: these rules can be removed once https://jira/jira/browse/CSE-115 is complete and the on page interstitial has close button removed.
  .btn-close {
    display: none;
  }

  .no-positionfixed & {
    position: absolute;
  }
}