// Account navigation
.account-nav {
  &.show-offcanvas {
    transform: translateX(-116%);
  }

  .user-menu-username {
    margin-bottom: 0.75rem;
  }

  .user-name,
  .user-menu-profile span,
  .company-name {
    padding-right: 0.75rem;
  }

  .btn-small .btn-label {
    padding: 0;
  }

  a {
    font-size: 0.875rem;
    font-weight: @fontWeightRegular;
  }
}

#user-name {
  font-size: 0.875rem;
  color: @colorSlateDark;
}

#company-name {
  font-size: 0.875rem;
  color: @colorSlateLight;
  display: flex;
  align-items: center;

  uitk-icon {
    font-size: 0.9375rem;
    line-height: unset;
    display: inline-flex;
    margin-right: 0.625rem;
  }
}

// Account navigation -- user menu profiles
#user-menu-profiles {
  font-size: 0.875rem;
  margin: 0.625rem 0 0;
}

.user-menu-profiles-label {
  font-size: 0.875rem;
}

.user-control-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    font-size: 0.875rem;
    margin-top: 1rem;
  }
}

.user-menu-profile {
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;

  a {
    color: @colorSlateLight;
    display: flex;
    align-items: center;
    text-decoration: none;

    uitk-icon {
      font-size: 0.9375rem;
      line-height: unset;
      display: inline-flex;
      margin-right: 0.625rem;
    }
  }

  span {
    font-size: 0.75rem;
  }
}

// Account navigation -- toggle button
.account-nav-toggle {
  font-size: 1.375rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 3.375rem;
  height: 3.375rem;
  transition: color @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;

  @media(min-width: @breakpointScreenMediumMax) {
    justify-content: initial;
    width: auto;
    height: auto;
    text-align: right;
  }

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  uitk-icon, .icon {
    font-size: 1.375rem;
    color: @colorSlateLight;
  }

  .account-nav-toggle-info {
    display: none;
    color: @colorSlateLight;

    @media(min-width: @breakpointHeaderMenuTablet) {
      display: block;

      & +.account-nav-toggle-icon {
        margin-left: 0.625rem;
      }
    }
  }

  .account-nav-toggle-user {
    font-size: 0.875rem;
  }

  .account-nav-toggle-company {
    font-size: 0.75rem;
  }

  .account-nav-toggle-icon{
    display: inline-flex;
  }
}

// Account navigation - mobile -- close account menu
#account-nav-mobile-close {
  position: fixed;
  top: 0;
  right: 0;
}


// Account navigation -- mobile styles
@media (max-width: @breakpointHeaderMenuMobile) {
  .account-nav {
    padding: 3.375rem 0.875rem 0.875rem;
    position: fixed;
    top: 0;
    right: -100%;
    overflow-x: visible;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    width: ~"calc(100% - 3.375rem)";
    height: 100%;
    min-height: 100%;
    color: @colorSlateLight;
    background-color: @colorNeutralWhite;
    box-shadow: @shadowMedium;
    filter: drop-shadow(0px 1px 3px rgba(0,0,0,0.2));
    transform-origin: 100% 50%;
    transform: translateX(0);
    transition:
            opacity @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc,
            top 0s @headerMenuToggleTransDuration,
            transform @headerMenuToggleTransDuration @headerMenuToggleTransTimeFunc;

    a {
      text-decoration: none;
      font-weight: @fontWeightRegular;
    }

    .btn {
      color: @colorSlateLight;
    }
  }

  .user-name {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .company-name {
    font-size: 0.875rem;
    color: @colorSlateLight;
    display: flex;
    align-items: center;

    uitk-icon {
      font-size: 0.9375rem;
      line-height: unset;
      display: inline-flex;
      margin-right: 0.625rem;
    }
  }
}

#locale-chooser {
  font-size: 0.875rem;
}