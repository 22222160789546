// Minimum Star Rating
// ==========================================================================
.star-ratings-filter {
  .star-input-wrapper {
    position: relative;
    display: inline-block;
  }

  .star-label,
  .reset-rating {
    cursor: pointer;
  }

  .star-label {
    #font.sizeIncludeLineheight(20, @baseFontPx);
    float: right;
    color: @colorGrayscale3;

    &:before {
      content: "\e04d";
      font-family: "EgenciaIcons";
    }

    ~ .star-label {
      .padding(r, @baseFontPx, 5);
    }

    &:hover,
    &:hover ~ .star-label {
      color: @colorBlueMed;
    }
  }

  .reset-rating {
    display: none;
    position: absolute;
    width: 20%;
    height: 100%;
    opacity: 0;
  }

  // Checked states
  .star-rating:checked {
    & ~ .star-label {
      color: @siteDefaultFontColor;
    }

    &:not(.reset-rating) ~ .reset-rating {
      display: block;
    }

    // Position reset input over current rating
    .pos-star-reset(@n, @i: 1) when (@i =< @n) {
      &:nth-of-type(@{i}) ~ .reset-rating {
        right: ((@i * 100% / @n) - 20%);
      }
      .pos-star-reset(@n, (@i + 1));
    }

    .pos-star-reset(5);
  }
}