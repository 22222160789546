#checkout-bar {
  width: 100%;
  background-color: @checkoutBarBgColor;
  position: fixed;
  bottom: 0;
  left: 0;
  #zIndex.fixedElements();
  box-shadow: @checkoutBarShadow;

  .site-content {
    .padding(tr, @baseFontPx, @checkoutBarSiteContentPadding, @checkoutBarSiteContentPadding);
  }

  .checkout-bar-title {
    color: @checkoutBarSubHeaderTextColor;
    .margin(trb, @baseFontPx, @checkoutBarTitleMarginTopBottom, 0, @checkoutBarTitleMarginTopBottom);
  }

  #checkout-bar-total  {
    #font.weight(regular);
    margin: 0;
  }

  .btn {
    margin: 0;
    float: right;

    .btn-label {
      vertical-align: middle;
    }

    uitk-icon, .icon {
      color: @checkoutBarIconTextColor;
      vertical-align: middle;
      .pos(r, @h3, @checkoutBarIconPosRt);
      left: auto;
    }
  }
}

@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  #checkout-bar {
    position: relative;
    text-align: right;

    .btn {
      .margin(t, @secondaryFontPx, @checkoutBarButtonMarginTopSmallScreen);
      float: none;
      width: 100%;
    }
  }
}