.character-count{
	#font.size(@fontSize6);
	color:@colorSlateLight;
	position:absolute;
	.padding(trbl, @baseFontPx, @textareaCharCountContainerPadding,@textareaCharCountContainerPadding,@textareaCharCountContainerPadding,@textareaCharCountContainerPadding);
	.pos(r, @baseFontPx, @textareaCharCountContainerPosRight);
	.pos(b, @baseFontPx, @textareaCharCountContainerPosBottom);
	.background-rgba(@defaultLight,@textareaCharCountContainerOpacity);
}
.max-count-reached{
	color:@colorOrangeMed;
}
