// =============================================================================
// HTML5 display definitions
// =============================================================================

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, datalist, meter {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

meter {
  appearance: none;
}

// =============================================================================
// Base
// =============================================================================

// 
// 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
// 2. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
//

html {
  height: 100%; // Helps to fix footer to bottom
  #font.primary-font-family();
  font-size: (@baseFontPx / 16) * 100 + 0%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.45;
}

html, button, input, select, textarea {
  color: @colorSlateDark;
}

// 
// Remove text-shadow in selection highlight: h5bp.com/i
// These selection declarations have to be separate
// Also: hot pink! (or customize the background color to match your design)
//

//Not a fan, prefer OS default. Truly yours, Jordan
//::-moz-selection {
//  background: lighten(@colorSlateDark, 5%);
//  text-shadow: none;
//}
//
//::selection {
//  background: lighten(@colorSlateDark, 5%);
//  text-shadow: none;
//}

// =============================================================================
// Links
// =============================================================================
a {
  color: @colorBlueMed;
}

a:visited {
  color: @colorBlueMed;
}

a:hover {
  color: @colorBlueMed;
}

a:focus {
  outline: thin dotted;
}

// Improve readability when focused and hovered in all browsers: h5bp.com/h
a:hover, a:active {
  outline: 0;
}

// =============================================================================
// Typography
// =============================================================================
abbr[title] {
  border-bottom: 1px dotted;
}

//b, strong {
//  font-weight: bold;
//}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

// Lines HTML:
hr {
  display: block;
  clear: both;
  height: 1px;
  border: 0;
  .margin(tb, @baseFontPx, 24, 24);
  padding: 0;
  background-color: @colorGrayscale3;

  &.secondary {
    border-style: dashed;
  }

  &.faded-line {
    background: black;
    .linear-gradient(hr);
  }

  &.separator {
    .background-rgba(@colorSlateMed, 40%);
  }

  &.hr-small, &.hr-medium {
    position: relative;

    &.hr-center {
      left: 50%;
    }

    &.hr-right {
      left: 100%;
    }
  }

  &.hr-small {
    #font.size(@hrSmallFontSize);
    .height(@hrSmallFontSize, @hrHeight);
    .width(@hrSmallFontSize, @hrSmallWidth);
    .margin(tb, @hrSmallFontSize, @hrSmallMarginSmall, @hrSmallMarginSmall);
    margin-left: 0;

    &.hr-center, &.hr-right {
      .translateXem(@hrSmallWidth * -1, @hrSmallFontSize);
    }
  }

  &.hr-medium {
    #font.size(@hrMediumFontSize);
    .height(@hrMediumFontSize, @hrHeight);
    .width(@hrMediumFontSize, @hrMediumWidth);
    .margin(tb, @hrMediumFontSize, @hrMediumMarginSize, @hrMediumMarginSize);
    margin-left: 0;

    &.hr-center, &.hr-right {
      .translateXem(@hrMediumWidth * -1, @hrMediumFontSize);
    }
  }
}


ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  #font.weight(medium);
}

// Redeclare monospace font family: h5bp.com/j
pre, code, kbd, samp {
  font-family: monospace, serif;
  font-size: 1em;
}

// Improve readability of pre-formatted text in all browsers
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

// Position subscript and superscript content without affecting line-height: h5bp.com/k
sub, sup {
  font-size: 65%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  .pos(t, (@baseFontPx*.65), -3);
}

sub {
  .pos(b, (@baseFontPx*.65), -3);
}

// =============================================================================
// Lists
// =============================================================================

ul, ol {
  margin: @baselineEm 0;
  //padding: 0 0 0 @baselineEm;
  padding: 0;
}

ul {
  list-style-type: disc;
}

dd {
  margin: 0;
}

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

// =============================================================================
// Embedded content
// =============================================================================

//
// 1. Improve image quality when scaled in IE7: h5bp.com/d
// 2. Remove the gap between images and borders on image containers: h5bp.com/i/440
//

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

//
// Correct overflow not hidden in IE9
//
svg:not(:root) {
  overflow: hidden;
}

// =============================================================================
// Figures
// =============================================================================

figure {
  margin: 0;

  img {
    max-width: 100%
  }
  figcaption {
    margin-top: 1.8em;
  }
}

// =============================================================================
// Forms
// =============================================================================

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Indicate that 'label' will shift focus to the associated form element
label {
  cursor: pointer;
}

//
// 1. Correct color not inheriting in IE6/7/8/9
// 2. Correct alignment displayed oddly in IE6/7
//

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

//
// 1. Correct font-size not inheriting in all browsers
// 2. Remove margins in FF3/4 S5 Chrome
// 3. Define consistent vertical alignment display in all browsers
//

input, select, textarea {
  #font.primary-font-family();
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button {
  #font.weight(medium);
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

//
// 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
//

button, input {
  line-height: normal;
}

//
// 1. Display hand cursor for clickable form elements
// 2. Allow styling of clickable form elements in iOS
// 3. Correct inner spacing displayed oddly in IE7 (doesn't effect IE6)
//

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  appearance: button;
}

//
// Re-set default cursor for disabled elements
//

button[disabled], input[disabled] {
  cursor: default;
}

//
// Consistent box sizing and appearance
//

input[type="checkbox"],
input[type="radio"],
textarea {
  box-sizing: border-box;
  padding: 0;
  appearance: none;
}

input[type="search"] {
  appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}

//
// Remove inner padding and border in FF3/4: h5bp.com/l
//

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

//
// 1. Remove default vertical scrollbar in IE6/7/8/9
// 2. Allow only vertical resizing
//

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

// Colors for form validity
input:valid, textarea:valid {
}

input:invalid, textarea:invalid {
  background-color: #fff;
}

// =============================================================================
// Tables
// =============================================================================

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
}

td {
  vertical-align: top;
}

// =============================================================================
// Chrome Frame Prompt
// =============================================================================

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0;
}

// FireFox fixes
// Remove outline/dotted border from Firefox select drop down
// http://stackoverflow.com/questions/3773430/remove-outline-from-select-box-in-ff

.firefox {
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}
