.section-header, // Deprecated
.text {
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  h1 {
    .text-embedded-icon {
      .padding(tr, @h1, 0, @textPadding12);
    }
  }
  h2 {
    .text-embedded-icon {
      .padding(tr, @h2, 0, @textPadding12);
    }
  }
  h3 {
    .text-embedded-icon {
     .padding(tr, @h3, 0, @textPadding12);
    }
  }
  h4 {
    .text-embedded-icon {
      .padding(tr, @h4, 0, @textPadding6);
    }
  }
  h5 {
    .text-embedded-icon {
      .padding(tr, @h5, 0, @textPadding6);
    }
  }
  h6 {
    .text-embedded-icon {
      .padding(tr, @h6, 0, @textPadding6);
    }
  }


  .text-shade1 {
    color: @textShade1Color;
  }

  .text-shade2 {
    color: @textShade2Color;
  }

  .text-embedded, small {
    color: @textEmbeddedColor;
    font-size: 70%;
  }


  .section-header-main, // Deprecated
  .text-main {
    margin: 0;
    small {
      color: @colorSlateLight;
      font-size: 80%;
    }
  }
  .section-header-sub, // Deprecated
  .text-sub {
    font-weight: normal;
    margin-top: 0;
  }
  .section-header-icon, // Deprecated
  .text-icon {
    .margin(r, @baseFontPx, 6);
    float: left;
    uitk-icon, .icon {
      vertical-align: middle;
    }
  }
  & + .see-all {
    padding: 0;
  }
}