// Arrow Size
@tooltipArrowSize: 7;
@tooltipArrowSizePx: 7px;
// Theme map marker
@tooltipMapMarkerDotSize: 6;

// tooltip wrapper
.uitk-tooltip {
  display: block;
  box-sizing: content-box;
  #zIndex.tooltip();
  position: absolute;
  opacity: 0;
  color: @defaultLight;
  max-width: 90%;
  padding: 0;
  filter: drop-shadow(0px 1px 3px rgba(0,0,0,0.2));

  &.on {
    opacity: 1;
    visibility: visible;
  }

  &.fade {
    transition: opacity 45ms cubic-bezier(0.155, 0.615, 0.990, 1.000);

    &.theme-click.on {
      transition: opacity 0;
    }
  }
}


// tooltip inner
.tooltip-inner {
  overflow: hidden;
  position: relative;
  box-shadow: @shadowMedium;
  border-radius: 0.1333rem;
  padding: 0.8rem;
  background-color: @defaultLight;
  color: @colorGrayscale5;
}
.tooltip-inner .hd {
  .margin(trb, @baseFontPx, 0, 0, 12);
  .padding(a, @baseFontPx, 0);
}

.tooltip-inner p {
  color: @colorGrayscale5;
}

.tooltip-inner p, .tooltip-inner ul {
  .margin(t, 13, 12);
}

.tooltip-inner p:first-child {
  margin-top: 0;
}

// To support a close button
.tooltip-inner button.btn-close {
  position: absolute;
  .pos(t, @baseFontPx, 6);
  .pos(r, @baseFontPx, 6);
}

.show-arrow .tooltip-arrow,
.show-arrow .tooltip-arrow-border {
  display: block;
}

// Tooltip Arrow
.tooltip-arrow {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
}

// Vertical align arrow
.top .tooltip-arrow {
  .tooltip-arrow-down();
}

.bottom .tooltip-arrow {
  .tooltip-arrow-up();
}


// Horizontal align arrow
.mid.right, .mid-top.right, .mid-bottom.right {
  .tooltip-arrow {
    .tooltip-arrow-left();
  }
}

.mid.left, .mid-top.left, .mid-bottom.left {
  .tooltip-arrow {
    .tooltip-arrow-right();
  }
}

// Hover theme
.theme-hover {
  .tooltip-inner {
    font-size: 0.6875rem;
    font-weight: @fontWeightMed;
  }
}

// Calendar, Autocomplete
.theme-calendar,
.theme-annual-calendar,
.theme-typeahead,
.theme-typeahead-by-category {
  &.on {
    opacity: 1;
  }
  &.bottom .tooltip-arrow::before {
    border-bottom-color: @defaultLight;
  }
  &.top .tooltip-arrow::before {
    border-top-color: @defaultLight;
  }

  .tooltip-inner {
    background: @defaultLight;
    padding: 0;
  }
}

// Global header profile
.theme-profile {
  .tooltip-inner {
    background: @defaultLight;
    color: @colorSlateLight;
  }
}

.theme-country-code {
  .tooltip-inner {
    .max-height(@baseFontPx, 300);
    .padding(a, @baseFontPx, 12);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

// Form errors
.theme-invalid {
  &.on {
    opacity: 1.0;
  }

  .tooltip-inner {
    background-color: @colorError;
    white-space: nowrap;
    color: white;

    p {
      color: @defaultLight;
    }
  }

  &.bottom .tooltip-arrow {
    .tooltip-arrow-up(@baseFontPx, @colorError, @colorError);
  }

  &.top .tooltip-arrow {
    .tooltip-arrow-down(@baseFontPx, @colorError, @colorError);
  }

  &.mid.right, &.mid-top.right, &.mid-bottom.right {
    .tooltip-arrow {
      .tooltip-arrow-left(@baseFontPx, @colorError, @colorError);
    }
  }

  &.mid.left, &.mid-top.left, &.mid-bottom.left {
    .tooltip-arrow {
      .tooltip-arrow-right(@baseFontPx, @colorError, @colorError);
    }
  }
}

// Map marker
.marker-wrapper {
  overflow: visible !important;
  // Bounce animation
  &.marker-highlight {
    .animate-bounce;
  }
}

.theme-map-marker.marker {
  max-width: none;
  position: static;
  cursor: pointer;
  .notextselect();

  &.on{
    opacity: 1;
  }

  .info-window {
    display: none;
  }

  .tooltip-arrow {
    left: calc(50% - @tooltipArrowSizePx);
  }

  &.marker-tooltip {
    float: left;
    position: relative;
    left: -50%;

    .tooltip-inner {
      box-shadow: @shadowLarge;
      color: @defaultLight;
      #font.size(@smallFontPx);
      #font.weight(medium);
      .padding(l, @baseFontPx, 15);
      .padding(r, @baseFontPx, 15);
      .padding(tb, @baseFontPx, 6, 6);
      .linear-gradient(tb, @colorSlateLight, @colorSlateMed);
      white-space: nowrap;
    }

    &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorSlateMed, @colorSlateMed);
    }

    &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorSlateMed, @colorSlateMed);
    }

    &.mid.right, &.mid-top.right, &.mid-bottom.right {
      .tooltip-arrow {
        .tooltip-arrow-left(@baseFontPx, @colorSlateMed, @colorSlateMed);
      }
    }

    &.mid.left, &.mid-top.left, &.mid-bottom.left {
      .tooltip-arrow {
        .tooltip-arrow-right(@baseFontPx, @colorSlateMed, @colorSlateMed);
      }
    }

    &.marker-info {
      .tooltip-inner {
        .linear-gradient(tb, @colorBlueMed, @colorBlueMed);
      }

      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx,  @colorBlueMed,  @colorBlueMed);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx,  @colorBlueMed,  @colorBlueMed);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx,  @colorBlueMed,  @colorBlueMed);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx,  @colorBlueMed,  @colorBlueMed);
        }
      }
    }

    &.marker-success {
      .tooltip-inner {
        .linear-gradient(tb, @colorGreenMed, @colorGreenMed);
      }

      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorGreenMed, @colorGreenMed);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorGreenMed, @colorGreenMed);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx, @colorGreenMed, @colorGreenMed);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx, @colorGreenMed, @colorGreenMed);
        }
      }
    }

    &.marker-warn {
      .tooltip-inner {
        .linear-gradient(tb, @colorOrangeMed, @colorOrangeMed);
      }

      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorOrangeMed, @colorOrangeMed);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorOrangeMed, @colorOrangeMed);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx, @colorOrangeMed, @colorOrangeMed);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx, @colorOrangeMed, @colorOrangeMed);
        }
      }
    }

    &.marker-error {
      .tooltip-inner {
        .linear-gradient(tb, @colorRedMed, @colorRedMed);
      }

      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorRedMed, @colorRedMed);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorRedMed, @colorRedMed);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx, @colorRedMed, @colorRedMed);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx, @colorRedMed, @colorRedMed);
        }
      }
    }

  }

  &.marker-cdd {
    .icon-stack {
      font-size: 25px;
      display: block;

      uitk-icon[name="circle-filled"] {
        color: white;
        position: absolute;
        left: -5px;
        bottom: -21px;
        font-size: 15px;
      }
      uitk-icon[name="destination"] {
        color: @colorWarn;
        position: absolute;
        left: -10px;
        bottom: -32px;
      }
    }

    &.marker-info {
      .icon-stack uitk-icon[name="destination"] {
        color: @colorInfo;
      }
    }

    &.marker-success {
      .icon-stack uitk-icon[name="destination"],
      .icon-stack uitk-icon[name="destination"]:before {
        color: @colorSuccess;
      }
    }

    &.marker-warn {
      .icon-stack uitk-icon[name="destination"],
      .icon-stack uitk-icon[name="destination"]:before{
        color: @colorWarn;
      }
    }

    &.marker-error {
      .icon-stack uitk-icon[name="destination"],
      .icon-stack uitk-icon[name="destination"]:before {
        color: @colorError;
      }
    }

  }

  &.marker-pin {
    background: url('../images/map-pin.svg') no-repeat 0 0;
    .height(@smallFontPx, 40);
    .width(@smallFontPx, 22);
  }

  &.marker-dot {
    background: @colorSlateDark;
    color: @defaultLight;
    font-size: @secondaryFontPx;
    .min-width(@baseFontPx, @tooltipMapMarkerDotSize);
    .min-height(@baseFontPx, @tooltipMapMarkerDotSize);
    .border(a, @baseFontPx, @borderWidthMedium, white);
    border-radius: 50%;
    padding: 2px;
    overflow: hidden;
    box-shadow: @shadowSmall;

    .tooltip-inner {
      padding: 0;
    }

    uitk-icon, .icon {
      top: 3px;
      left: 1px;
    }

    &.marker-info {
      background-color: @colorInfo;
    }

    &.marker-success {
      background-color: @colorSuccess;
    }

    &.marker-warn {
      background-color: @colorWarn;
    }

    &.marker-error {
      background-color: @colorError;
    }
  }

  &.marker-price {
    &.marker-dot,
    .tooltip-inner {
      background: @colorBlueMed;
    }

    &.bottom {
      .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorBlueMed, @colorBlueMed);
      }
    }

    &.top {
      .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorBlueMed, @colorBlueMed);
      }
    }

    &.mid.right, &.mid-top.right, &.mid-bottom.right {
      .tooltip-arrow {
        .tooltip-arrow-left(@baseFontPx, @colorBlueMed, @colorBlueMed);
      }
    }

    &.mid.left, &.mid-top.left, &.mid-bottom.left {
      .tooltip-arrow {
        .tooltip-arrow-right(@baseFontPx, @colorBlueMed, @colorBlueMed);
      }
    }

    &.marker-dot.oop,
    &.oop .tooltip-inner {
      background: @colorBlueMed;
    }

    &.oop {
      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorBlueMed, @colorBlueMed);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorBlueMed, @colorBlueMed);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx, @colorBlueMed, @colorBlueMed);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx, @colorBlueMed, @colorBlueMed);
        }
      }
    }

    &.marker-dot.preferred .tooltip-inner {
      background: @colorSuccess;
    }

    &.preferred {
      .tooltip-inner {
        background: @defaultLight;
        color: @colorSlateDark;
        uitk-icon {
          color: @colorSlateDark;
        }
      }

      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @defaultLight, @defaultLight);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @defaultLight, @defaultLight);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx, @defaultLight, @defaultLight);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx, @defaultLight, @defaultLight);
        }
      }
    }

    &.branded {
      .tooltip-inner {
        background: @colorGoldMed;
        color: @colorSlateDark;
        uitk-icon {
          color: @colorSlateDark;
        }
      }

      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorGoldMed, @colorGoldMed);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorGoldMed, @colorGoldMed);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx, @colorGoldMed, @colorGoldMed);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx, @colorGoldMed, @colorGoldMed);
        }
      }
    }

    &.negotiated {
      .tooltip-inner {
        background: @defaultLight;
        color: @colorSlateDark;
        uitk-icon {
          color: @colorSlateDark;
        }
      }

      &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @defaultLight, @defaultLight);
      }

      &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @defaultLight, @defaultLight);
      }

      &.mid.right, &.mid-top.right, &.mid-bottom.right {
        .tooltip-arrow {
          .tooltip-arrow-left(@baseFontPx, @defaultLight, @defaultLight);
        }
      }

      &.mid.left, &.mid-top.left, &.mid-bottom.left {
        .tooltip-arrow {
          .tooltip-arrow-right(@baseFontPx, @defaultLight, @defaultLight);
        }
      }
    }
  }

  &.marker-sold-out {
    &.marker-dot,
    .tooltip-inner {
      background: @colorGrayscale4;
    }

    &.bottom .tooltip-arrow {
        .tooltip-arrow-up(@baseFontPx, @colorGrayscale4, @colorGrayscale4);
    }

    &.top .tooltip-arrow {
        .tooltip-arrow-down(@baseFontPx, @colorGrayscale4, @colorGrayscale4);
    }

    &.mid.right, &.mid-top.right, &.mid-bottom.right {
      .tooltip-arrow {
        .tooltip-arrow-left(@baseFontPx, @colorGrayscale4, @colorGrayscale4);
      }
    }

    &.mid.left, &.mid-top.left, &.mid-bottom.left {
      .tooltip-arrow {
        .tooltip-arrow-right(@baseFontPx, @colorGrayscale4, @colorGrayscale4);
      }
    }

    &.marker-dot {
      .width(@baseFontPx, 7);
      .height(@baseFontPx, 7);
      .border(a, @baseFontPx, @borderWidthMedium, white);
    }
  }
}

.theme-educational {
  color: @defaultLight;

  .tool-tip-container {
    display: flex;
    flex-direction: row;
  }

  .tool-tip-close-icon {
    color: inherit;
  }

  .tool-tip-content {
    min-width: 16rem;
    padding-left: 0.8rem;
  }

  .tool-tip-progress {
    opacity: 0.7;
    padding-top: 0.25rem;
    text-align: right;
  }

  .padding-right-small {
    padding-right: 0.20rem;
  }

  .padding-top-small {
    padding-top: 0.6rem;
  }

  .padding-top-medium {
    padding-top: 1.5rem;
  }

  .padding-right-medium {
    padding-right: 1rem;
  }

  .padding-left-small {
    padding-left: 0.5rem;
  }

  a:link {
    text-decoration: underline;
  }

  a,
  .hd {
    color: inherit;
  }

  .font-small {
    font-size: 0.75rem;
  }

  .btn {
    min-width: 5.5rem;
  }

  .btn-tour-next, .btn-tour-end {
    color: @defaultLight;
  }

  .tooltip-inner {
    background-color: @colorViolet;
    color: @defaultLight;

    .hd {
      font-weight: 500;
      margin: 0;
    }

    p {
      color: @defaultLight;
    }
  }

  .no-border {
    border: 0;
  }

  .small-screen-content {
    display: none;
  }

  .large-screen-content {
    display: block;
  }
  .no-top-margin{
    margin-top: 0;
  }

  .header{
    position: relative;
    padding-left: 1.6em;
  }

  .left{
    width: 74.99%;
  }

  .right{
    width: 24.99%;
  }

  @media only screen and (max-width: 540px) and (min-width: 0px) {
    .header{
      padding-left: .8em;
    }

    .small-screen-content {
      display: block;
    }

    .large-screen-content {
      display: none;
    }
  }
}

.theme-educational.top .tooltip-arrow {
  border-color: @colorViolet transparent transparent transparent;
  &:before {
    border-color: @colorViolet transparent transparent transparent;
  }
}

.theme-educational.bottom .tooltip-arrow {
  border-color: transparent transparent @colorViolet transparent;
  &:before {
    border-color: transparent transparent @colorViolet transparent;
  }
}

.theme-educational.mid.right .tooltip-arrow {
  border-color: transparent @colorViolet transparent transparent;
  &:before {
    border-color: transparent @colorViolet transparent transparent;
  }
}

.theme-educational.mid.left .tooltip-arrow {
  border-color: transparent transparent transparent @colorViolet;
  &:before {
    border-color: transparent transparent transparent @colorViolet;
  }
}

.theme-educational.mid-bottom.left .tooltip-arrow, .theme-educational.mid-top.left .tooltip-arrow {
  border-color: transparent transparent transparent @colorViolet;
  &:before {
    border-color: transparent transparent transparent @colorViolet;
  }
}

.theme-educational.mid-top.right .tooltip-arrow, .theme-educational.mid-bottom.right .tooltip-arrow {
  border-color: transparent @colorViolet transparent transparent;
  &:before {
    border-color: transparent @colorViolet transparent transparent;
  }
}

// Tooltip Arrow
// ==========================================================================
.tooltip-arrow-up(
  @target: @baseFontPx;
  @arrowColor: @defaultLight;
  @arrowShadow: transparent;
) {
  .leftUnitReturn(@tooltipArrowSize, @target);
  .topUnitReturn(@tooltipArrowSize, @target);
  border-style:solid;
  border-width: 0 @left @top @left;
  border-color: transparent transparent @arrowShadow transparent;

  &:before {
    content: '';
    display: block;
    position: relative;
    .pos(r, @target, @tooltipArrowSize);
    .pos(b, @target, -2); // Use pos top will change @top value.
    border-style:solid;
    border-width: 0 @left @top @left;
    border-color: transparent transparent @arrowColor transparent;
  }
}

.tooltip-arrow-down(
  @target: @baseFontPx;
  @arrowColor: @defaultLight;
  @arrowShadow: transparent;
) {
  .leftUnitReturn(@tooltipArrowSize, @target);
  .topUnitReturn(@tooltipArrowSize, @target);
  border-style:solid;
  border-width: @top @left 0 @left;
  border-color: @arrowShadow transparent transparent transparent;

  &:before {
    content: '';
    display: block;
    position: relative;
    .pos(r, @target, @tooltipArrowSize);
    .pos(b, @target, 9);
    border-style:solid;
    border-width: @top @left 0 @left;
    border-color: @arrowColor transparent transparent transparent;
  }
}

.tooltip-arrow-right(
  @target: @baseFontPx;
  @arrowColor: @defaultLight;
  @arrowShadow: transparent;
) {
  .leftUnitReturn(@tooltipArrowSize, @target);
  .topUnitReturn(@tooltipArrowSize, @target);
  border-style:solid;
  border-width: @left 0 @left @top;
  border-color: transparent transparent transparent @arrowShadow;

  &:before {
    content: '';
    display: block;
    position: relative;
    .pos(r, @target, 9);
    .pos(b, @target, @tooltipArrowSize);
    border-style:solid;
    border-width: @left 0 @left @top;
    border-color: transparent transparent transparent @arrowColor;
  }
}

.tooltip-arrow-left(
  @target: @baseFontPx;
  @arrowColor: @defaultLight;
  @arrowShadow: transparent;
) {
  .leftUnitReturn(@tooltipArrowSize, @target);
  .topUnitReturn(@tooltipArrowSize, @target);
  border-style:solid;
  border-width: @left @top @left 0;
  border-color: transparent @arrowShadow transparent transparent;

  &:before {
    content: '';
    display: block;
    position: relative;
    .pos(r, @target, -2);
    .pos(b, @target, @tooltipArrowSize);
    border-style:solid;
    border-width: @left @top @left 0;;
    border-color: transparent @arrowColor transparent transparent;
  }
}