// NOTE: panels share styles with ./modal.less
// Panel -- Layout overrides
.modal-inner.panel {
    border-radius: 0;
    .margin(a, @baseFontPx, 0);
    .max-width(@baseFontPx, 612);
    .padding(a, @baseFontPx, 0);
    transform: translate(100%, 0);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 41rem;

    @media (min-width: @breakpointScreenMediumMin) {
        margin: 0 auto;
    }

    &.panel-small {
        .max-width(@baseFontPx, 384);
    }

    .modal-header {
        width: 100%;
        text-align: center;
        background-color: white;
        padding: 0;
        border-bottom: none;

        .modal-title {
            box-shadow: @shadowSmall;
            position: relative;
            color: @colorSlateDark;
            font-size: initial;
            font-weight: 400;
            padding: 1.3rem;
        }

        .modal-subtitle {
            #font.size(14);
            box-shadow: @shadowSmall;
            .margin(a, @baseFontPx, 0);
            .padding(trbl, @baseFontPx, 12, 12, 12, 12);
            color: @colorSlateDark;
            background-color: @colorGoldMed;
        }
    }

    .modal-body {
        flex: 2;
        .padding(trbl, @baseFontPx, 24, 24, 24, 24);
        max-height: 100%;
        -webkit-overflow-scrolling: touch;
    }

    .modal-footer {
        padding: 0rem 0.75rem;
        width: 100%;
        background-color: white;
        .border(t, @baseFontPx, 1, @colorGrayscale3);

        .modal-footer-btns {
            .margin(b, @baseFontPx, 0);
            border: none;
        }

        .btn:last-child {
            margin-right: 0;
        }
    }

    .modal-close {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        height: auto;
        top: 1.25rem;
        right: 1rem;
        left: 90%;
        color: @colorSlateDark;
    }
}

.modal-wrap.active {
    .panel {
        transform: translateX(0);
    }
}
