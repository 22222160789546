@accordionBorderColor: @colorGrayscale2;

.toggle {
  cursor: pointer;
  margin-bottom:0;
  .notextselect();
  font-weight: @fontWeightMed;

  uitk-icon[name="toggle180"] {
    margin-left: 0.5rem;
  }

  // Inverts Toggle arrow on open/close
  &.open .icon-caret180,
  &.open uitk-icon[name="toggle180"] {
    transform: rotate(-180deg);
  }
}

[data-control="toggle"]{ //you always need this for iOS Safari click event to bubble
  cursor: pointer;
}

.toggle-pane {
  height: 0;
  display:block;
  overflow:hidden;
  padding-bottom: 0;

  &.slide {
    transition: height @toggleDuration;
  }

  &.fade{
    transition: opacity @toggleDuration;
  }

  &.open{
    height: auto !important; // Important is necessary when pane is open
  }
}

// ACCORDION
.accordion,
.accordion-nested {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    position: relative;
    padding: 0;
    background-color: @accordionBgColor;

    .toggle {
      color: @accordionTextColor;
      padding: 1.2rem 2.5rem 1.2rem 1.2rem;

      &:before {
        .iconCore();
        #font.size(@accordionIconFontSize);
        position: absolute;
        .pos(r, @accordionFontSize, @accordionPadding);
        content: "\e09a"; // caret180
        text-align: center;
        transition: all @toggleDuration;
      }

      &:hover {
        color: @accordionTextColorHover;
      }

      &:hover:before {
        color: @accordionNestedTextColorHover;
      }

      &.open:before {
        transform: rotate(-180deg);
      }
    }
  }
}

.accordion {
  > li {
    .margin(b, @accordionFontSize, @accordionMargin);
    .border(a, @accordionFontSize, @accordionBorderWidth, @accordionBorderColor, @accordionBorderType);
    .border-radius(a, @accordionFontSize, @accordionRadius);
  }
}

.accordion-nested {
  > li {
    .border(t, @accordionFontSize, @accordionBorderWidth, @accordionBorderColor, @accordionBorderType);

    &:first-child {
      border-top: none;
    }
  }
}

.accordion-padded {
  .padding(r, @accordionFontSize, @accordionPadding);
  .padding(b, @accordionFontSize, @accordionPadding);
  .padding(l, @accordionFontSize, @accordionPadding);
}