#footer {
  min-height: 14.375rem;
  padding: 3rem 0 1.5rem;
  background-color: @colorNeutralWhite;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;

  ul {
    margin: 1.5rem 0 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding-top: 1.5rem
  }

  a {
    font-size: get-token(font__size__300);
    padding: 0 1.125rem;
    color: @colorSlateLight;

    &:hover {
      text-decoration: underline;
    }
  }
}

#site-logo-footer {
  display: block;
  height: 3.3125rem;
  background-image: url('/media/amexgbt-egencia-logo-footer.png'), url('../images/amexgbt-egencia-logo-footer.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10.25rem 3.3125rem;
}

#footer-copyright, #footer-copyright-disclaimer{
  max-width: 78rem;
  width: 100%;
  margin: 1.5rem auto 0;
  padding: 0 1.5rem;
  font-size: get-token(font__size__200);
  color: @colorSlateLight;
}

#site-logo-footer-amexgbt {
  margin-top: 1.5rem;
  display: block;
  height: 3.125rem;
  background-image: url('/media/amex-gbt-logo.png'), url('../images/amex-gbt-logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 7.0625rem 3.125rem;
}
