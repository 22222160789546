.agent-only {
  color: @agentOnlyTextColor !important; // A rare but legitimate use of !important to ensure 'color' is overridden on anything 'agent-only' is applied to
}

// "Agent Only" styles will go away and "for-agent-only" class is only for the style of admin link on global header.
.for-agent-only {
  font-weight: @fontWeightMed;

  uitk-icon[name="headset"], .icon.icon-headset {
    display: inline !important;
  }
}

.agent-only-indicator {
  #font.size(@agentOnlyIndicatorTextSize);
  .padding(tr, @agentOnlyIndicatorTextSize, @agentOnlyIndicatorPaddingTop, @agentOnlyIndicatorPaddingRight);
  .background-rgba(@agentOnlyIndicatorBgColor, @agentOnlyIndicatorBgOpacity);
  .border(a, @baseFontPx, @borderWidthBase, @agentOnlyIndicatorBorderColor);
  border-radius: 100%;
  line-height: 1;
  display: inline-block;
  color: @agentOnlyTextColor;
  vertical-align: middle;

  &:before {
    .iconCore();
    font-style: normal;
    content: "\e040";
    #font.size(@agentOnlyIndicatorBeforeTextSize);
  }
}

.input-group.agent-only {
  label:not(.unlabel), .icon-headset:before {
    color: @agentOnlyTextColor;
  }

  input, input[type="password"][placeholder], textarea, .input {
    .padding(r, @secondaryFontPx, @agentOnlyPaddingRight); // Reserve extra padding for agent-only icon.
  }

  &.select{
    color: @formSelectCaretColor; // Override agent-only greenMed back to colorGrayscale5.

    select {
      .padding(r, @secondaryFontPx, @agentOnlySelectPaddingRight);
    }

    &:after {
      .pos(r, @baseFontPx, @agentOnlySelectCaretPosRight);
    }
  }

  &.invalid {
    label:not(.unlabel) {
      color: @colorRedMed // Override agent-only greenMed back to redMed.
    }
  }

  .agent-only-indicator {
    position: absolute;
    .pos(r, @baseFontPx, @agentOnlyIndicatorPosRight);
    .pos(t, @baseFontPx, @agentOnlyIndicatorPosTop);
  }
}
