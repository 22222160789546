// Common Style for Switch and Switch Small
.switch, .switch-small {
  .input {
    overflow: hidden;
    margin: 0;
    width: auto;

    label {
      display: inline-block;
      margin: 0;

      // override from label position and padding-left style for .input-group label
      position: static;
      top: 0;
      padding-left: 0;

      input[type=radio] {
        position: absolute;

        &:hover + span {
        cursor: pointer;
        }
      }

      span {
        text-transform: none;
        display: inline-block;
        margin: 0;
      }
    }
  }
}

// Default Switch
.switch {
  .input {
    .padding(a, @switchFontSizeSecondary, @switchPaddingAll) !important; // Important to override form input default style

    label {
      input[type=radio] {
        &:checked + span {
          background-color: @colorBlueMed;
          color: @switchChecked;
        }
      }

      span {
        #font.size(@switchFontSizeSecondary, @baseFontPx);
        .border-radius(a, @switchFontSizeSecondary, @switchBorderRadius);
        .padding(tr, @switchFontSizeSecondary, @switchSpanPaddingTop, @switchSpanPaddingRight);
        #font.weight(regular);
        color: @switchLabelDark;
        letter-spacing: 0;
      }
    }

    label + label {
      .margin(l, @switchFontSizeSecondary, @switchLabelMarginLeft*-1); // Use negative margin to offset the inline-block gap
    }
  }
}

// Small Switch
.switch-small {
  .input {
    .border(a, @switchFontSizeSecondary, @switchBorderWidth, @switchSmallBorderColor);
    height: auto;
    padding: 0 !important; // To override form label default style

    label {
      input[type=radio] {
        &:checked + span {
          background-color: @switchSmallCheckedBg;
          color: @switchSmallCheckedTextColor;
        }
      }

      span {
        .padding(tr, @switchSmallFontSize, @switchSmallSpanPaddingTop, @switchSpanPaddingRight);
        #font.size(@switchSmallFontSize, @baseFontPx);
        #font.semi-bold-font-stack();
        letter-spacing: 0.6px;
        color: @switchSmallSpanTextColor;
      }
    }

    label + label {
      .margin(l, @switchSmallFontSize, @switchLabelMarginLeft*-1); // Use negative margin to offset the inline-block gap
    }
  }
}


//Toggle Switch
.toggle-switch {
  display: flex;
  align-items: center;

  .toggle-switch-slider + .toggle-switch-label {
    margin-left: 1rem;
  }

  .toggle-switch-label ~ .toggle-switch-slider {
    margin-left: 1rem;
  }
  
  input[type="checkbox"] {
    .visuallyhidden();

    + .toggle-switch-slider {
      transition: 0.5s;
      position: relative;
      display: inline-block;
      width: 48px;
      height: 24px;
      border-radius: 9999px;
      color: transparent;
      flex-shrink: 0;
      background-color: @colorGrayscale4;
  
      &::before {
        content: "";
        transition: 0.1s;
        transition-property: transform;
        display: block;
        height: 18px;
        width: 18px;
        border-radius: 9999px;
        border: 2px solid @colorGrayscale4;
        left: 0;
        position: absolute;
        background: #fff;
        top: 1px;
      }
    }

    &:checked {
      + .toggle-switch-slider {
        background-color: @colorGreenMed;
    
        &::before {
          border: 4px solid @colorGreenMed;
          transform: translateX(100%);
          top: -1px;
        }
      }
    }
    &:focus {
      + .toggle-switch-slider {
        outline: 2px solid @colorBlueLight;
        outline: 5px auto -webkit-focus-ring-color;
        
        &::before {
          top: -3px;
          border-width: 6px;
        }
      }
    }
    &:focus:checked {
      + .toggle-switch-slider {
        &::before {
          transform: translateX(calc(100% - 3px));
        }
      }
    }
    &:disabled {
      + .toggle-switch-slider {
        opacity: 0.1;
      }
    }
  }
}