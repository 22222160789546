.data-table {
  width: 100%;
  .border(a, @tablesFontSize, @tablesBorderWidth, @tablesBorderColor);
  background-color: @tablesBgColor;

  caption {
    &.table-message {
      caption-side: bottom;
      .padding(trb, @tableLabelFontSize, @tableLabelPaddingTop, @tablesTopCapPaddingLftRgt, 0);
      #font.weight(regular);
      #font.size(@tableLabelFontSize);
      color: @tableLabelTextColor;
      background-color: transparent;
    }
  }

  th {
    .padding(a, @tablesHeaderFontSize, @tablesHeaderPadding);
    .border(trbl, @tablesHeaderFontSize, 0, @tablesBorderWidth, @tablesBorderWidth, 0, @tablesBorderColor);
    #font.weight(medium);
    #font.size(@tablesHeaderFontSize);
    .notextselect();
    color: @tablesHeaderTextColor;
    background-color: @tablesHeaderBgColor;
    text-align: center;
    letter-spacing: @tablesHeaderLetterSpc;

    &.align-left {
      &:extend(.align-left);
    }

    &.align-right {
      &:extend(.align-right);
    }

    &:last-child {
      border-right: none; // This is accounted for in the outer table border
    }
  }

  th.table-sortable {
    position: relative;
    cursor: pointer;
    .notextselect();

    &:before, &:after {
      font-family: 'EgenciaIcons';
    }

    &:before {
      content: "\e09a";
      position: absolute;
      .pos(r, @tablesHeaderFontSize, @tablesCaretBeforePosRgt);
      color: @tablesCaretDefaultColor;
    }

    &:after {
      content: "";
      position: absolute;
      .pos(r, @tablesHeaderFontSize, @tablesCaretAfterPosRgt);
    }

    &:empty:before, &:empty:after {
      content: "";
    }

    &.asc:before {
      content:"";
    }

    &.asc:after {
      content: "\e098";
      color: @tablesCaretActiveColor;
    }

    &.dsc:before {
      content: "\e09a";
      color: @tablesCaretActiveColor;
    }

    &.dsc:after {
      content: "";
    }
  }

  td {
    .padding(trbl, @tablesDataFontSize, @tablesDataPaddingTopBtm, @tablesDataPaddingLeftRight, @tablesDataPaddingTopBtm, @tablesDataPaddingLeftRight);
    .border(t, @tablesDataFontSize, @tablesBorderWidth, @tablesBorderColor);
    #font.size(@tablesDataFontSize);
    #font.weight(regular);
    color: @tablesDataTextColor;

    &[rowspan] {
      .border(trbl, @tablesDataFontSize, 0, @tablesBorderWidth, 0, @tablesBorderWidth, @tablesBorderColor);
    }
  }

  tbody {
    tr:first-child {
      td {
        border-top: none;
      }
    }

    tr.disabled {
      td {
        color: fade(@tablesDataDisableTextColor, @tablesDataDisableTextOpacity);
      }
    }
  }

  tfoot {
    td {
      #font.weight(medium);
      color: @tablesFtrTextColor;
    }
  }

  &.table-striped {
    tbody tr:nth-child(odd) {
      .background-rgba(@tablesDataStripBgColor, @tablesDataStripBgOpacity);
    }


    &.table-no-border{
      tbody tr:nth-child(even) {
        background-color: @tablesBgColor;
      }
    }
  }

  &.table-no-border {
    border: none;

    thead th, td, tfoot tr {
      border: none;
    }
  }

  &.table-no-background {
    background-color: transparent;

    thead th, td, tfoot tr {
      background-color: transparent;
    }
  }

  &.table-highlight {
    tbody {
      tr:hover {
        td {
          background-color: fade(@colorBlueMed, 10%);
        }
      }
    }
  }

  &.table-price {
    &:extend(.data-table.table-no-border all);

    tbody{
      tr td {
        .padding(tr, @tablesTotalFontSize, @tablesTotalPaddingTopBottom, @tablesDataPaddingLeftRight);

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .table-total {
    .padding(tr, @tablesTotalFontSize, @tablesTotalPaddingTopBottom, @tablesDataPaddingLeftRight);

    .sub-header {
      color: @tablesTotalSubHeaderTextColor;
    }
  }

  .table-msg-container {
    text-align: center;

    td {
      padding: 0;
    }

    p {
      .padding(a, @secondaryFontPx, 4.5);
    }

    .table-try-again {
      color: @colorBlueMed;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// When Tables are in a Box
.box,
.box-alt {
  .box-flush {
    .data-table {
      border: none;
    }
  }
}

// FireFox fix on background painting over the borders when position:relative apply to th or td.
.firefox .data-table th.table-sortable {
  background-clip: padding-box;
}

// IE10+ fix on background painting over the borders when position:relative apply to th or td.
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .data-table th.table-sortable {
    background-clip: padding-box;
  }
}

// Small screens
@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  // i'm using :not so that when I apply .no-collapse I don't need to re-overwrite the original table styles back in.
  .data-table:not(.no-collapse){

    // Force table to not be like tables anymore
    display: block;
    color: @tableTextColor;

    thead,
    tfoot,
    tbody,
    th,
    td,
    tr,
    caption{
      display: block;
    }


    // Hide table headers and footer
    thead tr{
      position: absolute;
      top: -9999em;
      left: -9999em;
    }

    tfoot tr {
      position: absolute;
      top: -9999em;
      left:-9999em;
    }

    // prevents long worded languages from breaking out of the table cell / caption
    th,
    td,
    caption{
      word-wrap: break-word;
      white-space: normal;
    }

    tbody{

      &.show-header-small-screen {
        tr td{
          padding-left: 50%;

          &:before {
            // Now like a table header
            position: absolute;

            // Top/left values mimic padding
            width: 45%;
            .pos(l,@tablesFontSize,12);
            .padding(r,@tablesFontSize,9);

            // Label the data
            content: attr(data-title);
            #font.weight(medium);
          }
        }
      }

      tr{
        .border(t,@tablesFontSize,1, @tablesBorderColor);

        &:first-child{
          border-top:0;
        }

        .padding(tr, @tablesFontSize,15,0);
      }

      td {
        // Behave  like a "row"
        border: none;
        position: relative;
        .padding(tr, @tablesFontSize, 3,18);

        // setting a min height in case table cell is left empty
        .min-height( @tablesFontSize, 27);
        word-break: break-word;
        hyphens: auto;
      }
    }
  }

  // creating a class for non-standard <tables> that contain things such as colspan, rowspan, etc
  .no-collapse{
    display: block;
    overflow: auto;
    td,
    th{
      width:100%;
      .min-width(@tablesFontSize, 173);
      word-break:normal;
    }
    // the overflow auto is causing the border to double up on the right and bottom of the table
    // the following rules fix it
    // border is removed from <td> because removing it from <table> causes the border to be removed completely. trust me, i'm a cse dev.
    tr th:last-child,
    tr td:last-child,
    caption{
      border-right:0;
    }

    tr:last-child td{
      border-bottom:0;
    }
  }
}

// Medium screens
@media only screen and (min-width: @breakpointScreenMediumMin) and (max-width: @breakpointScreenMediumMax) {
  .data-table:not(.no-collapse){
    th {
      .padding(a, @tablesHeaderFontSize, @tablesHeaderDataMediumPadding);
    }

    td {
      .padding(a, @tablesDataFontSize, @tablesHeaderDataMediumPadding);
    }
  }
}
