// LOADER
// ==========================================================================
@loaderColor: @colorBlueMed;
@loaderEmptyColor: @colorGrayscale2;
@progressBarBgColor: @loaderEmptyColor;
@progressIndicatorBgColor: @colorBlueLight;
@progressOneWidth: 40%;
@progressOneDuration: 0.8s;
@progressTwoWidth: 65%;
@progressTwoDuration: 0.7s;
@progressLoadedWidth: 100%;
@progressLoadedDuration: 0.1s;

// Default loader
.loader,
uitk-loader {
  width: 2.25rem;  // 36/16
  height: 2.25rem;  // 36/16
  display: none;
  position: relative;
  color: inherit;

  &:before,
  &:after {
    border: 0.375rem solid currentColor;  // 6/16
    content: '';
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    transform: translateZ(0);
  }

  &:before { opacity: 0.2; }

  &:after {
    border-color: transparent;
    border-top-color: inherit;
    animation: spin 0.5s linear infinite;
  }

  &.loading,
  &[loading] {
    display: inline-block;
  }

  &[size="small"] {
    .loader-small;
  }
  &[size="med"] {
    .loader-secondary;
  }
}

// Secondary loader
.loader-secondary {
  &:before,
  &:after {
    width: 1.5rem;  // 24/16
    height: 1.5rem;  // 24/16
    border-width: 0.25rem;  // 4/16
  }
}

// Small loader
.loader-small {
  &:before,
  &:after {
    width: 0.75rem;  // 12/16
    height: 0.75rem;  // 12/16
    border-width: 0.125rem;  // 6/16
  }
}

// Dark loader
.loader-dark {
  color: @colorSlateDark;
}

// Loading button
.btn-label .loader,
.btn-label uitk-loader {
  display: inline;
  position: relative;
  width: auto;
  height: auto;

  &:before, &:after {
    display: none;
    width: 1em;
    height: 1em;
    border-width: 0.15em;
  }

  &.loading,
  &[loading] {
    padding-left: 1.3125rem;  // 21/16

    &:before, &:after {
      display: inline-block;
    }
  }
}

// Progress bar & indicator
.progress-bar,
uitk-progress-bar {
  height: 0.1875rem;  // 3/16
  width: 100%;
  background-color: @progressBarBgColor;

  .progress-indicator,
  uitk-progress-indicator {
    height: 0.1875rem;  // 3/16
    width: 0;
    background-color: @progressIndicatorBgColor;
  }

  &.progress-one {
    .progress-indicator {
      width: @progressOneWidth;
      transition: width @progressOneDuration;
    }
  }
  &.progress-two {
    .progress-indicator {
      width: @progressTwoWidth;
      transition: width @progressTwoDuration;
    }
  }
  &.progress-loaded {
    .progress-indicator {
      width: @progressLoadedWidth;
      transition: width @progressLoadedDuration;
    }
  }
}

@keyframes spin {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}