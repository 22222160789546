// Basic Stepper Styles (this is our version of the HTML5 'number' input type)
// ==========================================================================
.stepper {
  display: flex;

  .stepper-down {
    border-radius: @formInputRadius+0px 0 0 @formInputRadius+0px;
    border-right: none;
  }

  .stepper-up {
    border-radius: 0 @formInputRadius+0px @formInputRadius+0px 0;
    border-left: none;
  }

  input.stepper-input {
    font-size: 0.8125rem;
    font-weight: @fontWeightRegular;
    text-align: center;
    border-radius: 0;
    width:3.375rem;
  }

  .stepper-input {
    &[disabled],
    &.disabled {
      cursor: default;
    }
  }

  .stepper-btn {
    .notextselect();
    text-align: center;
    color: @formFocusInputTextColor;
    cursor: pointer;
    font-size: 0.8125rem;
    width: 2.625rem;

    &:hover {
      background: fade(@colorBlueMed, 10%);
    }

    uitk-icon, .icon {
      margin-top: 0.375rem;
      cursor: inherit;
    }

    &.limit {
      color:@formDisabledInputTextColor;
    }

    &.disabled {
      color: @formDisabledInputTextColor;
      background-color: @formDisabledBgColor;
      border-color: @formDisabledInputBorderColor;
      cursor: default;
    }
  }
}