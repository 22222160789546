// Basic Button Overrides
// =========================================================================
button {
  appearance: none;
}

// ==========================================================================
// Base
// ==========================================================================
button,
.btn,
.btn:visited {
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 3rem;  // 48/16
  margin: @marginDefault @marginDefault 0 0;
  padding: 0;
  border-radius: @radiusDefault + 0px;

  .btn-label {
    position: relative;
    padding: 0 2rem; // 32/16
    font-weight: 500;
    letter-spacing: 0.6px;
    pointer-events: none;

    // Superscript
    sup {
      color: @colorSlateLight;
      top: -6px;
    }
  }
  
  .ege-no-outline & {
    outline: none;
  }
}

// ==========================================================================
// Button Message (optional message below button)
// ==========================================================================
// Message
.btn-message {
  font-size: 0.6875rem; // 11/16
  margin-top: 0.1875rem; // 3/16
}

// ==========================================================================
// Button Sizes
// ==========================================================================

//-----------------
// Large
//-----------------
.btn-large {
  height: 4.1875rem;  // 67/16

  .btn-label {
    font-size: 1.125rem; // 18/16
    padding: 0 3rem; // 48/16
  }
}

//Large buttons are the same as medium buttons on small screens
@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenSmallMax) {
  .btn-large{
    height: 3rem;  // 48/16

    .btn-label {
      font-size: 1rem;
    }
  }
}

//-------------
// Medium
//-------------
// Medium (required size for form groupings)
.btn-med {
  .btn-label {
    font-size: 1rem;
  }
}

//----------------
// Small
//----------------

// Size- Small
.btn-small {
  height: 1.625rem; // 26/16

  .btn-label {
    font-size: 0.6875rem; // 11/16
    padding: 0 0.75rem // 12/16
  }
}

// ==========================================================================
// Button States
// ==========================================================================

.btn {
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

// ==========================================================================
// Visual Styles
// ==========================================================================
// Action
.btn-action,
a.btn-action,
.btn-sub-action  //btn-sub-action getting deprecated
{
  color: white; //should use UDS Token in future
  border: none;
  background-color: @colorBlueMed;

  &:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  &.selected, 
  &:active{
    opacity: 0.6;
  }
}

// Utility & Add
.btn-utility,
.btn-add {
  color: @colorBlueMed;

  .btn-label {
    color: @colorBlueMed;
  }
  &:hover{
    background-color: @colorBlueUltraLight;
  }
  &:active {
    background-color: @colorCornFlowerBlue;
  }
}

// Utility
.btn-utility {
  border: 1px solid @colorBlueMed;
  background-color: transparent;

  &.selected {
    background-color: @colorCornFlowerBlue;
  }
}

// Add (AKA Wire)
.btn-add {
  border: 1px dashed @colorBlueMed;
  background-color: transparent;
}

// Text
.btn-text-action,
.btn-text-sub-action { //TEXT SUBACTION is deprecated
  background: none;
  border: none;
}

.btn-text-action,
.btn-text-sub-action { //TEXT SUBACTION is deprecated
  color: @colorBlueMed;

  .btn-label {
    color: @colorBlueMed;
  }
}

.btn-action,
.btn-add,
.btn-utility,
.btn-text,
.btn-text-action,
.btn-text-sub-action { //TEXT SUBACTION is deprecated
  &.disabled,
  &:disabled,
  &.disabled:hover,
  &.disabled:active,
  &:disabled:hover,
  &:disabled:active {
    cursor: default;
    opacity: 0.4;
    top: 0;
    box-shadow: none;
  }
}

.btn-text,
.btn-utility,
.btn-add,
.btn-text-action,
.btn-text-sub-action  { //TEXT SUBACTION is deprecated
  &.disabled,
  &.disabled:hover,
  &.disabled:active,
  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    background: transparent;
  }
}

//Price
.btn-wrapper {
  display: inline-block;
}

// Price
.btn-price {
  margin: 1.5rem 1.5rem 0 0; // 24/16
  font-weight: 500;
  left: 10.125rem; // 162/16
  background: white;
  min-width: 8.625rem;  // 138/16
  border: 1px solid @colorGrayscale3;
  border-radius: 0.125rem; // @radiusDefault(2)/16
  &:hover {
    border-color: @colorGrayscale4;

    .btn-label {
      color: @colorSlateDark;
    }
  }

  &:active {
    background: @colorGrayscale1;
    border: 1px solid @colorGrayscale4;
  }

  .btn-label {
    color: @colorSlateLight;
    padding-top: 0.6875rem; // 11/16
    padding-right: 0.6875rem; // 11/16
  }

  // Cascading is taking affect here, so don't move
  &.out-of-policy {
    uitk-icon, .icon {
      color: @colorRedMed;
    }
  }
}


// Close
.btn-close {
  margin: 0;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.45;
  color: inherit;

  &:active {
    margin-top: 0;
  }

  &:after {
    padding-top: 0;
    padding-right: 0.1875rem; // 3/16
  }

  // Psuedo element to pad the touch target
  &:before {
    display: block;
    position: absolute;
    box-sizing: content-box;
    top: -0.1875rem; // -3/16
    left: -0.1875rem; // -3/16
    width: 0.1875rem; // 3/16
    height: 0.1875rem; // 3/16
    content: '';
  }

  .btn-label {
    padding: 0;
  }

  &.module-close {
    background-color: darken(@colorSlateDark, 36%);
    color: lighten(@colorSlateDark, 18%);
    &:focus,
    &:hover,
    &:active {
      background-color: @colorSlateDark;
      color: white;
    }
  }
}

// Alert states DEPRECATED
// Error
@btnErrorBg: @colorError;
@btnErrorBgHover: darken(@btnErrorBg, 10);
@btnErrorBgActive: @colorRedDark;
// Success
@btnSuccessBg: @colorSuccess;
@btnSuccessBgHover: darken(@btnSuccessBg, 10);
@btnSuccessBgActive: @colorGreenDark;
// Warn
@btnWarnBg: @colorWarn;
@btnWarnBgHover: darken(@btnWarnBg, 10);
@btnWarnBgActive: @colorGoldDark;

.btn {
  &.error {
    background-color: @btnErrorBg;
    &:hover {
      background-color: @btnErrorBgHover;
    }
    &.selected, &:active {
      background-color: @btnErrorBgActive;
    }
    &[disabled],
    &.disabled {
      background-color: @colorGrayscale4;
    }
  }
  &.success {
    background-color: @btnSuccessBg;
    &:hover {
      background-color: @btnSuccessBgHover;
    }
    &.selected, &:active {
      background-color: @btnSuccessBgActive;
    }
    &[disabled],
    &.disabled {
      background-color: @colorGrayscale4;
    }
  }
  &.warn {
    background-color: @btnWarnBg;
    &:hover {
      background-color: @btnWarnBgHover;
    }
    &.selected, &:active {
      background-color: @btnWarnBgActive;
    }
    &[disabled],
    &.disabled {
      background-color: @colorGrayscale4;
    }
  }
}


// ==========================================================================
// No margin style
// ==========================================================================
.btn.btn-no-margin {
  margin: 0;
}


// ==========================================================================
// Column style
// ==========================================================================
.col,
.col-flush {
  > .input-group > .btn {
    width: 100%;
  }
}

// ==========================================================================
// Button Bar
// ==========================================================================

.btn-group {
  display: inline-block;
  position: relative;

  > .btn {
    .margin(trbl, 15, 0, 0, 0, -6);
    border-radius: 0;

    &:first-child {
      margin-left: 0;
      border-radius: 0.1875rem 0 0 0.1875rem // 3/16
    }
    &:last-of-type {
      border-radius: 0 0.1875rem 0.1875rem 0 // 3/16
    }
    &:only-of-type {
      border-radius: 0.1875rem; // 3/16
    }
  }
  .btn-med.btn-utility:active {
    margin-top: 0;
  }

  uitk-icon, .icon {
    padding: 0;
  }
  &.open .btn-sub-action.dropdown-toggle, .btn-sub-action.selected {
    background-color: @colorBlueMed;
  }

  & + .btn-group {
    margin-left: 1.5rem; // 24/16
  }
}