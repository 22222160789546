//
// Animations HTML:
//
// example: <span class="animate-bounce"></span>
//
// BOUNCE
.animate-bounce {
  .animation(bounce @animateBounceDuration @animateBounceIterationCount @animateBounceFillMode);
  transform-origin: @animateBounceTransOrgX @animateBounceTransOrgY;
}

// FADE IN UP
.animate-fade-in-up {
  .animation(fadeInUp @animateFadeInUpDuration);
}

// FADE IN DOWN
.animate-fade-in-down {
  .animation(fadeInDown @animateFadeInDownDuration);
}

// FADE OUT UP
.animate-fade-out-up {
 .animation(fadeOutUp @animateFadeOutUpDuration);
}

// FADE OUT DOWN
.animate-fade-out-down {
  .animation(fadeOutDown @animateFadeOutDownDuration);
}


// SLIDE IN UP
.animate-slide-in-up {
  .animation(slideInUp @animateSlideInUpDuration);
}

// SLIDE IN DOWN
.animate-slide-in-down {
  .animation(slideInDown @animateSlideInDownDuration);
}