.filters {
  .filter-menu-wrapper {
    overflow-x: auto;
    white-space: nowrap;
  }
  .filter-menu {
    .margin(a, @baseFontPx, 0);
    .padding(l, @baseFontPx, 9);
    list-style-type: none;

    .filter-menu-icon {
      .padding(trbl, @baseFontPx, 0,9,0,9);
      display: inline-block;
      color: @colorGrayscale5;
      #font > .size(@smallFontPx);
      cursor: pointer;
    }

    .filter-category {
      .padding(trbl, @baseFontPx, 9,9,6,9);
      display: inline-block;
      cursor: pointer;

      .filter-label {
        color: @colorGrayscale5;
        &:hover {
          color:@colorSlateLight;
        }
      }
    }
  }

  .filter-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;

    .filter-content {
      max-height: 200px;
      overflow-y: scroll;
    }

    .filter-body-menu {
      .background-rgba(@colorGrayscale1, 60%);
    }
  }

  &.filtered { // Style overrides when in a "filtered" state
    .filter-category {
      &.filtered {
        .filter-label {
          color: @colorSlateMed;
        }
      }
    }
    .filter-menu-icon {
      color: @colorSlateMed;
    }
  }

  &.open {
    .filter-category {
      &.open {
        .border(b, @baseFontPx, @borderWidthMedium, @colorBlueMed);
        .filter-label {
          color: @colorBlueMed;
        }
      }
    }
  }
}
